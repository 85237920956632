import Header from '../../Components/Fixed/Header';
import Footer from '../../Components/Fixed/Footer';

import MyMenu from '../../Components/MyChangGgo/MyMenu';
import { customAxios } from '../../Components/Common/customAxios';
import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { pageCollection } from '../../Components/Page/PageCollection';
import DisplayInquiry from '../../Components/Inquiry/DisplayInquiry';

export default function MyInquiryWrite() {

    const [start, setStart] = useState(false)
    const loginState = useSelector((state) => state.login)
    const navigate = useNavigate()
    const [inquiryData, setInquiryData] = useState()

    useEffect(() => {
        setStart(true)
    }, [])

    useEffect(() => {

        if (loginState.isLogin === false) {
            alert("로그인이 필요합니다.")
            navigate("/")
        }
        else if (loginState.isLogin === true) {
            if (start) {
            }
        }
    }, [start, loginState.isLogin])


    return (
        <div>
            <Header />
            <section>
                <article className='bg-Maincolor1  break-keep py-20 pt-32'>
                    <div className='mx-auto min-h-screen max-w-7xl px-5 md:flex md:justify-between'>
                        <div className='text-white box-border flex flex-wrap flex-col items-center mb-10 md:w-1/4 md:mb-0'>
                            <MyMenu />
                        </div>
                        <div className='box-border bg-white text-black rounded-lg md:w-width_70 p-5'>
                        
                        </div>
                    </div>
                </article>

            </section>
            <Footer />
        </div>
    );
}


