import AdminAuthCheck from "../../Components/Admin/AdminAuthCheck";
import AdminStoreList from "../../Components/Admin/AdminStoreList";
import AdminHeader from "../../Components/Fixed/AdminHeader";

function AdminStorePage() {
    return (
        <div className="h-[calc(100vh-2rem)] flex flex-row ">
            <AdminAuthCheck auth="M3"/>
            <AdminHeader mode={1} />
            <AdminStoreList/>
        </div>

    );

}

export default AdminStorePage;


