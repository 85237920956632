
import { useEffect, useState } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";
import { customAxios } from "../../Components/Common/customAxios";

import { TableStriped } from "../../Components/Table/TableStriped";
import { Input, Button } from "@material-tailwind/react"
import { useSelector, useDispatch } from "react-redux";

function AdminMemberList() {

    const [start, setStart] = useState(false)
    const loginState = useSelector((state) => state.login)
    const navigate = useNavigate()

    const [memberData, setMemberData] = useState([])
    const [filterMemberData, setFilterMemberData] = useState([])

    const [searchUID, setSearchUID] = useState("")
    const [searchEmail, setSearchEmail] = useState("")
    const [searchNickname, setSearchNickname] = useState("")
    const [searchName, setSearchName] = useState("")
    const [searchPhone, setSearchPhone] = useState("")

    const [complete, setComplete] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()

   
    const [memberCount, setMemberCount] = useState()

    const [loadingMember, setLoadingMember] = useState(true)
    const [seconds, setSeconds] = useState(parseInt(0));

    const limit = Number(searchParams.get("limit"))
    const active = Number(searchParams.get("active"))

    useEffect(() => {
        setStart(true)
    }, [])

    useEffect(() => {
        const getMemberCount = async()=>{
            if (start) {
                const result = await customAxios.get('/api/getMemberCount')
                if (result.data.state === 101) {
                    // console.log(result.data)
                    setMemberCount(result.data.response)
                }
            }
        }
        const getMemberTable = async () => {
            if (start) {
                setLoadingMember(true)
                const result = await customAxios.get('/api/getMemberTable')
                if (result.data.state === 101) {
                    // console.log(result.data.MemberTable[14])
                    setMemberData(result.data.MemberTable)
                    setFilterMemberData(result.data.MemberTable)
                }
                setLoadingMember(false)
            }
        }
        if (loginState.isLogin === false) {
            alert("로그인이 필요합니다.")
            navigate("/")
        }
        else if (loginState.isLogin === true) {
            if (loginState.Member_Auth === "U") {
                alert("권한이 없습니다.")
                navigate("/")
            }
            else {
                getMemberCount()
                getMemberTable()
                setComplete(true)
            }
        }
    }, [start, loginState.isLogin])

    useEffect(() => {
        const countdown = setInterval(() => {
          if (parseInt(seconds) > 0) {
            setSeconds(parseInt(seconds) - 1);
          }
          if (parseInt(seconds) === 0) {
                clearInterval(countdown);
          }
        }, 1000);
        
        return () => clearInterval(countdown);
      
    }, [seconds]);

    const refreshMemberData = () => {
        const getMemberTable = async () => {
            if (start) {
                setLoadingMember(true)
                const result = await customAxios.get('/api/getMemberTable')
                if (result.data.state === 101) {
                    setMemberData(result.data.MemberTable)
                    setSearchUID("")
                    setSearchEmail("")
                    setSearchNickname("")
                    setSearchName("")
                    setSearchPhone("")
                    setDataTable()
                }
                setLoadingMember(false)
            }
        }
        getMemberTable()
    }

    const setDataTable = () => {
        
        let filterArray = []
        if (searchUID === "" && searchEmail === "" && searchNickname === "" &&searchName ==="" &&searchPhone ==="") 
        {filterArray = memberData}
        else {

            const strNickname = searchNickname.toLowerCase()
            const strEmail = searchEmail.toLowerCase()
            const strUID = searchUID.toLowerCase()

            filterArray = memberData.filter((element)=>{
                const MemberNickname = element["Member_Nickname"].toLowerCase()
                const MemberUID = element["Member_UID"].toLowerCase()
                const MemberEmail =element["Member_Email"]

                const UserInfoName =element["UserInfo"].name
                const UserInfoPhone =element["UserInfo"].phone

                return MemberUID.includes(strUID) && 
                MemberEmail.includes(strEmail) && 
                MemberNickname.includes(strNickname) && 
                UserInfoName.includes(searchName) && 
                UserInfoPhone.includes(searchPhone);
            })
        }
        setFilterMemberData(filterArray)
    }

    useEffect(() => {
            setDataTable()
    }, [seconds, searchUID, searchEmail, searchNickname, searchName, searchPhone])


    const displayMemberData = () => {
        let result = []
        result.push(
            <div key="search" >
                <div className="flex flex-row mb-2 gap-4 w-full" >
                    <div>
                        <Input label="닉네임" value={searchNickname} onChange={(e) => { setSearchNickname(e.target.value) }} />
                    </div>
                    <div>
                        <Input label="이메일" value={searchEmail} onChange={(e) => { setSearchEmail(e.target.value) }} />
                    </div>
                    <div>
                        <Input label="UID" value={searchUID} onChange={(e) => { setSearchUID(e.target.value) }} />
                    </div>
                    <div>
                        <Input label="이름" value={searchName} onChange={(e) => { setSearchName(e.target.value) }} />
                    </div>
                    <div>
                        <Input label="번호" value={searchPhone} onChange={(e) => { setSearchPhone(e.target.value) }} />
                    </div>
                </div>
                <Button key={"refresh"} variant="outlined" className="flex items-center gap-3" onClick={refreshMemberData}>새로고침</Button>
            </div>
        )

        if(loadingMember){
            result.push(<div key="no">로딩중...</div>)
        }
        else{
            if (filterMemberData.length <= 0) {
                result.push(<div key="no">회원 데이터가 없습니다.</div>)
                result.push(<div key="plz">검색 결과가 없습니다.</div>)
            }
            else {
                const TABLE_HEAD = ["닉네임", "이메일", "UID", "이름","번호","가입날짜", "권한"];
                const TABLE_ROWS = ["Member_Nickname", "Member_Email", "Member_UID", "Member_RegistrationDate", "Member_Auth"];
    
                result.push(<TableStriped
                    TABLE_HEAD={TABLE_HEAD}
                    TABLE_ROWS={TABLE_ROWS}
                    TABLE_DATA={filterMemberData}
                    key={"TableStriped"} />)
                result.push(<div key={"Length"}>검색된 회원 수 : {filterMemberData.length}</div>)
            }
        }

        return result

    }

    if (complete) {
        return (
            <div>
                <section>
                    <article className='main-visual_wrap'>
                        <div className='mx-auto px-5'>
                            <div className='flex flex-col gap-2 py-10'>
                                <div>
                                    회원 수 : {memberCount}
                                </div>
                                    {displayMemberData()}
                            </div>
                        </div>

                    </article>

                </section>
            </div>

        );
    }
    return (<></>)

}

export default AdminMemberList;


