import { customAxios } from "../../Components/Common/customAxios";

import Header from '../../Components/Fixed/Header';
import Footer from '../../Components/Fixed/Footer';
import MyMenu from '../../Components/MyChangGgo/MyMenu';
import MyPasswordConfirm from '../../Components/MyChangGgo/MyPasswordConfirm';

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

function MyPortfolio() {

    const loginState = useSelector((state)=> state.login)
    const [start, setStart] = useState(false);    
    const [initState, setinitState] = useState(false);

    const [portfolioModeState, setPortfolioModeState] = useState(0);
    const [passwordState, setPasswordState] = useState(false);

    useEffect(() => {
        setStart(true);
    }, [])

    useEffect(() => {
        if (start  && loginState.isLogin === true) {
            customAxios.post('/api/SelectMyPortfolio',
                {
                    uid: loginState.Member_UID,
                })
                .then((res) => {
                    // console.log(res.data.state);
                    if (res.data.state === -100)
                        setinitState(false);
                    else
                        setinitState(true);
                })
        }
    }, [start])

    useEffect(() => {
        if (passwordState === true) {
            setPortfolioModeState(2);
        }

    }, [passwordState])

    const MyPortfolioBody = () => {
        if (portfolioModeState === 0) {
            if (initState === true) //이전에
            {
                // return (
                //     // <MyPortfolioView setPortfolioModeState={setPortfolioModeState} />
                // );
            }
            else {
                return (
                    <div className='basic text-black box-border py-5 px-5'>
                        <div className="break-all">
                            <h1 className=' font-bold mb-10'>나의 포트폴리오</h1>
                        </div>
                        <div className='flex justify-center mt-10'>
                            <button className=' box-border rounded-lg bg-Cgcolor py-3 px-3 text-white lg:w-1/5 w-full font-bold'
                                onClick={(e) => { setPortfolioModeState(1); }}
                            >
                                나의 포트폴리오 만들기
                            </button>
                        </div>
                    </div>
                );
            }
        }
        else if (portfolioModeState === 1) {
            return (
                <MyPasswordConfirm setPasswordState={setPasswordState} />  /*비밀번호 확인*/
            );
        }
        else if (portfolioModeState === 2) {
            // return (
            //     <MyPortfolioWrite
            //         initState={initState}
            //         setinitState={setinitState}
            //     />
            // );
        }
    }

    return (
        <div>
            <Header/>
            <section>
                <article className='bg-Maincolor1 text-white break-keep py-20 pt-32'>
                    <div className='mx-auto min-h-screen max-w-7xl px-5 md:flex md:justify-between'>
                        <div className='box-border flex flex-wrap flex-col items-center mb-10 md:w-1/4 md:mb-0'>
                            <MyMenu></MyMenu>
                        </div>
                        <div className='box-border bg-white rounded-lg md:w-width_70 '>
                            <div className='text-black'>
                                {/* 나의 포트폴리오 시작*/}
                                {MyPortfolioBody()}
                                {/* 나의 포트폴리오 종료*/}
                            </div>
                        </div>
                    </div>
                </article>

            </section>
            <Footer></Footer>
        </div>


    );
}

export default MyPortfolio;


