import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';

import DaumPostcodeEmbed from 'react-daum-postcode'
import { useState, useEffect } from 'react';
import { customAxios, getDelivery } from '../Common/customAxios';
import { Typography, CircularProgress, Input, Checkbox } from '@mui/material';
import { Chip } from '@material-tailwind/react';
import { removeEmoji } from '../Common/dataStruct';

// import 

export default function Address(props) {

  const [mode, setMode] = useState(0)
  const [preMode, setPreMode] = useState(0)

  const [index, setIndex] = useState(null)
  const [postName, setPostName] = useState("")
  const [isValidPostName, setIsValidPostName] = useState(true);
  const [postCode, setPostCode] = useState("")
  const [isValidPostCode, setIsValidPostCode] = useState(true);
  const [address, setAddress] = useState("")
  const [roadAddress, setRoadAddress] = useState("")
  const [detailAddress, setDetailAddress] = useState("")
  const [phone, setPhone] = useState("")
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
  const [requests, setRequests] = useState("")
  const [checkDefault, setCheckDefault] = useState(false)


  const delivery = props.delivery

  useEffect(()=>{
    if(mode!==3){
      setPreMode(mode)
    }
  },[mode])


  const onClickCancel = () => {
    setIndex(null)
    setPostName("")
    setIsValidPostName(true)
    setPostCode("")
    setIsValidPostCode(true)
    setAddress("")
    setRoadAddress("")
    setDetailAddress("")
    setPhone("")
    setIsValidPhoneNumber(true)
    setRequests("")
    setCheckDefault(false)

    setMode(0)
    props.DialogCancel()
  }
  useEffect(()=>{
    checkPostCode()
  },[postCode])


  const handleDaumPostcodeEmbed = async(data) => {
    setPostCode(data.zonecode)
    setAddress(data.address)
    setRoadAddress(data.roadAddress)


    setMode(preMode)
  }

  const formatPhoneNumber = (input) => {
    // 정규식을 사용하여 숫자만 남기고 나머지 문자 제거
    const cleaned = input.replace(/\D/g, '');
    
    // 전화번호에 하이픈 추가
    const formatted = cleaned.replace(/(\d{2,3})(\d{3,4})(\d{4})/, '$1-$2-$3');
    
    return formatted;
  };

  const checkPhone = () => {
    // 전화번호 정규식
    const phoneNumberRegex = /^\d{2,3}-\d{3,4}-\d{4}$/;

    const formattedPhoneNumber = formatPhoneNumber(phone);

    setPhone(formattedPhoneNumber);
    setIsValidPhoneNumber(phoneNumberRegex.test(formattedPhoneNumber));

    return phoneNumberRegex.test(formattedPhoneNumber)
  }

  const checkPostName = () => {
    //  빈칸인지 확인
    if (postName.trim() === '') {
      setIsValidPostName(false)
      return false
    }
    else {
      setIsValidPostName(true)
      return true
    }
  };

  const checkPostCode = () => {
    //  빈칸인지 확인
    if (postCode.trim() === '') {
      setIsValidPostCode(false)
      return false
    }
    else {
      setIsValidPostCode(true)
      return true
    }
  };

  const onClickUpdate = (element) =>{
    setIndex(element.Delivery_Index)
    setPostName(element.Delivery_PostName)
    setPostCode(element.Delivery_Postcode)
    setAddress(element.Delivery_Address)
    setRoadAddress(element.Delivery_RoadAddress)
    setDetailAddress(element.Delivery_Detail)
    setPhone(element.Delivery_Number)
    setRequests(element.Delivery_Requests)
    setCheckDefault(element.Delivery_Default===1)
    setMode(2)
  }
  const onClickSelect = (element,idx) =>{
    props.setSelectDeliveryidx(idx)
    onClickCancel()
  }
  const onClickUpdateDelivery = async() => {

    if(checkPostName()  && checkPhone()  && checkPostCode() ){
      await customAxios.post("/api/UpdateDelivery", {
        Delivery_Index: index,
        Delivery_PostName: postName,
        Delivery_Postcode: postCode,
        Delivery_Address: address,
        Delivery_RoadAddress: roadAddress,
        Delivery_Detail: detailAddress,
        Delivery_Number: phone,
        Delivery_Requests: requests,
        Delivery_Default: checkDefault
      })
      
      props.updateDelivery()
      setMode(0)
    }
  }
  const onClickInsertDelivery = async() => {
    
    if(checkPostName() && checkPhone() && checkPostCode()){
      await customAxios.post("/api/InsertDelivery",{
        Delivery_PostName: postName,
        Delivery_Postcode: postCode,
        Delivery_Address: address,
        Delivery_RoadAddress: roadAddress,
        Delivery_Detail: detailAddress,
        Delivery_Number: phone,
        Delivery_Requests: requests,
        Delivery_Default: checkDefault
      })
      props.updateDelivery()
      setMode(0)
    }
    
  }

  const onClickDeleteDelivery = async (element) =>{
    if(window.confirm("삭제 하시겠습니까?")){
      const result = await customAxios.post("/api/deleteDelivery",element)
      props.updateDelivery()
    }
  }

  const displayBtn = []
  displayBtn.push(
    <button key="modeBtn1" className='mt-3 bg-Cgcolor text-white text-sm font-bold rounded-lg py-3 px-4 inline-block '
      onClick={(e) => { setMode(1) }}>배송지 추가하기</button>
  )
  // displayBtn.push(
  //   <button key="testbtn" className='mt-3 bg-Cgcolor text-white text-sm font-bold rounded-lg py-3 px-4 inline-block '
  //     onClick={onClickInsertDelivery}>테스트</button>
  // )

  const classes = "p-4 border-b border-blue-gray-50"


  const displayDelivery = []
  if (delivery === null) {
    displayDelivery.push(
      <CircularProgress key="displayDelivery1" />
    )
  }
  else if (delivery === false) {
    displayDelivery.push(
      <div key="displayDelivery2"
        className='flex flex-col'>
        배송지 정보가 없습니다.
        {displayBtn}
      </div>
    )

  }
  else {

    let DeliveryList = []

    delivery.forEach((element, idx) => {
      let defaultCheck 
      if(element.Delivery_Default===1){
        defaultCheck = <Chip color='gray' value={"기본 배송지"} />
      }
    
      DeliveryList.push(
        <div key={"DeliveryList" + idx} className='delivery_list box-border border-2 border-rose-600 px-3 py-3'>
          <div className='line'>
            <div className='border-b'>
              <h2 className='defaultchek'>{defaultCheck}</h2>
              <h1>
                <span className='block box-border p-2'>이름: {element.Delivery_PostName}</span>
              </h1>
            </div>
          </div>
          <div className='line box-border p-2'>
            <div className='md:flex md:flex-row gap-1 mb-3'>
              <div className='text-sm'>
                <span>주소 : </span>
                {element.Delivery_Address},
              </div>
              <div className='text-sm'>
                
                {element.Delivery_Detail}
              </div>
              <div className='text-sm'>
              [{element.Delivery_Postcode}]
              </div>
            </div>
            <div className='md:flex md:flex-row gap-1 mb-3'>
              <div className='text-sm'>
                <span>도로명주소 : </span>
                {element.Delivery_RoadAddress},
              </div>
              <div className='text-sm'>
                {element.Delivery_Detail}
              </div>
              <div className='text-sm'>
              [{element.Delivery_Postcode}]
              </div>
            </div>
            <div className='text-sm'>
              <span>연락처 : </span>
              {element.Delivery_Number}
            </div>
            <div className='text-sm'>
              <span>요청사항 : </span>
              {element.Delivery_Requests}
            </div>
          </div>
          <div className='flex flex-row gap-2 justify-between border-t'>
            <div className='flex flex-row gap-2'>
              <button
                className='mt-3 bg-Cgcolor text-white text-sm font-bold rounded-lg py-3 px-4 inline-block '
                onClick={(e) => { onClickUpdate(element) }}>
                수정
              </button>
              <button
                className='mt-3 bg-Cgcolor text-white text-sm font-bold rounded-lg py-3 px-4 inline-block '
                onClick={(e) => { onClickDeleteDelivery(element) }}>
                삭제
              </button>
            </div>
            <button
              className='mt-3 bg-Cgcolor text-white text-sm font-bold rounded-lg py-3 px-4 inline-block '
              onClick={(e) => { onClickSelect(element,idx) }}>
              선택</button>
          </div>

        </div>
      )
    });
    

    displayDelivery.push(
      <div key="displayDelivery3" className='flex flex-col gap-1'>
        {DeliveryList}
        {displayBtn}
      </div>
    )
  }
  let setDeliveryBtn
  if (mode === 1) {
    setDeliveryBtn = (<button key="setDelivery1" className='mt-3 bg-Cgcolor text-white text-sm font-bold rounded-lg py-3 px-4 inline-block '
      onClick={(e) => { onClickInsertDelivery() }}>
      배송지 추가하기
    </button>)

  }
  else if (mode === 2) {
    setDeliveryBtn = (<button key="setDelivery2" className='mt-3 bg-Cgcolor text-white text-sm font-bold rounded-lg py-3 px-4 inline-block '
      onClick={(e) => { onClickUpdateDelivery() }}>
      배송지 변경하기
    </button>)
  }

 

  const displayAddDelivery = (
    <div key="displayAddDelivery">
      <table className="mt-3 w-full table-auto text-left delivery_table">
        <tbody>
          <tr>
            <td className={`${classes} bg-blue-gray-50/50 text-right w-full md:w-36`}>
              <Typography variant="small" color="blue-gray" className="font-normal">
                이름
              </Typography>
            </td>
            <td className={classes}>
              <div className='flex flex-col gap-1 w-full'>
                <Input
                  className=''
                  variant="static"
                  label={""}
                  value={postName}
                  placeholder='이름'
                  onChange={(e) => {
                    const text = removeEmoji(e.target.value)
                    setPostName(text)
                    // setPostName(e.target.value)
                  }}
                  onBlur={checkPostName}
                  error={!isValidPostName}
                />
                {isValidPostName ?
                  null :
                  <Typography
                    variant="sx"
                    color="red"
                    className="mt-2 flex items-center gap-1 font-normal"
                  >
                    이름을 입력해주세요.
                  </Typography>
                }
              </div>
           
            </td>
          </tr>
          <tr>
            <td className={`${classes} bg-blue-gray-50/50 text-right`}>
              <Typography variant="small" color="blue-gray" className="font-normal">
                연락처
              </Typography>
            </td>
            <td className={classes}>
              <div className='flex flex-col gap-1 w-full'>
                <Input
                  variant="static"
                  label={""}
                  value={phone}
                  placeholder='010-xxxx-xxxx'
                  onChange={(e) => { setPhone(e.target.value) }}
                  onBlur={checkPhone}
                  error={!isValidPhoneNumber}
                />
                {isValidPhoneNumber ?
                  null :
                  <Typography
                    variant="sx"
                    color="red"
                    className="mt-2 flex items-center gap-1 font-normal"
                  >
                    올바른 번호를 입력해주세요.
                  </Typography>
                }
              </div>
            </td>
          </tr>
          <tr>
            <td className={`${classes} bg-blue-gray-50/50 text-right`}>
              <Typography variant="small" color="blue-gray" className="font-normal">
                배송 주소
              </Typography>
            </td>
            <td className={`${classes}`}>
              <div className='flex flex-col gap-1'>
                <div className='flex items-center gap-2 '>
                  <Typography variant="small" color="blue-gray" className="font-normal">
                    {address}
                  </Typography>
                  <Typography variant="small" color="blue-gray" className="font-normal">
                    {postCode}
                  </Typography>
                  <IconButton
                    aria-label="close" onClick={(e) => { setMode(3) }}
                    sx={{
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                </div>
                {isValidPostCode ?
                  null :
                  <Typography
                    variant="sx"
                    color="red"
                    className="mt-2 flex items-center gap-1 font-normal"
                  >
                    주소를 입력해주세요.
                  </Typography>
                }
              </div>
            </td>
          </tr>
          <tr>
            <td className={`${classes} bg-blue-gray-50/50 text-right`}>
              <Typography variant="small" color="blue-gray" className="font-normal">
                도로명 주소
              </Typography>
            </td>
            <td className={classes}>
              <div className='flex items-center gap-2 '>
                <Typography variant="small" color="blue-gray" className="font-normal">
                  {roadAddress}
                </Typography>
                <Typography variant="small" color="blue-gray" className="font-normal">
                  {postCode}
                </Typography>
              </div>
            </td>
          </tr>
          <tr>
            <td className={`${classes} bg-blue-gray-50/50 text-right`}>
              <Typography variant="small" color="blue-gray" className="font-normal">
                상세 주소
              </Typography>
            </td>
            <td className={classes}>
              <Input
                className='w-full'
                variant="static"
                label={""}
                placeholder='상세주소'
                value={detailAddress}
                onChange={(e) => {
                  const text = removeEmoji(e.target.value)
                  setDetailAddress(text)
                  // setDetailAddress(e.target.value)
                }} />
            </td>
          </tr>
          <tr>
            <td className={`${classes} bg-blue-gray-50/50 text-right`}>
              <Typography variant="small" color="blue-gray" className="font-normal">
                배송 요청사항
              </Typography>
            </td>
            <td className={`${classes}`}>
              <Input
                className='w-full'
                variant="static"
                label={""}
                placeholder='현관 비밀번호 종 누르고 1111*'
                value={requests}
                onChange={(e) => {
                  const text = removeEmoji(e.target.value)
                  setRequests(text)
                  // setRequests(e.target.value)
                }} />
            </td>
          </tr>
          <tr>
            <td className={`${classes} bg-blue-gray-50/50 text-right`}>
              <Typography variant="small" color="blue-gray" className="font-normal">
                기본 배송지
              </Typography>
            </td>
            <td>
              <Checkbox
                id="checkDefault"
                label={
                  <Typography
                    variant="small" color="gray"
                    className="flex items-center font-normal">
                    기본 배송지로 선택
                  </Typography>
                }
                checked={checkDefault}
                onChange={(e) => { setCheckDefault(!checkDefault) }}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div className='flex flex-row-reverse'>
      {setDeliveryBtn}
      </div>
    </div>
  )

  const displayMode = []
  let style = { width: "100%", height: 0 }
    //배송지 리스트
  if (mode === 0) {
    displayMode.push(displayDelivery)
  }
    //배송지 추가하기
  else if (mode === 1) {
    displayMode.push(displayAddDelivery)
  }
    //배송지 수정하기
  else if (mode === 2) {
    displayMode.push(displayAddDelivery)
  }
    //주소창 검색
  else if (mode === 3) {
    style = {width: "100%", height: 500}
  displayMode.push(<DaumPostcodeEmbed key="DaumPostcodeEmbed" style={style} onComplete={handleDaumPostcodeEmbed} autoClose={false} />)
}
  
  return (
    <Dialog
      open={props.open}
      onClose={onClickCancel}
      fullWidth={true}
      size= {"xxl"}
    >
      <DialogTitle id="customized-dialog-title" >
        {props.title}
        <IconButton aria-label="close" onClick={onClickCancel}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent >
        {displayMode}
      </DialogContent>

    </Dialog>

  );
}
Address.defaultProps = {
  title: "배송지 변경",
  contents: "Contents",
}