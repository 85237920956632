import { useEffect, useState } from "react";
import { customAxios } from "../Common/customAxios";
import { Link, Navigate, useNavigate } from "react-router-dom";

function AdminStoreList() {
  const [start, setStart] = useState(false);
  const [storeListRefresh, setStoreListRefresh] = useState(true)
  const [storeList, setStoreList] = useState()
  const navigate = useNavigate()

  useEffect(() => {
    setStart(true);
  }, []);
  useEffect(() => {

    const getStoreList = async()=>{
      const result = await customAxios.get("/api/getStoreList")
      setStoreList(result.data.response)
    }

    if(start){
      if(storeListRefresh === true){
        getStoreList()
        setStoreListRefresh(false)
      }
    }
    
  }, [start, storeListRefresh]);

  const clickUpdateBtn = (e, index) => {

    navigate("/adminStore/AdminStoreWritePage?Store_Code="+storeList[index].Store_Code)
  }
  const clickDeleteBtn = async (e, index) => {

    if(window.confirm("삭제하시겠습니까?")){
      
      const result = await customAxios.post("/api/deleteStore",{storeCode:storeList[index].Store_Code})
      if(result.data.state === 100){
        setStoreListRefresh(true)
      }
    }

  }

  const thClass = "border border-black border-blue-gray-100 bg-blue-gray-50 p-1";
  const tdClass = "border border-black border-blue-gray-100 p-1";

  const StoreListRender = ({ storeList }) => {
  
    return (
      <>
        {storeList && storeList.map((store, index) => (
          <tr key={index}>
            <td className={`${tdClass} w-1/12`}>{store.Store_Code}</td>
            <td className={`${tdClass} w-1/12`}>{store.Store_CategoryId}</td>
            <td className={`${tdClass} w-1/12`}>{store.Store_Visible ? '노출' : '숨김'}</td>
            <td className={`${tdClass} w-6/12`}>
              <Link to={"/StoreDetail?Store_Code="+store.Store_Code} >{store.Store_Title}</Link>
            </td>
            <td className={`${tdClass} w-1/12`}>{new Date(store.Store_Date).toLocaleDateString()}</td>
            <td className={`${tdClass} w-1/12`}>{store.Store_Views}</td>
            <td className={`${tdClass} w-1/12`}>
              <div className="flex justify-center gap-2">
                <button className="px-1 py-1 rounded-md bg-Cgcolor text-white" onClick={(e) => clickUpdateBtn(e, index)}> 수정</button>
                <button className="px-1 py-1 rounded-md bg-Cgcolor text-white" onClick={(e) => clickDeleteBtn(e, index)}> 삭제</button>
              </div>
            </td>
          </tr>
        ))}
      </>
    );
  };
  
  
  

  return (
    <div className=" p-1 overflow-x-auto w-full">
      <table className="table-fixed whitespace-nowrap">
        <thead className="">
          <tr>
            <th className={thClass + " w-1/12"}>스토어 코드</th>
            <th className={thClass + " w-1/12"}>분류</th>
            <th className={thClass + " w-1/12"}>노출</th>
            <th className={thClass + " w-6/12"}>스토어 제목</th>
            <th className={thClass + " w-1/12"}>등록 날짜</th>
            <th className={thClass + " w-1/12"}>조회수</th>
            <th className={thClass + " w-1/12"}>변경</th>
          </tr>
        </thead>
        <tbody>
          <StoreListRender storeList={storeList} />
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={4}></td>
            <td>
              <div className="flex justify-center">
                <Link to={"/adminStore/AdminStoreWritePage"}
                  className="px-3 py-2 rounded-md text-sm font-bold duration-300 bg-Cgcolor text-white">
                  추가
                </Link>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}

export default AdminStoreList;
