import Header from '../../Components/Fixed/Header';
import Footer from '../../Components/Fixed/Footer';
import JoinData from '../../Components/Fixed/JoinData';

function Join() {
    return (
        <div className="max-w-lg mx-auto">
            <Header />
            <JoinData />
            <Footer />


        </div>


    );
}

export default Join;


