import axios from "axios";

const customAxios = axios.create({
    headers: {
        // test123:"ttt"
    }
});

customAxios.interceptors.request.use(
    async (req) => {

        // console.log(req.url)
        // await getAccessToken()
        
        return req;
    },
);

customAxios.interceptors.response.use(
    (response) => {
        return response;
    },
    async (err) =>{
        const {config, response} = err
        // console.log(config.url)

        //accessToken Expired
        if(response.status === 402 && config.sent !==true){
            console.log("Access Expired ")

            config.sent = true
            const res = await customAxios.get('/api/getAccessToken')
            if (res.data.state === 101) {
                customAxios.defaults.headers.common['Authorization'] = `Bearer ${res.data.accessToken}`
                
                // console.log(config.headers.Authorization)
                config.headers.Authorization = `Bearer ${res.data.accessToken}`
                // console.log(config.headers.Authorization)
                console.log("Access re")
                return customAxios(config)                
            }
            else{
                console.log("response getAccessToken 실패")
                alert("세션이 만료되었습니다. 다시 로그인 해주세요")

                await customAxios.get("/api/Logout")
                customAxios.defaults.headers.common['Authorization'] = ""

                window.location.replace("/")
            }
        }
        else{
            return Promise.reject(err);
        }


    }
);

const getAccessToken = async() =>{
    const res = await customAxios.get('/api/getAccessToken',{
        headers:{'Cache-Control': 'no-store', Pragma: 'no-store',Expires: '0',}
    })
    if (res.data.state === 101) {
        console.log("get")
        customAxios.defaults.headers.common['Authorization'] = `Bearer ${res.data.accessToken}`
    }
    else {
        console.log(res.data.message)
    }
    return res
}


const LoginEmail = async(email, password) =>{

    const result = {
        state : -100,
        MemberData :null
    }

    const res = await customAxios.post('/api/Login',{ 
        email : email, 
        password : password,
    },)

    if(res.data.state ===101){
        customAxios.defaults.headers.common['Authorization'] = `Bearer ${res.data.accessToken}`
        result.state = 101
    }

    return  result
}
const LogoutEmail = async () =>{

    let res = await customAxios.get('/api/Logout')
    if(res.data.state ===100){
        customAxios.defaults.headers.common['Authorization'] =""
    }

    return res
}

const getProduct = async(Product_Index) =>{
    const result = await customAxios("/api/getProduct?Product_Index=" + Product_Index)
    return result
}


const getDelivery = async() =>{
    const result = await customAxios("/api/getDelivery")
    return result
}



export { customAxios,
    LoginEmail,
    LogoutEmail,
    getAccessToken,
    getProduct,
    getDelivery,
 }
