import { useState, useEffect } from "react";
import { customAxios } from "../Common/customAxios";
import dayjs from "dayjs";


export const TimeUtils = (props) => {

    const [startTimer, setStartTimer] = useState(0);

    const [days, setDays] = useState(parseInt(0));
    const [hours, setHours] = useState(parseInt(0));
    const [minutes, setMinutes] = useState(parseInt(0));
    const [seconds, setSeconds] = useState(parseInt(0));

    const [serverTime, setServerTime] = useState(null)
    const [sumTime, setSumTime] = useState(0)
    const [isAfter, setAfter] = useState(false)

    const timeFunc = () => {

        if(serverTime === null)
        return

        
        // const testTime = "2024/03/11 15:00:00"
        // const serverDate = new Date(serverTime);

        const serverDate = new Date(serverTime);
        const curDate = dayjs(serverDate).add(sumTime,'milliseconds').toDate()
        const limitDate = new Date(props.limitDateTime);
        const countDate = limitDate - curDate;

        // console.log(serverDate)
        // console.log(curDate)
        // console.log(limitDate)
        if(dayjs(curDate).isAfter(limitDate))
            setAfter(true)

        if (countDate <= 0) {

            setDays(0);
            setHours(0);
            setMinutes(0);
            setSeconds(0);
        }

        const diffDay = Math.floor(countDate / (1000 * 60 * 60 * 24));
        const diffHour = Math.floor((countDate / (1000 * 60 * 60)) % 24);
        const diffMin = Math.floor((countDate / (1000 * 60)) % 60);
        const diffSec = Math.floor(countDate / 1000 % 60);

        setDays(diffDay);
        setHours(diffHour);
        setMinutes(diffMin);
        setSeconds(diffSec);

        setSumTime(prevTime => prevTime + 1000)

    }

    useEffect(() => {
        
        const getServerTime = async() =>{
            const result = await customAxios.get("/api/getTime")
            if(result.data.state ===100){
                setServerTime(result.data.response)
                setStartTimer(1);
            }
        }
        getServerTime()


    }, [])


    useEffect(() => {
        if (startTimer === 1  && serverTime !== null) {
            timeFunc();
        }
    }, [startTimer,serverTime])

    useEffect(() => {
        const countdown = setInterval(() => {
            timeFunc();
        }, 1000);
        return () => clearInterval(countdown);
    }, [seconds]);


    if (serverTime) {

        if(isAfter){
            return (
                <h1 className='flex justify-center  text-white text-center text-sm bg-black md:opacity-100 opacity-70 rounded-lg py-1 px-1'>
                    <span className='flex'><b className='md:block hidden mr-2'>마감</b></span>
                </h1>
            )
        }
        else{
            return (
                <h1 className='flex justify-center  text-white text-center text-sm bg-black md:opacity-100 opacity-70 rounded-lg py-1 px-1'>
                    <span className='flex'><b className='md:block hidden mr-2'>마감까지</b>남은 시간</span>
                    <span className='pl-2 text-Cgcolor'>
                        {days < 10 ? `0${days}` : days}일 {hours < 10 ? `0${hours}` : hours}:{minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                    </span>
                </h1>
            )
        }
        
    }


}

export const dateKor = (day) =>{

    let dayStr =""

    switch (day) {
        case 0:
            dayStr = "일"
            break;
        case 1:
            dayStr = "월"
            break;
        case 2:
            dayStr = "화"
            break;
        case 3:
            dayStr = "수"
            break;
        case 4:
            dayStr = "목"
            break;
        case 5:
            dayStr = "금"
            break;
        case 6:
            dayStr = "토"
            break;
    }

    return dayStr
}

