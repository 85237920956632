import { useState, useEffect } from "react"
import { Progress } from "@material-tailwind/react";

const Test = () => {
  const [start, setStart] = useState(false)
  const [max, setMax] = useState(0)
  const [currentValue, setCurrentValue] = useState(0)

  useEffect(() => {
    if (!start) { // start가 false일 때만 실행
      setStart(true);
    }
  }, [start]); // 의존성 배열에 start 추가

  useEffect(() => {
    const getSignUpTest = async () => {
      console.log("getSignUpTest")
      const response = await fetch('/api/getSignUpTest');
      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value);
        const lines = chunk.split('\n').filter(line => line.trim() !== '');
        
        lines.forEach(line => {
          console.log(line)
          const data = JSON.parse(line);
          if (data.message === 'isRunning') {
            alert('해당 프로세스가 이미 실행되었습니다.');
          } else if (data.message === 'start') {
            setMax(data.max);
            setCurrentValue(0);
          } else if (data.message === 'end') {
            setCurrentValue(max);
          } else if (typeof data.message === 'number') {
            setCurrentValue(data.message);
          }
        });
      }
    }
    if (start && max === 0) {
      console.log(start)
      console.log(max)
      getSignUpTest()
    }
  }, [start, max])

  const progressValue = (currentValue / max) * 100;

  return (
    <div className="flex justify-center items-center h-screen p-5">
       <Progress value={progressValue} size="lg" color="orange"/>
    </div>
  )
}
export default Test
