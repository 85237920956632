import Footer from "../../Components/Fixed/Footer";
import SignUpExamDetailCard from "../../Components/Card/SignUpExamDetailCard";


function SignUpExamDetail() {
    
    return (
        <div className=''>
            <div className='flex w-full px-2 pt-1 justify-center'>
                <SignUpExamDetailCard/>
            </div>
            <Footer />
        </div>
    )
}

export default SignUpExamDetail;
