import Header from '../../Components/Fixed/Header';
import Footer from '../../Components/Fixed/Footer';

import Top_BG from '../../Images/Class/bg.jpg';
import Bottm_BG from '../../Images/Class/bg2.jpg';
import Bg_mo from '../../Images/Class/bg_mo.jpg';
import Bg_mo2 from '../../Images/Class/bg2_mo.jpg';

import { useEffect, useState } from 'react';

import Trainer01 from '../../Images/Class/TrainerDetail01.jpg';
import Trainer02 from '../../Images/Class/TrainerDetail02.png';
import Trainer03 from '../../Images/Class/TrainerDetail03.jpg';
import Trainer04 from '../../Images/Class/TrainerDetail04.jpg';
import Trainer05 from '../../Images/Class/TrainerDetail05.jpg';
import Trainer06 from '../../Images/Class/TrainerDetail06.jpg';
import Trainer07 from '../../Images/Class/TrainerDetail07.jpg';
import Trainer08 from '../../Images/Class/TrainerDetail08.jpg';
import Trainer09 from '../../Images/Class/TrainerDetail09.jpg';
import Trainer10 from '../../Images/Class/TrainerDetail10.jpg';
import Trainer11 from '../../Images/Class/TrainerDetail11.jpg';
import Trainer12 from '../../Images/Class/TrainerDetail12.jpg';
import Trainer13 from '../../Images/Class/TrainerDetail13.png';
import Trainer14 from '../../Images/Class/TrainerDetail14.jpg';
import Trainer15 from '../../Images/Class/TrainerDetail15.jpg';
import Trainer16 from '../../Images/Class/TrainerDetail16.jpg';
import Trainer17 from '../../Images/Class/TrainerDetail17.jpg';
import Trainer18 from '../../Images/Class/TrainerDetail18.jpg';
import Trainer19 from '../../Images/Class/TrainerDetail19.jpg';
import Trainer20 from '../../Images/Class/TrainerDetail20.jpg';
import Trainer21 from '../../Images/Class/TrainerDetail21.jpg';
import Trainer22 from '../../Images/Class/TrainerDetail22.jpg';
import Trainer23 from '../../Images/Class/TrainerDetail23.jpg';
import Trainer24 from '../../Images/Class/TrainerDetail24.jpg';
import Trainer25 from '../../Images/Class/TrainerDetail25.jpg';
import Trainer26 from '../../Images/Class/TrainerDetail26.jpg';
import Trainer27 from '../../Images/Class/TrainerDetail27.jpg';

let Trainers = [
  {
    name: "송현용",
    src: Trainer01
  },
  {
    name: "조성호",
    src: Trainer02
  },
  {
    name: "육예진",
    src: Trainer03
  },
  {
    name: "황수영",
    src: Trainer04
  },
  {
    name: "조예서",
    src: Trainer05
  },
  {
    name: "마리원",
    src: Trainer06
  },
  {
    name: "이나람",
    src: Trainer07
  },
  {
    name: "유지영",
    src: Trainer08
  },
  {
    name: "장규철",
    src: Trainer09
  },
  {
    name: "재희",
    src: Trainer10
  },
  {
    name: "영선",
    src: Trainer11
  },
  {
    name: "조세현",
    src: Trainer12
  },
  {
    name: "한혜지",
    src: Trainer13
  },
  {
    name: "정재린",
    src: Trainer14
  },
  {
    name: "오현준",
    src: Trainer15
  },
  {
    name: "우영롱",
    src: Trainer16
  },
  {
    name: "강길구",
    src: Trainer17
  },
  {
    name: "고아람",
    src: Trainer18
  },
  {
    name: "김혜미",
    src: Trainer19
  },
  {
    name: "류겸조",
    src: Trainer20
  },
  {
    name: "박우담",
    src: Trainer21
  },
  {
    name: "신근영",
    src: Trainer22
  },
  {
    name: "신진아",
    src: Trainer23
  },
  {
    name: "이수연",
    src: Trainer24
  },
  {
    name: "임재환",
    src: Trainer25
  },
  {
    name: "조형원",
    src: Trainer26
  },
  {
    name: "허예림",
    src: Trainer27
  },
]

const sortTrainders = Trainers.sort((a, b) => a.name.localeCompare(b.name));
// console.log(sortTrainders)


function ClassData() {

  const [startTimer, setStartTimer] = useState(0);
  const [days, setDays] = useState(parseInt(0));
  const [hours, setHours] = useState(parseInt(0));
  const [minutes, setMinutes] = useState(parseInt(0));
  const [seconds, setSeconds] = useState(parseInt(0));

  const timeFunc = () => {

    let CurDate = new Date();
    let LimitDate = new Date("2023/01/09 23:59:59");
    let CountDate = LimitDate - CurDate;

    if (CountDate <= 0) {

      setDays(0);
      setHours(0);
      setMinutes(0);
      setSeconds(0);
    }

    const diffDay = Math.floor(CountDate / (1000 * 60 * 60 * 24));
    const diffHour = Math.floor((CountDate / (1000 * 60 * 60)) % 24);
    const diffMin = Math.floor((CountDate / (1000 * 60)) % 60);
    const diffSec = Math.floor(CountDate / 1000 % 60);

    setDays(diffDay);
    setHours(diffHour);
    setMinutes(diffMin);
    setSeconds(diffSec);

  }

  useEffect(() => {
    setStartTimer(1);

  }, [])
  useEffect(() => {
    if (startTimer === 1) {
      timeFunc();
    }
  }, [startTimer])

  useEffect(() => {
    const countdown = setInterval(() => {
      timeFunc();
    }, 1000);
    return () => clearInterval(countdown);
  }, [seconds]);

  //Accordion
  const [open, setOpen] = useState(0);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  }
  //Accordion End

  const result = []
  for (let i = 0; i < sortTrainders.length; i++) {
    result.push(
      <div key={"trainer" + i}>
        <div key={i}><img src={Trainers[i].src} className='w-full rounded' alt={'트레이너' + (i + 1)} /></div>
      </div>)

  }


  return (
      <div className='break-keep camp_wrap'>
        <section className='text-white relative before'>
          <article className='mx-auto h-screen max-w-7xl md:py-32 pt-32 pb-28 px-5 relative z-1 flex items-center'>
            <div className=''>
              <h1 className='text-4xl font-bold leading-normal w-full'>
                <span className='text-xl block text-Cgcolor'>창꼬실용음악학원</span>
                <span className='text-xl block text-Cgcolor'>창꼬M실용음악학원</span>
              </h1>
              <h2 className='text-2xl font-bold leading-normal w-full my-10'>
                <span className='block'>당신의 목표를 이루기 위해 창꼬가 함께합니다.</span>
              </h2>
              <h3 className='text-xl leading-normal w-full'>
                <span className='block opacity-70'>창꼬의 전문가들과 함께, 실현 가능한 목표로 만드세요.</span>
              </h3>
            </div>
          </article>
          <img src={Top_BG} className='h-screen absolute py-32 inset-0 w-full md:opacity-100 opacity-0' alt='Bg1' />
          <img src={Bg_mo} className='absolute inset-0 py-32 w-full md:opacity-0 opacity-100' alt='Bg1_1' />
        </section>
        <section className='text-white relative bg-black'>
          <article className='mx-auto max-w-7xl px-5 relative z-1 flex items-center'>
            <div className='flex-col gap-2'>
              <h1 className='text-4xl font-bold leading-normal w-full'>
                <span className='text-xl block text-white mt-10 mb-10'>창꼬 트레이너를 소개합니다.</span>
              </h1>
              <div className='mb-10'>
                <div className='grid gap-6 space-y-6'>
                  {result}
                </div>
              </div>
            </div>
          </article>
          {/* <img  src={Bg} className='h-screen absolute inset-0 w-full ' alt='Bg' />  */}
        </section>
        <section className='text-white relative before overflow-hidden'>
          <article className='mx-auto md:h-screen max-w-7xl md:py-32 pt-10 pb-28 px-5 relative z-1 flex items-center'>
            <div className=''>
              <h2 className='text-xl md:text-2xl font-bold leading-normal w-full my-10'>
                <span className='block'>상담문의는 <span className='text-Cgcolor'>카카오톡 채널</span>에서만 가능합니다.</span>
              </h2>
              <h2 className='mt-5 w-full'>
              <div className='mt-5 mb-10'>
                  <h1 className='text-xsleading-normal w-full'>
                    <span className='text-xs block text-white'>교습과목 및 교습비</span>
                    <span className='text-xs block text-white mt-3'>창꼬실용음악학원 (학원등록번호 : 수원 제7609호)</span>
                    <div className='flex-col gap-2 text-xs'>
                      <p>취미(보컬, 작사, 작곡, 피아노, 화성학) : 250,000</p>
                      <p>보컬(오디션, 전문, 입시) : 450,000</p>
                      <p>프리미엄 보컬(오디션, 전문, 입시) : 500,000</p>
                      <p>취미 댄스 : 200,000</p>
                      <p>댄스 : 280,000</p>
                    </div>
                  </h1>
                  <h1 className='text-xs leading-normal w-full mt-3'>
                    <span className='text-xs block text-white'>창꼬엠실용음악학원 (학원등록번호 : 수원 제7903호)</span>
                    <div className='flex-col gap-2 text-xs'>
                      <p>취미(보컬, 작사, 작곡, 피아노) : 250,000</p>
                      <p>보컬(오디션, 전문) : 450,000</p>
                      <p>프리미엄 보컬(오디션, 전문) : 500,000</p>
                      <p>취미 댄스 : 200,000</p>
                      <p>오디션 댄스 : 280,000</p>
                    </div>
                  </h1>
                </div>
              </h2>
              <div className='w-full mt-10'>
                <a href="https://pf.kakao.com/_QxbxmRG" target='_blank' className='bg-Cgcolor text-sm font-bold rounded-lg py-3 px-4 inline-block '>상담문의</a>
              </div>
            </div>
          </article>
          <img src={Bottm_BG} className='md:h-screen absolute inset-0 w-full opacity-0 md:opacity-100' alt='Bg1' />
          <img src={Bg_mo2} className='absolute inset-0 w-full opacity-100 md:opacity-0' alt='Bg1_1' />
        </section>
        {/* 참가신청버튼 Start */}
        {/* span 부분 개발로 카운트 설정 부탁합니다. + 모집합니다 하단 h1 동일 */}
        <section className='fixed_wrap md:bottom-3 bottom-0 z-20'>
          <div className='md:flex fixed_bt box-border md:px-5 md:py-5 rounded-lg'>
            {/* <h1 className='flex justify-center  text-white text-center text-sm bg-black md:opacity-100 opacity-70 rounded-lg py-1 px-1'>
                  <span className='block flex'><b className='md:block hidden mr-2'>접수까지</b>남은 시간</span>
                  <span className='pl-2 text-Cgcolor'>
                      {days < 10 ? `0${days}` : days}일 {hours < 10 ? `0${hours}` : hours}:{minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                  </span>
              </h1> */}
            {/* <Link to="/Participate" className='bg-Cgcolor block text-center rounded-lg flex-ac-jc py-1 px-1 md:py-2 md:px-2'>상담 신청</Link> */}
            <a href="https://pf.kakao.com/_QxbxmRG" target='_blank' className='bg-Cgcolor block text-center rounded-lg flex-ac-jc py-3 px-1 md:py-2 md:px-2'>상담문의</a>
          </div>
        </section>
        {/* 참가신청버튼 End */}
      </div>

  );
}

export default ClassData;

