import { Typography, Accordion, AccordionHeader, AccordionBody, } from "@material-tailwind/react"
import { useState } from "react";

const DisplayDelivery = (props) => {

    const [open, setOpen] = useState(0);
    const handleOpen = (value) => setOpen(open === value ? 0 : value);

    const selectDelivery = props.delivery
    const classes = "p-2 border-b border-blue-gray-50"

    if (selectDelivery === null || selectDelivery === undefined) {

    }
    else {
        return (
            <div key="Delivery">
                <Accordion open={open === 1}>
                    <AccordionHeader
                        onClick={() => handleOpen(1)}
                        className={`text-sm border-b-0 transition-colors 
                        ${open === 1 ? "text-blue-500 hover:!text-blue-700" : ""}`}
                    >
                        배송지 정보
                    </AccordionHeader>
                    <AccordionBody>
                    <table className="w-full">
                        <tbody>
                            <tr>
                                <td className={`${classes} bg-blue-gray-50/50 text-right`}>
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        이름
                                    </Typography>
                                </td>
                                <td className={classes}>
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        {selectDelivery.Delivery_PostName}
                                    </Typography>
                                </td>
                            </tr>
                            <tr>
                                <td className={`${classes} bg-blue-gray-50/50 text-right`}>
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        연락처
                                    </Typography>
                                </td>
                                <td className={classes}>
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        {selectDelivery.Delivery_Number}
                                    </Typography>
                                </td>
                            </tr>
                            <tr>
                                <td className={`${classes} bg-blue-gray-50/50 text-right`}>
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        배송 주소
                                    </Typography>
                                </td>
                                <td className={`${classes} flex items-center gap-2`}>
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        {selectDelivery.Delivery_Address},
                                    </Typography>
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        {selectDelivery.Delivery_Detail}
                                    </Typography>
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        [{selectDelivery.Delivery_Postcode}]
                                    </Typography>
                                </td>
                            </tr>
                            <tr>
                                <td className={`${classes} bg-blue-gray-50/50 text-right`}>
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        배송 도로명 주소
                                    </Typography>
                                </td>
                                <td className={`${classes} flex items-center gap-2`}>
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        {selectDelivery.Delivery_RoadAddress},
                                    </Typography>
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        {selectDelivery.Delivery_Detail}
                                    </Typography>
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        [{selectDelivery.Delivery_Postcode}]
                                    </Typography>
                                </td>
                            </tr>
                            <tr>
                                <td className={`${classes} bg-blue-gray-50/50 text-right`}>
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        배송 요청사항
                                    </Typography>
                                </td>
                                <td className={classes}>
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        {selectDelivery.Delivery_Requests}
                                    </Typography>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </AccordionBody>
            </Accordion>
            </div >
        )
    }

    
}

export default DisplayDelivery