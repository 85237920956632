import Header from "../../Components/Fixed/Header";
import Footer from "../../Components/Fixed/Footer";

import ServiceText from "../../Components/Text/ServiceText";
function Service() {
  
    return (
      <div className='sub-bg'>
        <Header/>
            <div className='mx-auto max-w-7xl px-5 py-32 text-white'>
              <ServiceText/>
            </div>
        <Footer/>
      </div> 
      
    );
  }
    
    export default Service;
    
  