import { useEffect, useRef, useState } from "react";
import { customAxios } from "../Common/customAxios";
import { Input, Textarea, Typography } from "@material-tailwind/react";
import StoreDisplay from "../Store/StoreDisplay";
import dayjs from "dayjs";
import { useNavigate, useSearchParams } from "react-router-dom";

function AdminStoreForm() {

  const [start, setStart] = useState(false);
  const [defaultCode, setDefaultCode] = useState(dayjs().format("YYMMDDHHmmss"))

  const [storeCategoryData, setStoreCategoryData] = useState(null);
  const [storeCategoryRefresh, setStoreCategoryRefresh] = useState(true)
  const [idList, setIdList] = useState(null);

  const [addCount, setAddCount] = useState(0)

  const [searchParams, setSearchParams] = useSearchParams()
  const storeCode = searchParams.get("Store_Code")

  const initialProduct = {
    code: defaultCode + "_" + addCount,
    name: "상품 이름",
    description: "설명",
    price: 0,
    deliveryFee: 0,
    images: [],

  }

  const [title, setTitle] = useState("제목 제목")
  const [subTitle, setSubTitle] = useState("서브 제목")
  const [context, setContext] = useState("")
  const [contextImg, setContextImg] = useState([])
  const [categoryId, setCategoryId] = useState("")
  const [storeMode, setStoreMode] = useState("limit"); // 선택된 값의 상태
  const [visible, setVisible] = useState(false)
  const navigate = useNavigate()

  // 라디오 버튼 선택 시 상태 업데이트
  const handleRadioChange = (e) => {
    setStoreMode(e.target.value);
  };

  const [products, setProducts] = useState([initialProduct])

  const displayData = {
    title: title,
    subTitle: subTitle,
    context: context,
    contextImg: contextImg,
    products: products,
    categoryId: categoryId,
    storeMode: storeMode,
    visible: visible,
  }

  const addProduct = () => {

    const newCount = addCount + 1

    const data = initialProduct
    data.code = defaultCode + "_" + newCount
    setProducts([...products, data])
    setAddCount(newCount)
  }


  // form 데이터 삭제
  const removeProduct = (index) => {
    const newProducts = [...products];

    if (newProducts.length === 1) {
      alert("상품은 최소 1개 이상 존재해야합니다.")
      return
    }

    newProducts.splice(index, 1);
    setProducts(newProducts);
  };

  // form 데이터 변경 처리
  const handleChange = (index, e) => {
    const { name, value } = e.target;
    let newValue = value;

    // 숫자 입력 필드에 대해서만 처리합니다.
    if (name === "price" || name === "deliveryFee") {
      // 숫자만 추출하고 앞의 0을 제거합니다.
      newValue = value.replace(/^0+/, '').replace(/\D/g, '');

      // 값이 빈 문자열인 경우, 0으로 설정합니다.
      if (newValue === '') {
        newValue = 0;
      }
    }

    const newProducts = [...products];
    newProducts[index][name] = newValue;
    setProducts(newProducts);
  };

  // 이미지 추가 처리
  const handleImageChange = (index, e) => {
    const newProducts = [...products];
    const selectedImages = Array.from(e.target.files);

    if (newProducts[index].images.length > 4) {
      alert("상품 이미지는 현재 5개까지 첨부 가능합니다.")
      return
    }

    // 기존 이미지와 새로운 이미지를 합칩니다.
    newProducts[index].images = newProducts[index].images.concat(selectedImages);

    setProducts(newProducts);
  };


  // 이미지 삭제 처리
  const removeImage = (productIndex, imageIndex) => {
    const newProducts = [...products];
    newProducts[productIndex].images.splice(imageIndex, 1);
    setProducts(newProducts);
  };


  // 이미지 클릭 시 새 창 열기
  const openImageInNewTab = (imageURL) => {
    window.open(imageURL, '_blank');
  };

  const isAnyProductDataEmpty = () => {
    return products.some(product => (
      Object.values(product).some(value => value === '' || value === null || value === undefined)
    ));
  };
  const isDuplicateCode = () => {
    const codes = products.map(product => product.code); // products 배열에서 code만 추출하여 새로운 배열 생성
    const uniqueCodes = new Set(codes); // 중복을 제거하기 위해 Set을 사용하여 유일한 코드만 포함하는 새로운 Set 객체 생성

    // 원래 배열과 중복이 제거된 Set의 크기를 비교하여 중복 여부를 판단
    return codes.length !== uniqueCodes.size;
  };

  const addStore = async () => {
    if (title === "") {
      alert("제목이 작성되지 않았습니다.")
      return
    }
    if (subTitle === "") {
      alert("서브 제목이 작성되지 않았습니다.")
      return
    }
    if (products.length === 0) {
      alert("등록된 상품이 없습니다.")
      return
    }
    if (isAnyProductDataEmpty()) {
      alert("등록된 상품에 빈칸이 있습니다.")
      return
    }
    if (storeMode === "") {
      alert("판매모드가 설정되지 않았습니다.")
      return
    }

    if (context === "") {
      alert("본문이 작성되지 않았습니다.")
      return
    }

    if (isDuplicateCode()) {
      alert("상품 코드가 중복되었습니다.")
      return
    }

    const codeList = []
    products.forEach((product) => {
      codeList.push(product.code)
    })

    if(storeCode){

    }
    else{

      const check = await customAxios.post("/api/checkDuplicateCode", { codeList: codeList })
      if (check.data.response.isDuplicated) {
        const list = []
        check.data.response.DuplicatedList.forEach(element => {
          list.push(element)
          // console.log(element)
        });

        alert("이미 등록된 상품코드 입니다.\n(" + list + ")")
        return
      }
    }

    


    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("subTitle", subTitle);
      formData.append("context", context);
      formData.append("categoryId", categoryId);
      formData.append("storeMode", storeMode);
      formData.append("visible", visible);
      formData.append("storeCode",storeCode)

      // 상품 데이터 추가
      products.forEach((product, index) => {
        formData.append(`products[${index}][code]`, product.code);
        formData.append(`products[${index}][name]`, product.name);
        formData.append(`products[${index}][description]`, product.description);
        formData.append(`products[${index}][price]`, Number(product.price));
        formData.append(`products[${index}][deliveryFee]`, Number(product.deliveryFee));
        formData.append(`products[${index}][imageCount]`, Number(product.images.length))
        product.images.forEach((image) => {
          formData.append('productImg', image);
        });
      });

      // 본문 이미지 추가
      contextImg.forEach((image) => {
        formData.append('contextImg', image);
      });

      const response = await customAxios.post("/api/addStore", formData);
      console.log(response.data);
      // 성공적으로 등록된 경우에 대한 처리를 추가할 수 있습니다.
      // 예를 들어, 사용자에게 성공 메시지를 표시하거나 리다이렉트를 수행할 수 있습니다.
    } catch (error) {
      console.error("Error adding store:", error);
      // 에러가 발생한 경우에 대한 처리를 추가할 수 있습니다.
      // 예를 들어, 사용자에게 오류 메시지를 표시하거나 다시 시도할 수 있도록 안내할 수 있습니다.
    }

    alert("스토어페이지가 등록되었습니다.")
    navigate("/adminStore/AdminStorePage")

  }

  const recursiveFlatList = (data, flatList, parentTitle = '') => {
    flatList.push(
      {
        id: data.id,
        title: data.title,
        visible: data.visible,
        count: data.count,
        parentTitle: parentTitle,
      }
    )

    if (data.dataList) {
      data.dataList.forEach((mData) => {
        recursiveFlatList(mData, flatList, data.title)
      })
    }
  }

  useEffect(() => {
    setStart(true);
  }, []);

  useEffect(() => {

    const getList = async () => {
      const result = await customAxios.get("/api/getStoreCategoryList")
      if (result.data.state === 101) {
        setStoreCategoryData(result.data.response);

        const flatList = [];
        result.data.response.forEach((data) => {
          recursiveFlatList(data, flatList);
        });
        setCategoryId(flatList[0].id)
        setIdList(flatList);
      }
    }

    if (start) {
      if (storeCategoryRefresh === true) {
        getList()
        setStoreCategoryRefresh(false)
      }
    }
  }, [start, storeCategoryRefresh]);

  useEffect(() => {

    const getStoreData = async () => {

      const result = await customAxios.post("/api/getStoreData", { code: storeCode })
      // console.log(result.data.response)
      // console.log(result.data.response.contextImg)

      // console.log("visible : "+result.data.response.visible)

      setTitle(result.data.response.title)
      setSubTitle(result.data.response.subTitle)
      setCategoryId(result.data.response.categoryId)
      setStoreMode(result.data.response.storeMode)
      
      if(result.data.response.visible === 1)
        setVisible(true)
      else{
        setVisible(false)
      }
      setContext(result.data.response.context)

      const files = []
      for (let i = 0; i < result.data.response.contextImg.length; i++) {
        const response = await fetch(result.data.response.contextImg[i]);
        // 응답 데이터를 blob 형식으로 변환
        const blob = await response.blob();
        files.push(blob)
      }
      setContextImg(files)

      const products = result.data.response.products
      for (let i = 0; i < products.length; i++) {
        for (let j = 0; j < products[i].images.length; j++) {
          const response = await fetch(products[i].images[j]);
          // 응답 데이터를 blob 형식으로 변환
          const blob = await response.blob();
          products[i].images[j] = blob
        }
      }
      setProducts(products)
    }

    if (start && storeCode) {
      getStoreData()
    }
  }, [start, storeCode])

  const ContextImgChange = (event) => {
    const files = event.target.files; // 업로드된 파일들

    // 파일이 존재하는 경우에만 처리
    if (files && files.length > 0) {
      const updatedContextImg = [...contextImg]; // 기존 이미지 배열 복사

      // 업로드된 각 파일을 배열에 추가
      for (let i = 0; i < files.length; i++) {
        updatedContextImg.push(files[i]);
      }

      // 변경된 이미지 배열을 상태에 업데이트
      setContextImg(updatedContextImg);
    }
  };


  const removeContextImg = (index) => {
    setContextImg(prevContextImg => prevContextImg.filter((_, idx) => idx !== index));
  };


  const handleSubmit = (e) => {
    e.preventDefault()
  }




  return (
    <div className=" p-1 w-full">
      <div className="flex w-full ">
        <div className="w-1/3 p-5 ">
          <form className="mt-8 mb-2" onSubmit={handleSubmit}>
            <div className="mb-1 flex flex-col gap-3 ">
              <Typography variant="h6" color="blue-gray" className="mb-1">
                제목
              </Typography>
              <Input
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
              />
              <Typography variant="h6" color="blue-gray" className="mb-1">
                서브 제목
              </Typography>
              <Input
                value={subTitle}
                onChange={(e) => setSubTitle(e.target.value)}
                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
              />
              <Typography variant="h6" color="blue-gray" className="mb-1">
                카테고리
              </Typography>
              <select onChange={(e) => setCategoryId(e.target.value)} value={categoryId}>
                {idList && idList.map((element) => {
                  return <option key={element.id} value={element.id}>{element.title}</option>;
                })}
              </select>

              <Typography variant="h6" color="blue-gray" className="mb-1">
                판매 모드
              </Typography>
              <div className="flex gap-2 items-center">
                {/* 라디오 버튼 그룹 */}
                <div>
                  <input
                    type="radio"
                    id="option1"
                    name="storeMode"
                    value="limit"
                    checked={storeMode === "limit"} // 선택된 값에 따라 체크 여부 결정
                    onChange={handleRadioChange} // 변경 이벤트 핸들러
                  />
                  <label htmlFor="option1">수량제한</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="option2"
                    name="storeMode"
                    value="noLimit"
                    checked={storeMode === "noLimit"}
                    onChange={handleRadioChange}
                  />
                  <label htmlFor="option2">수량제한없음</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="option3"
                    name="storeMode"
                    value="reservation"
                    checked={storeMode === "reservation"}
                    onChange={handleRadioChange}
                  />
                  <label htmlFor="option3">사전예약</label>
                </div>
              </div>
              <Typography variant="h6" color="blue-gray" className="mb-1">
                페이지 노출하기(보이기)
              </Typography>
              <div className="flex gap-2 items-center">
                {/* 라디오 버튼 그룹 */}
                <div>
                  <input
                    type="radio"
                    id="option2_1"
                    name="visble"
                    value="true"
                    checked={visible} // 선택된 값에 따라 체크 여부 결정
                    onChange={(e) => { setVisible(true) }} // 변경 이벤트 핸들러
                  />
                  <label htmlFor="option2_1">보이기</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="option2_2"
                    name="visble"
                    value="false"
                    checked={!visible}
                    onChange={(e) => { setVisible(false) }} // 변경 이벤트 핸들러
                  />
                  <label htmlFor="option2_2">숨기기</label>
                </div>
              </div>


              <Typography variant="h6" color="blue-gray" className="mb-1">
                본문 이미지
              </Typography>
              <div className="flex flex-wrap gap-4">
                {contextImg.map((image, index) => (
                  <div key={index} className="relative">
                    <img src={URL.createObjectURL(image)} alt={`Thumbnail ${index}`} className="w-40 h-40 object-cover rounded-lg" />
                    <button type="button" className="absolute top-0 right-0 p-1 bg-red-500 text-white rounded-full" onClick={() => removeContextImg(index)}>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>
                ))}
              </div>
              <label htmlFor="file-upload2" className="inline-flex items-center px-4 py-2 w-12 bg-white border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <span className="text-xl font-bold mr-2">+</span>
                <input id="file-upload2" name="file-upload2" type="file" accept="image/*" className="sr-only" onChange={ContextImgChange} />
              </label>

              <div className="mt-2">
                <Typography variant="h6" color="blue-gray" className="mb-1">
                  본문
                </Typography>
                <Textarea
                  className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                  size="lg"
                  rows={10}
                  resize={true}
                  value={context}
                  onChange={(e) => setContext(e.target.value)}
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                />
              </div>
            </div>
          </form>
          {/* 각 form 출력 */}
          {products.map((product, index) => (
            <div key={index} className="flex flex-col gap-1 rounded-xl border-2 border-blue-gray-300 p-2">
              <Typography variant="h6" color="blue-gray" className="mb-1">상품 {index + 1}</Typography>
              <div className="flex gap-2 items-center">
                <Typography variant="h6" color="blue-gray" className="w-1/6">코드</Typography>
                <Input
                  name="code"
                  value={product.code}
                  onChange={(e) => handleChange(index, e)}
                  className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }} />
              </div>
              <div className="flex gap-2 items-center">
                <Typography variant="h6" color="blue-gray" className="w-1/6">이름</Typography>
                <Input
                  name="name"
                  value={product.name}
                  onChange={(e) => handleChange(index, e)}
                  className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }} />
              </div>
              <div className="flex gap-2 items-center">
                <Typography variant="h6" color="blue-gray" className="w-1/6">가격</Typography>
                <Input
                  type="text"
                  name="price"
                  value={product.price}
                  maxLength={8}
                  onChange={(e) => handleChange(index, e)}
                  pattern="[0-9]*"
                  className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }} />
              </div>
              <div className="flex gap-2 items-center">
                <Typography variant="h6" color="blue-gray" className="w-1/6">배송비</Typography>
                <Input
                  name="deliveryFee"
                  pattern="[0-9]*"
                  value={product.deliveryFee}
                  maxLength={8}
                  onChange={(e) => handleChange(index, e)}
                  className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }} />
              </div>
              <div className="flex gap-2 items-center">
                <Typography variant="h6" color="blue-gray" className="w-1/6">상품 설명</Typography>
                <Textarea
                  className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                  name="description"
                  size="md"
                  rows={10}
                  resize={true}
                  value={product.description}
                  onChange={(e) => handleChange(index, e)}
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                />
              </div>
              <div className="flex items-center">
                <Typography variant="h6" color="blue-gray" className="w-1/6">이미지</Typography>
                {/* 이미지 목록 출력 */}
                <div className="flex flex-wrap gap-4">
                  {product.images.map((image, imageIndex) => (
                    <div key={imageIndex} className="relative">
                      <img className="w-40 h-40 object-cover rounded-lg cursor-pointer" src={URL.createObjectURL(image)} alt={`Product ${index + 1} - Image ${imageIndex + 1}`} onClick={() => openImageInNewTab(URL.createObjectURL(image))} />
                      {/* 이미지 삭제 버튼 */}
                      <button type="button" className="absolute top-0 right-0 p-1 bg-red-500 text-white rounded-full" onClick={() => removeImage(index, imageIndex)}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>
                  ))}
                </div>
                <label htmlFor={`file-upload${index}`} className="inline-flex px-4 py-2 w-12 bg-white border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <span className="text-xl font-bold mr-2">+</span>
                  <input id={`file-upload${index}`} name={`file-upload${index}`} type="file" accept="image/*" className="sr-only" onChange={(e) => handleImageChange(index, e)} />
                </label>
              </div>


              {/* 상품 삭제 버튼 */}
              <div className="flex justify-end">
                <button onClick={() => removeProduct(index)} className='  bg-Cgcolor text-white text-sm font-bold rounded-lg py-3 px-4 inline-block w-24'>상품 삭제</button>
              </div>
            </div>
          ))}
          <div className="flex justify-end">
            <button onClick={addProduct} className='mt-2 bg-Cgcolor text-white text-sm font-bold rounded-lg py-3 px-4 inline-block '>상품 추가하기</button>
          </div>
          <button onClick={addStore} className='mt-2 w-full bg-Cgcolor text-white text-sm font-bold rounded-lg py-3 px-4 inline-block '>상품 페이지 등록하기</button>
        </div>
        <div className="w-2/3">
          <StoreDisplay displayData={displayData} mode={"admin"} />
        </div>
      </div>

    </div>
  );
}

export default AdminStoreForm;
