import AdminAuthCheck from "../../Components/Admin/AdminAuthCheck";
import AdminProductList from "../../Components/Admin/AdminProductList";
import AdminHeader from "../../Components/Fixed/AdminHeader";

const AdminProducts = () => {

    return (
        <div className="h-[calc(100vh-2rem)] flex flex-row ">
            <AdminAuthCheck auth="M3"/>
            <AdminHeader mode={1}/>
            <div className="p-1">
            <AdminProductList/>
            </div>
        </div>
    )
}

export default AdminProducts