import Header from "../../Components/Fixed/Header";
import Footer from "../../Components/Fixed/Footer";
import ExamDetailCard from "../../Components/Card/ExamDetailCard";


function ExamDetail() {
    
    return (
        <div className=''>
            <div className='flex w-full px-2 pt-1 justify-center'>
                <ExamDetailCard/>
            </div>
            <Footer />
        </div>
    )
}

export default ExamDetail;
