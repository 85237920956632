import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function AdminAuthCheck(props) {

    const [start, setStart] = useState()
    const [authCheck, setAuthCheck] = useState()
    const loginState = useSelector((state) => state.login)
    const navigate = useNavigate()

    useEffect(() => {
        setStart(true)
    }, [])

    useEffect(() => {
        if (start) {
            if (loginState.isLogin === false) {
                alert("로그인이 필요합니다.")
                navigate("/", { replace: true })
            }
            else if (loginState.isLogin === true) {

                if (loginState.Member_Auth === "U") {
                    alert("권한이 없습니다.")
                    navigate("/", { replace: true })
                }
                else {

                    const authArray = []

                    if (props.auth === "M1") {
                        authArray.push("M1", "M2", "M3", "A")
                    }
                    else if (props.auth === "M2") {
                        authArray.push("M2", "M3", "A")
                    }
                    else if (props.auth === "M3") {
                        authArray.push("M3", "A")
                    }
                    else if (props.auth === "A") {
                        authArray.push("A")
                    }

                    let my_authCheck = false
                    authArray.forEach(element => {
                        if (loginState.Member_Auth === element) {
                            my_authCheck = true
                        }
                    });

                    if (my_authCheck === false) {
                        navigate("/admin", { replace: false })
                        alert("권한이 없습니다.")
                    }
                    setAuthCheck(my_authCheck)
                }

            }
        }
    }, [start, loginState.isLogin])


    if (authCheck !== true) {
        return (
            <div className="bg-white absolute top-0 bottom-0 left-0 right-0">
                권한 확인...
            </div>
        )
    }
}

export default AdminAuthCheck;


