import { Fragment, useEffect, useState } from "react";
import { Checkbox, Dialog, DialogHeader, DialogBody, DialogFooter, Input, Select, Option, IconButton } from "@material-tailwind/react";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { customAxios } from "../Common/customAxios";
import { findDataByID } from "../../Store/storeCategoryFunc";

function AdminCategoryList() {
  const [open, setOpen] = useState(false);
  const [dialogMode, setDialogMode] = useState("add"); //add, update
  const [start, setStart] = useState(false);

  const [id, setId] = useState("")
  const [pid,setPid] = useState("")
  const [title, setTitle] = useState("")
  const [visible, setVisible] = useState(false)
  const [count, setCount] = useState(0)

  const [storeCategoryData, setStoreCategoryData] = useState(null);
  const [storeCategoryRefresh, setStoreCategoryRefresh] = useState(true)
  const [idList, setIdList] = useState(null);

  

  useEffect(() => {
    setStart(true);
  }, []);

  useEffect(() => {
    if (storeCategoryData) {
      const flatList = [];
      storeCategoryData.forEach((data) => {
        recursiveFlatList(data, flatList);
      });
      setIdList(flatList);
    }
  }, [storeCategoryData]);

  useEffect(() => {

    const setItems = (dataList) =>{
      const initialOpenItems = {};
      dataList.forEach((item) => {
        initialOpenItems[item.id] = true;
        if (item.dataList) {
          item.dataList.forEach((childItem) => {
            initialOpenItems[childItem.id] = true;
          });
        }
      });
      setOpenItems(initialOpenItems)
    }

    const getList = async() =>{
      const result = await customAxios.get("/api/getStoreCategoryList")
      if(result.data.state ===101){
        setItems(result.data.response)
        setStoreCategoryData(result.data.response);
      }
      else if(result.data.state ===-100){
        setStoreCategoryData([])
      }
    }
    
    if (start) {
      if(storeCategoryRefresh === true){
        getList()
        setStoreCategoryRefresh(false)
      }
    }
  }, [start,storeCategoryRefresh]);

  const recursiveFlatList = (data, flatList, parentTitle = '') =>{
    flatList.push(
      {
        id: data.id,
        title: data.title,
        visible: data.visible,
        count: data.count,
        parentTitle: parentTitle,
      }
    )
    
    if (data.dataList) {
      data.dataList.forEach((mData) => {
        recursiveFlatList(mData, flatList, data.title)
      })
    }
  }
  

  // const findDataByID = (dataList, targetId, parent = null) => {
  //   let data = null;
  //   let parentData = parent;

  //   for (const item of dataList) {
  //     if (item.id === targetId) {
  //       data = item;
  //       break;
  //     } else if (item.dataList) {
  //       const result = findDataByID(item.dataList, targetId, item);
  //       if (result.data) {
  //         data = result.data;
  //         parentData = result.parentData;
  //         break;
  //       }
  //     }
  //   }

  //   return { data, parentData };
  // };

  const clickOpenBtn = (e, element, mode) => {
    if (element === undefined) {
      setId("");
      setTitle("");
      setCount(0);
      setVisible(false);
      setPid("0");
    } else {
      setId(element.id);
      setTitle(element.title);
      setCount(element.count);
      setVisible(element.visible);

      const dataList = storeCategoryData;

      const findData = findDataByID(dataList, element.id);
      if (findData.parentData === null) setPid("0");
      else setPid(findData.parentData.id);
    }
    setDialogMode(mode);
    setOpen(true);
  };

  const clickCloseBtn = (e) => {
    setOpen(false);
  };

  // 특정 위치에 데이터를 추가하는 함수
  const addData = (dataList, parentId, newData) => {
    if (parentId === "0") {
      dataList.push(newData);
    } else {
      for (const item of dataList) {
        if (item.id === parentId) {
          if (!item.dataList) {
            item.dataList = [];
          }
          item.dataList.push(newData);
          break;
        } else if (item.dataList) {
          addData(item.dataList, parentId, newData);
        }
      }
    }
  };

  const removeDataById = (dataList, targetId) => {
    for (let i = 0; i < dataList.length; i++) {
      if (dataList[i].id === targetId) {
        dataList.splice(i, 1); // 해당 인덱스의 데이터를 삭제합니다.
        return true; // 데이터를 삭제하고 함수를 종료합니다.
      } else if (dataList[i].dataList) {
        // 하위 데이터리스트가 있는 경우 재귀적으로 탐색합니다.
        if (removeDataById(dataList[i].dataList, targetId)) {
          return true; // 하위 데이터리스트에서 데이터를 삭제한 경우 함수를 종료합니다.
        }
      }
    }
    return false; // 데이터를 찾지 못한 경우 false를 반환합니다.
  };

  const editData = (dataList, sourceId, destinationId, newData) => {
    removeDataById(dataList, sourceId);
    addData(dataList, destinationId, newData);
  };

  const clickDeleteBtn = async (element) => {
    if (window.confirm("삭제하시겠습니까?\n(연관된 하위 분류가 모두 삭제됩니다)") === false) {
      return;
    }

    const dataList = [...storeCategoryData];
    removeDataById(dataList, element.id);

    const result = await customAxios.post("/api/setStoreCategoryList",{dataList:dataList})
    if(result.data.state===100){
      setStoreCategoryRefresh(true)
    }
  };

  const clickUpdateBtn = async(e) => {

    const dataList = [...storeCategoryData];

    const newData = {
      id: id,
      title: title,
      visible: visible,
      count: 0,
    };

    if (dialogMode === "add") {
      addData(dataList, pid, newData);
    } else if (dialogMode === "update") {
      const findData = findDataByID(dataList, id);
      newData.dataList  =findData.data.dataList
      editData(dataList, id, pid, newData);
    }

    const result = await customAxios.post("/api/setStoreCategoryList",{dataList:dataList})
    if(result.data.state===100){
      setStoreCategoryRefresh(true)
    }
    
    setOpen(false);
  };

  

  const isDialog = () => {
    return (
      <Dialog open={open} handler={setOpen}>
        <DialogHeader>카테고리</DialogHeader>
        <DialogBody>
          <div className="flex flex-col">
            <div className="">상위 목록</div>
            <select onChange={(e) => setPid(e.target.value)} value={pid}>
              <option value={"0"}>최상위</option>
              {idList &&
                idList.map((element) => {
                  return <option key={element.id} value={element.id}>{element.title}</option>;
                })}
            </select>
            <div className="">분류 번호</div>
            <Input value={id} className="!border-t-blue-gray-200 focus:!border-t-gray-900" labelProps={{ className: "before:content-none after:content-none" }} onChange={(e) => setId(e.target.value)} />
            <div className="">분류 이름</div>
            <Input value={title} className="!border-t-blue-gray-200 focus:!border-t-gray-900" labelProps={{ className: "before:content-none after:content-none" }} onChange={(e) => setTitle(e.target.value)} />
            <div className="">보이기</div>
            <div className="flex justify-start">
              <Checkbox color="red" checked={visible} onChange={(e) => setVisible(e.target.checked)} />
            </div>
            <div className="">상품수</div>
            <div className="flex justify-start p-2">{count}</div>
          </div>
        </DialogBody>
        <DialogFooter>
          <div className="flex justify-center gap-1">
            <button className="px-3 py-2 rounded-md text-sm font-bold duration-300 bg-Cgcolor text-white" onClick={clickUpdateBtn}>
              등록
            </button>
            <button className="px-3 py-2 rounded-md text-sm font-bold duration-300 bg-Cgcolor text-white" onClick={clickCloseBtn}>
              취소
            </button>
          </div>
        </DialogFooter>
      </Dialog>
    );
  };

  const [openItems, setOpenItems] = useState({});

  const toggleOpen = (id) => {
    setOpenItems((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const renderData = (dataList, depth = 0) => {
    return dataList.map((item) => (
      <Fragment key={item.id}>
        <tr>
          <td style={{ paddingLeft: depth * 20 }}>
            <IconButton variant="text" onClick={() => toggleOpen(item.id)}>
              {openItems[item.id] ? <ChevronDownIcon className="h-4 w-4" /> : <ChevronRightIcon className="h-4 w-4"/>}
            </IconButton>
            {item.title}
          </td>
          <td>{item.count}</td>
          <td>
            <div className="flex justify-center">
              <Checkbox color="red" checked={item.visible} disabled />
            </div>
          </td>
          <td>
            <div className="flex gap-1 justify-center">
              <button
                className="px-1 py-1 rounded-md bg-Cgcolor text-white"
                onClick={(e) => clickOpenBtn(e, item, "update")}
              >
                수정
              </button>
              <button
                className="px-1 py-1 rounded-md bg-Cgcolor text-white"
                onClick={(e) => clickDeleteBtn(item)}
              >
                삭제
              </button>
            </div>
          </td>
        </tr>
        {openItems[item.id] && item.dataList && renderData(item.dataList, depth + 1)}
      </Fragment>
    ));
  };
  const thClass = "border border-black border-blue-gray-100 bg-blue-gray-50 p-1";

  if (!storeCategoryData) return <div>로딩중</div>;

  return (
    <div className=" p-1 overflow-x-auto w-full">
      <table className="table-fixed whitespace-nowrap">
        <thead className="">
          <tr>
            <th className={thClass + " w-6/12"}>분류 이름</th>
            <th className={thClass + " w-1/12"}>상품수</th>
            <th className={thClass + " w-1/12"}>보이기</th>
            <th className={thClass + " w-1/12"}>수정</th>
          </tr>
        </thead>
        <tbody>{renderData(storeCategoryData)}</tbody>
        <tfoot>
          <tr>
            <td colSpan={4}></td>
            <td>
              <div className="flex justify-center">
                <button className="px-3 py-2 rounded-md text-sm font-bold duration-300 bg-Cgcolor text-white" onClick={(e) => clickOpenBtn(e, undefined, "add")}>
                  추가
                </button>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
      {isDialog()}
    </div>
  );
}

export default AdminCategoryList;
