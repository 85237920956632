import dayjs from "dayjs"

const requestPay = (data, callback) => {

    /* 1. 가맹점 식별하기 */
    const { IMP } = window
    IMP.init(process.env.REACT_APP_IMP)

    let pg =""
    if(data.pay_method === "card"){
        pg=process.env.REACT_APP_TOSSPAYMENTS_PG1
    }
    else  if(data.pay_method === "kakaopay"){
        pg=process.env.REACT_APP_TOSSPAYMENTS_PG2
    }
    else  if(data.pay_method === "naverpay"){

    }
        // pg: 'tosspayments',                           // PG사

    const initialData = {
        pg: pg,                           // PG사
        pay_method: 'card',                           // 결제수단
        merchant_uid: `mid_${new Date().getTime()}`,   // 주문번호
        amount: 100,                                 // 결제금액
        name: '아임포트 결제 데이터 분석',                  // 주문명
        buyer_name: '홍길동',                           // 구매자 이름
        buyer_tel: '01012341234',                     // 구매자 전화번호
        buyer_email: 'example@example',               // 구매자 이메일
        buyer_addr: '신사동 661-16',                    // 구매자 주소
        buyer_postcode: '06018',                      // 구매자 우편번호
        m_redirect_url: "https://helloworld.com/payments/result", // 모바일 환경에서 필수 입력
        // notice_url: "https://d6d5-222-235-213-205.ngrok-free.app/api/insertPaymentWebhook",
        // notice_url: "https://d6d5-222-235-213-205.ngrok-free.app/api/insertPaymentWebhook",
        // notice_url: "https://helloworld.com/api/v1/payments/notice",
        // confirm_url: "https://helloworld.com/api/v1/payments/confirm",
        // confirm_url: "https://helloworld.com/api/v1/payments/confirm",
        currency: "KRW",
        locale: "ko",
        niceMobileV2: true // 신규 모바일 버전 적용 시 설정
    }
    const day = dayjs(new Date())
    let mData = {
        ...initialData,
        ...data,
    }
    mData.merchant_uid = data.merchant_uid + day.format("_YYYYMMDD_HHmmssSSS")

    // console.log(mData)

    IMP.request_pay(mData, callback)
}

const certification = (data,callback) => {

    const day = dayjs(new Date())

    /* 1. 가맹점 식별하기 */
    const { IMP } = window
    IMP.init(process.env.REACT_APP_IMP)

    const initialData = {
        pg: process.env.REACT_APP_INICIS_PG1,                           // PG사
        // pg: 'inicis_unified.MIIchanggg',//본인인증 설정이 2개이상 되어 있는 경우 필수 
        merchant_uid: "UIC" + day.format("_YYYYMMDD_HHmmssSSS"), // 주문 번호
        // m_redirect_url: "http://localhost:5000/api/wook", // 모바일환경에서 popup:false(기본값) 인 경우 필수, 예: https://www.myservice.com/payments/complete/mobile
    }
    let mData = {
        ...initialData,
        ...data
    }


    IMP.certification(mData, callback)
}



const payMethodArr = [
    {
        label: "신용/체크 카드",
        value: "card"
    },
    {
        label: "카카오 페이",
        value: "kakaopay"
    },
    // {
    //     label: "네이버 페이",
    //     value: "naverpay"
    // },
]

export {
    requestPay,
    certification,
    payMethodArr
}