import { Card, CardHeader, CardBody, CardFooter, Typography, Chip, Button, } from "@material-tailwind/react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import logo from "../../Images/Layout/logo.png"
import { customAxios } from "../Common/customAxios";

export function AdminEventCard(props) {

  const navigate = useNavigate()
  const onClickCard = async (e) => {
    navigate("/EventDetail?page=" + props.cardData.Event_UID)
  }
  const onClickEdit = async (e) => {
    navigate("/AdminEventWrite?page=" + props.cardData.Event_UID)
  }
  const onClickRemove = async (e) => {
    if (window.confirm("이 게시물을 삭제하시겠습니까?")) {

      // console.log(props.cardData.Event_UID)
      const result = await customAxios.get("/api/removeEvent?Event_UID=" + props.cardData.Event_UID)
      if (result.data) {
        if (result.data.state === 100) {
          alert("게시물이 삭제되었습니다.")
          props.setEventListRefresh(true)
        }
        else {
          alert("게시물이 삭제에 실패하였습니다.\n다시 시도해주세요")
        }
      }
    }
  }

  const Rday = dayjs(props.cardData.Event_RegistrationDate)

  let cardImage
  const Event_ThumbnailURL = JSON.parse(props.cardData.Event_ThumbnailURL)

  if (Event_ThumbnailURL.length === 0) {
    cardImage = <img className="w-full object-contain h-64" src={"logo_new.png"} alt="card-image" />
  }
  else {
    cardImage = <img className="w-full object-contain h-64" src={Event_ThumbnailURL[0]} alt="card-image" />
  }

  let abstract = props.cardData.Event_Abstract 
  
  if(abstract.length === 0)
    abstract = props.cardData.Event_Context

  return (
    <Card>
      <CardBody className="w-full hover:cursor-pointer" onClick={onClickCard} key={props.cardData.Event_UID}>
        <div className="flex flex-row w-full gap-2 ">
          <div className="flex w-2/5"> {cardImage}</div>
          <div className="flex flex-none flex-col w-3/5 p-1 gap-1 ">
            <p className="whitespace-pre-wrap overflow-hidden">
              {props.cardData.Event_Title}
            </p>
            <div className="flex grow items-center">
              <p className="abstract">
                {abstract}
              </p>
            </div>
            <div className="flex flex-col grow justify-end">
              <p className="text-right whitespace-pre-wrap overflow-hidden">
                {Rday.format("YYYY-MM-DD")}
              </p>
            </div>
          </div>
        </div>
      </CardBody>
      <CardFooter className="flex justify-end gap-1">
        <button className="rounded bg-Cgcolor text-white" onClick={onClickEdit}>수정</button>
        <button className="rounded bg-Cgcolor text-white" onClick={onClickRemove}>삭제</button>
      </CardFooter>
    </Card>
  );
}