import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom"
import { useDispatch } from "react-redux";
import { setLogin } from "../../Store/loginSlice";
import { customAxios } from "../../Components/Common/customAxios";

const LoginNaver = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [start, setStart] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const code = searchParams.get("code")
    const error = searchParams.get("error")

    useEffect(() => {
        setStart(true)
    }, [])

    useEffect(() => {
        const AwaitFunc = async () => {

            if (error === "access_denied") {
                navigate("/Login", { replace: false })
            }

            if (code !== null) {
                const res = await customAxios.post("/api/NaverLogin", { code: code })
                searchParams.delete("code")
                setSearchParams(searchParams, { replace: true })

                if (res.data.state === 101) {
                    customAxios.defaults.headers.common['Authorization'] = `Bearer ${res.data.accessToken}`
                    const getMember = await customAxios.get('/api/getMember')
                    const state = {}
                    if (getMember.data.state === 101) {
                        state.isLogin = true
                        state.Member_UID = getMember.data.Member.Member_UID
                        state.Member_Email = getMember.data.Member.Member_Email
                        state.Member_Nickname = getMember.data.Member.Member_Nickname
                        state.Member_Auth = getMember.data.Member.Member_Auth
                        state.Member_TokenType = getMember.data.Member.Member_TokenType
                        dispatch(setLogin(state))
                    }
                    else {
                        console.log("getMember 실패")
                    }
                    const nextPage = localStorage.getItem("nextPage")
                    localStorage.removeItem("nextPage")

                    if (nextPage) {
                        navigate(nextPage, { replace: true })
                    }
                    else {
                        navigate("/Home", { replace: true })
                    }
                }
                else {
                    if (res.data.isDelete === true) {
                        if (window.confirm("탈퇴한 회원입니다.\n복구하시겠습니까?")) {
                            const result = await customAxios.post("/api/restorationNaver")
                            if (result.data.state === 100) {
                                alert("복구되었습니다.\n다시 로그인해주세요.")
                                navigate("/Login", { replace: false })
                            }
                        }
                        else {
                            navigate("/Login", { replace: false })
                        }
                    }
                    else {
                        console.log("네이버 인증 에러")
                    }
                }
            }
        }


        if (start === true) {
            AwaitFunc()
        }
    }, [start, code, error])

}

export default LoginNaver