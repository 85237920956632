import Header from '../../Components/Fixed/Header';
import Footer from '../../Components/Fixed/Footer';

import { useSelector } from 'react-redux';
import {  useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { customAxios } from '../../Components/Common/customAxios';


function PaymentSuccess() {

    const [start, setStart] = useState(false)
    const loginState = useSelector((state) => state.login)
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()
    const imp_uid = searchParams.get("imp_uid")
    const merchant_uid = searchParams.get("merchant_uid")
    
    useEffect(() => {
        setStart(true)
    }, [])

    useEffect(() => {
        setStart(true)


    }, [start,imp_uid,merchant_uid])

    const onClickTest = async() =>{
        const result = await customAxios.post("/api/insertPayment/",{
            imp_uid:imp_uid,
            merchant_uid:merchant_uid})

            console.log(result.data)

    }

    return (
        <div className='break-keep'>
            <Header />
            <div>
                <section className='sm:flex mx-auto max-w-7xl px-5 py-32'>
                    <article>
                        <div className=''>
                            <h2 className='text-xl sm:leading-normal font-bold leading-normal w-full my-3'>
                                <span className='block'>[결제 정보]</span>
                            </h2>
                           
                            <div className='w-full mt-10'>

                            </div>
                            <div className='mt-10'>
                                <button
                                    onClick={onClickTest}
                                    className='w-36 bg-Cgcolor text-white text-sm font-bold rounded-lg py-3 px-4 inline-block '>
                                    본인 인증
                                </button>
                            </div>
                        </div>
                    </article>
                </section>
            </div>
            <Footer />
        </div>

    );
}

export default PaymentSuccess;

