import { useEffect, useState } from "react"
import { customAxios } from "../Common/customAxios"
import dayjs from "dayjs"
import { Typography, } from "@mui/material"
import { Dialog, DialogHeader, DialogBody, DialogFooter, Input} from "@material-tailwind/react";
import { useSearchParams } from "react-router-dom";
import { dateKor } from "../Func/TimeUtils";




export const AdminPracticeForm = (props) => {

    const [start, setStart] = useState(false)

    const [practiceRoomGroupList, setPracticeRoomGroupList] = useState()
    const [practiceRoomGroupData, setPracticeRoomGroupData] = useState()
    const [practiceRoomList, setPracticeRoomList] = useState()
    
    const [selectDay, setSelectDay] = useState()


    const [selectRoom, setSelectRoom] = useState()
    const [selectTime, setSelectTime] = useState()
    const [usingTime, setUsingTime] = useState(60)
    const [numberOfUser, setNumberOfUser] = useState(1)

    const [bookingData, setBookingData] = useState()
    
    const [context, setContext] = useState("")

    const[IP, setIP] = useState("")
    const[ChangGGoIP, setChangGGoIP] = useState() 

    const [refresh, setRefresh] = useState(true)

    const [searchParams, setSearchParams] = useSearchParams()
    const selectPracticeRoomGroup = (searchParams.get("PracticeRoomGroup"))

    const [open, setOpen] = useState(false);
    const [mode, setMode] = useState("")

    useEffect(() => {
        setStart(true)
    }, [])

    useEffect(() => {
        const getToday = async () => {
            const result = await customAxios.get("/api/getTime")
            if (result.data) {
                setSelectDay(result.data.response)
            }
        }

        const getPracticeRoomGroup = async () => {
            
            const mode = props.mode 
            // console.log(mode)
            const result = await customAxios.get("/api/getPracticeRoomGroup",{params:{mode:mode}})
            if (result.data) {

                const list = []
                
                result.data.response.forEach(element => {
                    list.push(element.PracticeRoomGroup_Name)
                });
                setPracticeRoomGroupList(list)
                
                if(selectPracticeRoomGroup ===null){
                    searchParams.set("PracticeRoomGroup", list[0])
                    setSearchParams(searchParams, { replace: true })
                }
            }
        }
        const getIP = async () =>{
            
            const result = await customAxios.get("/api/getIP")
            if (result.data) {
                setIP(result.data.ip)
            }
        }
        const getCGIP = async () =>{
            
            let Etc_Index = 1
            if(props.mode === "CGM"){
                Etc_Index = 2
            }
            const result = await customAxios.get("/api/getChangGgoIP",{params:{Etc_Index:Etc_Index}})
            if (result.data) {
                setChangGGoIP(result.data.ip)
            }
        }

        if (start) {
            getToday()
            getPracticeRoomGroup()
            getIP()
            getCGIP()
        }

    }, [start])

    useEffect(() => {

        const getPractice = async () => {
            const result = await customAxios.get("/api/getPractice", {
                params: {
                    selectDay: selectDay,
                    selectPracticeRoomGroup: selectPracticeRoomGroup,
                }
            })
            if (result.data) {
                // console.log(result.data.response.practiceRoomList)
                setPracticeRoomList(result.data.response.practiceRoomList)
                setPracticeRoomGroupData(result.data.response.practiceRoomGroupData)
                setRefresh(false)
            }
        }

        if (selectDay && selectPracticeRoomGroup && refresh) {
            getPractice()
        }

    }, [selectDay, selectPracticeRoomGroup,refresh])

    const leftbtnClicked = () => {
        setRefresh(true)
        setSelectDay(prev => dayjs(prev).subtract(1, 'day').format("YYYY/MM/DD HH:mm:ss"));
    }

    const rightbtnClicked = () => {
        setRefresh(true)
        setSelectDay(prev => dayjs(prev).add(1, 'day').format("YYYY/MM/DD HH:mm:ss"));
    }
    const displaySelectRoomGroup = () => {
        return (
            <div>
                <select value={selectPracticeRoomGroup} className="" onChange={(e) => { changedSelect(e.target.value) }}>
                    {practiceRoomGroupList.map(element => {

                        let displayValue = element
                        if(props.mode === "CG"){
                            displayValue = element.replace("floor","")
                            displayValue +="층"
                        }
                        else if(props.mode === "CGM"){
                            if(displayValue.includes("floor2_1")){
                                displayValue = "보컬"
                            }
                            else if(displayValue.includes("floor2_2")){
                                displayValue = "댄스A"
                            }
                            else if(displayValue.includes("floor2_3")){
                                displayValue = "댄스B"
                            }
                        }
                        return (
                            <option key={element} value={element}>{displayValue}</option>
                        )
                    })}
                </select>
            </div>
        )
    }
    const displayTable2 = () => {
        if (practiceRoomList && practiceRoomGroupData) {

            const TABLE_HEAD = ["시간"];
            const TABLE_TYPE = [true]
            practiceRoomList.forEach(practiceRoomData => {
                if(practiceRoomData.PracticeRoom_Capacity === 1){
                    TABLE_HEAD.push(practiceRoomData.PracticeRoom_Name)
                    TABLE_TYPE.push(!TABLE_TYPE[TABLE_TYPE.length-1])
                }
                else{
                        
                    const type = (!TABLE_TYPE[TABLE_TYPE.length-1])

                    for(let i=0; i<practiceRoomData.PracticeRoom_Capacity; i++){
                        const text = practiceRoomData.PracticeRoom_Name+"-"+(i+1)
                        TABLE_HEAD.push(text)
                        TABLE_TYPE.push(type)
                    }
                }
            });
            const TABLE_ROWS = []
            // console.log(practiceRoomList[0])
            // console.log(practiceRoomGroupData)

            practiceRoomGroupData.arrayTime.forEach((dateTime, row)=>{
                const TABLE_ROW = []

                //-------------------------------------------
                const currentDayjs = dayjs(selectDay) //현재 시간
                const rowStartDayjs = dayjs(dateTime) // 해당 row 시간
                const rowEndDayjs = dayjs(dateTime).add(practiceRoomGroupData.PracticeRoomGroup_Standard,"m") // 해당 row 시간
                
                //-------------------------------------------
                const timeTD=(
                    <td  
                        className="border-b border-blue-gray-200 text-center w-9 bg-blue-gray-50"
                        key={row + "td" + 0}> 
                        <div className="flex flex-col">
                            <div>{rowStartDayjs.format("hh:mm")}</div>
                            <div>~</div>
                            <div>{rowEndDayjs.format("hh:mm")}</div>
                        </div>
                    </td>
                 )
                TABLE_ROW.push(timeTD)
                //-------------------------------------------

                let count =1
                //-------------------------------------------
                practiceRoomList.forEach((practiceRoomData) =>{
                    let limit = practiceRoomData.PracticeRoom_Capacity
                    let span = 1
                    for(let i=0; i<limit; i++){
                        let tdData
                        //예약 가능
                        if (practiceRoomData.arrayStates[row] === "wait") {
                            span = 1
                            tdData = <button className="rounded bg-Cgcolor text-white"
                                onClick={(e) => {
                                    clickedBooking({
                                        rowStartDayjs: rowStartDayjs,
                                        rowEndDayjs: rowEndDayjs,
                                        row: row,
                                        clickPracticeRoomData: practiceRoomData,
                                    })
                                }}>
                                예약</button>
                        }
                        //이미 예약됨 ('wait'가 아닌 데이터가 있는 경우)
                        else {
                            if(practiceRoomData.arrayStates[row][i]){
                                const idx = practiceRoomData.arrayStates[row][i].BookingPR_Index
                                const display = practiceRoomData.arrayStates[row][i].BookingPR_Display
                                const name = practiceRoomData.arrayStates[row][i].BookingPR_Name

                                tdData = (
                                    <div className="flex flex-col">
                                        <div>{display}</div>
                                        <div>{name}</div>
                                        <div>{<button className="rounded bg-Cgcolor text-white" 
                                        onClick={(e)=>{clickedEditBooking(
                                            {
                                                BookingPR:practiceRoomData.arrayStates[row][i], 
                                                BookingPR_Index:idx, 
                                                display : display,
                                                rowStartDayjs: rowStartDayjs,
                                                rowEndDayjs: rowEndDayjs,
                                                row: row,
                                                clickPracticeRoomData: practiceRoomData,
                                            })}}>
                                                변경</button>}</div>
                                    </div>
                                )
                                span = practiceRoomData.arrayStates[row][i].BookingPR_NumberOfUser
                                limit = limit - (span-1) 
                                // i= i+practiceRoomData.arrayStates[row][i].BookingPR_NumberOfUser-1

                            }
                            else{
                                span = 1
                                tdData = <button className="rounded bg-Cgcolor text-white"
                                onClick={(e) => {
                                    clickedBooking({
                                        rowStartDayjs: rowStartDayjs,
                                        rowEndDayjs: rowEndDayjs,
                                        row: row,
                                        clickPracticeRoomData: practiceRoomData,
                                    })
                                }}>
                                예약</button>
                            }
                        }
                        let tdClass = ""
                        if (TABLE_TYPE[count]) {
                            tdClass = "border-b border-blue-gray-200 text-center bg-blue-gray-50"
                        }
                        else {
                            tdClass = "border-b border-blue-gray-200 text-center"
                        }
                        
                        const tdKey = row + "td" + (count)
                        // console.log(practiceRoomData.arrayStates[row][i])
                        const tds = (
                            <td 
                                colSpan={span}
                                className={tdClass}
                                 key={tdKey}> 
                                {tdData}
                            </td>
                        )
                        TABLE_ROW.push(tds)
                        count++
                    }
                    //-------------------------------------------
                })

                TABLE_ROWS.push(TABLE_ROW)
            }
        )

            // console.log(TABLE_ROWS)

            return (
                <table className="w-full min-w-max table-auto mt-2">
                    <thead>
                        <tr>
                            {
                            TABLE_HEAD.map((head, col) => {
                                let classes = ""

                                //맨 왼쪽줄
                                if (col === 0) {
                                    classes = "sticky left-0 "
                                }
                                //그 외
                                else {
                                    classes = "p-1"
                                }

                                if (TABLE_TYPE[col]) {
                                    classes += " bg-blue-gray-50"
                                }
                                return (
                                    <th key={head} className={"whitespace-pre border-b border-blue-gray-200 text-center "+classes}>
                                        {head.replace(/\\n/g, '\n')}
                                    </th>)
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            TABLE_ROWS.map((rowData,row)=>{
                                return(
                                    <tr key={"tr" + row}>
                                        {rowData}
                                    </tr>
                                )

                            })
                        }
                    </tbody>
                </table>
            )
        }
    }
    const changedSelect = (val) => {
        setRefresh(true)
        searchParams.set("PracticeRoomGroup", val)
        setSearchParams(searchParams, { replace: true })
    }

    const clickedBooking = (param) =>{

        setSelectRoom(param.clickPracticeRoomData)
        setSelectTime(param.rowStartDayjs.format("YYYY-MM-DD HH:mm:ss"))
        
        // console.log(selectPractice[param.col-1])

        setUsingTime(param.clickPracticeRoomData.PracticeRoomGroup_Standard)
        setNumberOfUser(1)
        setContext("")
        setMode("add")
        setOpen(true)
    }

    const clickedEditBooking = (param) =>{

        // console.log(param)

        setSelectRoom(param.clickPracticeRoomData)
        setSelectTime(param.rowStartDayjs.format("YYYY-MM-DD HH:mm:ss"))

        
        setBookingData(param.BookingPR)

        const curMin = dayjs(param.BookingPR.BookingPR_BookingEndDate).diff(dayjs(param.BookingPR.BookingPR_BookingDate),'minute')
        setUsingTime(curMin)

        setNumberOfUser(param.BookingPR.BookingPR_NumberOfUser)
        setContext(param.display)
        setMode("edit")
        setOpen(true)
    }

    const clickedOkDlg = async() =>{

        if(context ===""){

            alert("출력 내용이 없습니다.\n출력내용을 입력해주세요")
            return
        }

        if(mode ==="add"){
            if(window.confirm("등록 하시겠습니까?")){
                const result = await customAxios.post("/api/setPractice",{
                    PracticeRoom_Index : selectRoom.PracticeRoom_Index,
                    usingTime : usingTime,
                    selectTime : selectTime,
                    context : context,
                    mode: mode,
                    numberOfUser:numberOfUser,
                })
                if(result.data){
                    if(result.data.state === 100){
                        setRefresh(true)
                    }
                    else{
                        if(result.data.state === -100){
                            if(result.data.message ==="alreadyBookedTime"){
                                alert("해당 연습실은 이미 예약되었습니다.\n정원초과 입니다.")
                            }
                            setRefresh(true)
                        }
                        else{
                            alert("등록실패")
                        }
                    }
                }
        setOpen(false)
            }
        }
        else if(mode === "edit"){
            if(window.confirm("변경 하시겠습니까?")){
                const result = await customAxios.post("/api/setPractice",{
                    PracticeRoom_Index : selectRoom.PracticeRoom_Index,
                    usingTime : usingTime,
                    selectTime : selectTime,
                    context : context,
                    BookingPR_Index : bookingData.BookingPR_Index,
                    mode: mode,
                    numberOfUser:numberOfUser,
    
                })
                if(result.data){
                    if(result.data.state === 100){
                        setRefresh(true)
                    }
                    else{
                        console.log(result.data.message)
                        if(result.data.message ==="alreadyBookedTime"){
                            alert("해당 연습실은 이미 예약되었습니다.\n정원초과 입니다.")
                        }
                        else{
                            alert("등록실패")
                        }
                    }
                }
        setOpen(false)
            }
        }
    }
    
    const clickedCloseDlg = () =>{
        setOpen(false)
    }
    const clickedDeleteDlg = async() =>{

        if (window.confirm("일정을 삭제하시겠습니까?")) {

            const result = await customAxios.post("/api/removePractice", {
                BookingPR_Index: bookingData.BookingPR_Index,
            })
            if (result.data) {
                if (result.data.state === 100) {
                    setRefresh(true)
                }
            }


        }
        else {

        }

        setOpen(false)

    }

    const clickedUpdateIP = async() =>{

        let Etc_Index = 1
        if(props.mode === "CGM"){
            Etc_Index = 2
        }

        const getCGIP = async () =>{

            const result = await customAxios.get("/api/getChangGgoIP",{params:{Etc_Index:Etc_Index}})
            if (result.data) {
                setChangGGoIP(result.data.ip)
            }
        }

        if(window.confirm("해당 IP로 변경하시겠습니까?")){
            const result = await customAxios.post("/api/setChangGgoIP",{IP:IP,Etc_Index:Etc_Index})
            if(result.data){
                if(result.data.state ===100){
                    alert("변경이 완료되었습니다.")
                    getCGIP()
                }
            }
        }
    }

    
    const isDialog = () => {

        const selects = []
        const numberOfUserSelects = []

        if(selectRoom){

            const found = selectRoom.arrayTime.findIndex((Time)=> dayjs(Time).isSame(selectTime))

            for(let i=1; i<=selectRoom.arrayTime.length-found; i++){
                selects.push(selectRoom.PracticeRoomGroup_Standard*i)
            }
            if(selects[selects.length-1]<usingTime)
                setUsingTime(selects[selects.length-1])

            for(let i=1; i<=selectRoom.PracticeRoom_Capacity; i++){
                numberOfUserSelects.push(i)
            }
        }
        

            return (
                <Dialog open={open} handler={setOpen}>
                  <DialogHeader>연습실 예약</DialogHeader>
                  <DialogBody>
                    <div className="flex flex-col gap-2">
                    <div className="flex flex-row gap-2">
                            <div className="text-black">분류</div>
                            <div className="font-bold">{selectPracticeRoomGroup ? selectPracticeRoomGroup: null}</div>
                        </div>

                        <div className="flex flex-row gap-2">
                            <div className="text-black">연습실 이름</div>
                            <div className="font-bold">{selectRoom ? selectRoom.PracticeRoom_Name.replace(/\\n/g, '\n'): null}</div>
                        </div>
                        <div className="flex flex-row gap-2">
                            <div className="text-black">예약 시간</div>
                            <div className="">{selectTime ? selectTime: null}</div>
                        </div>
                        <div className="flex flex-row gap-2">
                            <div className="text-black">사용 시간</div>
                                <div className="">
                                    <select value={usingTime} onChange={(e)=>{setUsingTime(e.target.value)}}>
                                        {selects.map((element,idx)=>{
                                            let text = ""
                                            if(selectRoom.PracticeRoomGroup_Standard===30){
                                                text = element +"분" +"["+(idx+1)+"]"
                                            }
                                            else if(selectRoom.PracticeRoomGroup_Standard ===60){
                                                text = (element/60) +"시간"
                                            }
                                            return(
                                            <option key={element} value={element}>{text}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                        </div>
                        {numberOfUserSelects.length === 1 ? 
                        null : 
                        <div className="flex flex-row gap-2">
                            <div className="text-black">사용 인원</div>
                                <div className="">
                                    <select value={numberOfUser} onChange={(e)=>{setNumberOfUser(e.target.value)}}>
                                        {numberOfUserSelects.map(element=>{
                                            return(
                                            <option key={element} value={element}>{element}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                        </div>
                        }
                        
                        <div className="flex flex-row gap-2">
                            <div className=" w-10 text-black">출력</div>
                            <Input value={context} placeholder="수업" className="!border-t-blue-gray-200 focus:!border-t-gray-900" labelProps={{ className: "before:content-none after:content-none" }} onChange={(e) => setContext(e.target.value)} />
                        </div>
                    </div>
                  </DialogBody>
                  <DialogFooter>
                    <div className="flex justify-center gap-1">
                            <button className="px-3 py-2 rounded-md text-sm font-bold duration-300 bg-Cgcolor text-white" onClick={clickedOkDlg}>
                                {mode === "add" ? "등록" : "변경"}
                            </button>
                            {mode === "add" ?
                                null :
                                <button className="px-3 py-2 rounded-md text-sm font-bold duration-300 bg-Cgcolor text-white" onClick={clickedDeleteDlg}>삭제</button>
                            }
                      <button className="px-3 py-2 rounded-md text-sm font-bold duration-300 bg-Cgcolor text-white" onClick={clickedCloseDlg}>
                        취소
                      </button>
                    </div>
                  </DialogFooter>
                </Dialog>
              );
        }

    if (selectDay && practiceRoomGroupList) {

        const DOW = dateKor(dayjs(selectDay).day())

        return (
            <div className="flex flex-col">
                <div className="flex flex-row justify-center gap-3 mt-3">
                    <div>
                        <button className="rounded-lg text-white bg-red-500" onClick={leftbtnClicked}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                            </svg>
                        </button>
                    </div>
                    <div className="flex">
                        <Typography variant="h6" >
                            {dayjs(selectDay).format("YYYY-MM-DD")}
                        </Typography>
                        <div className="ml-1">
                            <Typography variant="h6" >
                                ({DOW})
                            </Typography>
                        </div>
                    </div>
                    <div>
                        <button className="rounded-lg text-white bg-red-500" onClick={rightbtnClicked}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                            </svg>
                        </button>
                    </div>
                </div>
                {displaySelectRoomGroup()}
                {displayTable2()}

                {isDialog()}
                    
                    <div className="flex flex-row justify-center mt-5">
                        <div className="flex flex-col">
                            <div className="">등록 IP : {ChangGGoIP}</div>
                            <div className="flex items-center gap-2">
                            <div className="w-16">변경 IP : </div>
                            <Input value={IP} className="!border-t-blue-gray-200 focus:!border-t-gray-900" labelProps={{ className: "before:content-none after:content-none" }} onChange={(e) => setIP(e.target.value)} />
                                <button className=" bg-Cgcolor text-white rounded w-10" onClick={clickedUpdateIP}>변경</button>
                            </div>
                        </div>
                    </div>

            </div>
        )
    }



}