import { useEffect, useState } from "react"
import { customAxios } from "../Common/customAxios"
import dayjs from "dayjs"
import { Typography, } from "@mui/material"
import { useSelector } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"
import { dateKor } from "../Func/TimeUtils"
import { Link } from "react-router-dom"
import { pageCollection } from "../Page/PageCollection"

export const PracticeTable = () => {

    const [start, setStart] = useState(false)

    const [practiceRoomGroupList, setPracticeRoomGroupList] = useState()
    const [practiceRoomGroupData, setPracticeRoomGroupData] = useState()
    const [practiceRoomList, setPracticeRoomList] = useState()

    const [selectDay, setSelectDay] = useState()

    const [selectRoom, setSelectRoom] = useState()
    const [selectTime, setSelectTime] = useState()

    const [IP, setIP] = useState()
    const [ChangGGoIP, setChangGGoIP] = useState()

    const [refresh, setRefresh] = useState(true)

    const [open, setOpen] = useState(false);

    const loginState = useSelector((state) => state.login)

    const [searchParams, setSearchParams] = useSearchParams()
    const selectPracticeRoomGroup = (searchParams.get("PracticeRoomGroup"))

    const navigate = useNavigate()
    const mode = (searchParams.get("mode"))


    useEffect(() => {
        setStart(true)
    }, [])

    useEffect(() => {

        const getMode = async () => {
            if (mode === null) {
                searchParams.set("mode", "CG")
                setSearchParams(searchParams, { replace: true })
            }
        }
        const getToday = async () => {
            const result = await customAxios.get("/api/getTime")
            if (result.data) {
                setSelectDay(result.data.response)
            }
        }

        const getPracticeRoomGroup = async () => {
            const result = await customAxios.get("/api/getPracticeRoomGroup", { params: { mode: mode } })
            if (result.data) {

                const list = []

                result.data.response.forEach(element => {
                    list.push(element.PracticeRoomGroup_Name)
                });
                setPracticeRoomGroupList(list)

                //null이 들어온 경우
                if (selectPracticeRoomGroup === null || selectPracticeRoomGroup === 'null') {
                    searchParams.set("PracticeRoomGroup", list[0])
                    setSearchParams(searchParams, { replace: true })
                }
                //List에 없는 값이 들어온 경우
                else if(!list.includes(selectPracticeRoomGroup)){
                    searchParams.set("PracticeRoomGroup", list[0])
                    setSearchParams(searchParams, { replace: true })
                }
            }
        }
        const getIP = async () => {
            const result = await customAxios.get("/api/getIP")
            if (result.data) {
                setIP(result.data.ip)
            }
        }
        const getCGIP = async () => {

            let Etc_Index = 1
            if (mode === "CGM") {
                Etc_Index = 2
            }

            const result = await customAxios.get("/api/getChangGgoIP", { params: { Etc_Index: Etc_Index } })
            if (result.data) {
                setChangGGoIP(result.data.ip)
            }

        }


        if (start) {
            getMode()
            getToday()
            if (mode) {
                getPracticeRoomGroup()
                getIP()
                getCGIP()
            }
        }

    }, [start, mode])

    useEffect(() => {

        const getPractice = async () => {
            const result = await customAxios.get("/api/getPractice", {
                params: {
                    selectDay: selectDay,
                    selectPracticeRoomGroup: selectPracticeRoomGroup,
                }
            })
            // console.log(result.data)

            if (result.data) {
                // console.log(result.data.response.practiceRoomList)
                setPracticeRoomList(result.data.response.practiceRoomList)
                setPracticeRoomGroupData(result.data.response.practiceRoomGroupData)
                setRefresh(false)

            }
        }

        if (selectDay && selectPracticeRoomGroup && refresh) {
            getPractice()
        }

    }, [selectDay, selectPracticeRoomGroup, refresh])

    const onClickMode = (mode) => {

        if (mode === "CG") {
            setRefresh(true)
            searchParams.set("mode", "CG")
            searchParams.set("PracticeRoomGroup", "floor1")
            setSearchParams(searchParams, { replace: true })

        }
        else if (mode === "CGM") {
            setRefresh(true)
            searchParams.set("mode", "CGM")
            searchParams.set("PracticeRoomGroup", "m_floor2_1")
            setSearchParams(searchParams, { replace: true })
        }
        

    }

    const displaySelectRoomGroup = () => {

        return (
            <div>
                <select value={selectPracticeRoomGroup} className="" onChange={(e) => { changedSelect(e.target.value) }}>
                    {practiceRoomGroupList.map(element => {

                        let displayValue = element
                            if (displayValue === "floor1") {
                                displayValue = "1층"
                            }
                            else if (displayValue === "floor2") {
                                displayValue = "2층"
                            }
                            else if (displayValue === "floor3") {
                                displayValue = "3층"
                            }
                            else if (displayValue === "m_floor2_1") {
                                displayValue = "보컬"
                            }
                            else if (displayValue === "m_floor2_2") {
                                displayValue = "댄스A"
                            }
                            else if (displayValue === "m_floor2_3") {
                                displayValue = "댄스B"
                            }

                        return (
                            <option key={element} value={element}>{displayValue}</option>
                        )
                    })}
                </select>
            </div>
        )
    }

    const changedSelect = (val) => {
        setRefresh(true)
        searchParams.set("PracticeRoomGroup", val)
        setSearchParams(searchParams, { replace: true })
    }

    const clickedBooking = async (param) => {

        let clickTime = "2024-06-18 14:09:00"

        const getTime = await customAxios.get("/api/getTime")
        if (getTime.data) {
            clickTime = getTime.data.response
        }

        const currentDay = dayjs(clickTime)

        let startDayjs = dayjs(param.rowStartDayjs).subtract(10, "minute")
        let endDayjs = dayjs(param.rowEndDayjs).subtract(10, "minute")

        console.log(startDayjs.format("YYYY-MM-DD HH:mm"))
        console.log(currentDay.format("YYYY-MM-DD HH:mm"))
        console.log(endDayjs.format("YYYY-MM-DD HH:mm"))

        //30분 단위 예약을 1시간으로 만들어주는 기능
        if (endDayjs.minute() === 30) {
            endDayjs = endDayjs.add(30, "minute")
            console.log(endDayjs.minute())
        }

        //-----test시에만 주석
        if (currentDay.isAfter(startDayjs) && currentDay.isBefore(endDayjs)) {
            console.log("예약가능")
        }
        else {
            alert("예약 가능 시간이 아닙니다.\n새로고침 해주세요.")
            return
        }
        //-----test시에만 주석


        const getIP = await customAxios.get("/api/getIP")
        if (getIP.data) {
            if (IP !== getIP.data.ip) {
                alert("IP가 변경되었습니다.\n새로고침 해주세요.")
                console.log("IP변경")
                return
            }
        }

        //test시 주석
        if (IP !== ChangGGoIP) {
            alert("창꼬 와이파이에 접속해주세요.")
            console.log("창꼬 와이파이 아님")
            return
        }

        if (window.confirm("예약 하시겠습니까?")) {
            const result = await customAxios.post("/api/addPractice", {
                PracticeRoom_Index: param.clickPracticeRoomData.PracticeRoom_Index,
                selectStartTime: param.rowStartDayjs.format("YYYY-MM-DD HH:mm:ss"),
                selectEndTime: param.rowEndDayjs.format("YYYY-MM-DD HH:mm:ss"),
                selectPracticeRoomGroup: selectPracticeRoomGroup,
            })
            console.log("YES")

            if (result.data) {
                if (result.data.state === 100) {
                    alert("예약되었습니다.")
                    setRefresh(true)
                }
                else {
                    if (result.data.message === "alreadyBookedUser") {
                        alert("이미 같은 시간대에 예약되었습니다.")
                        console.log("이미 같은 시간대에 예약되었습니다.")
                        setRefresh(true)
                    }
                    else if (result.data.message === "alreadyBookedTime") {
                        alert("해당 연습실은 이미 예약되었습니다.\n정원초과입니다.")
                        console.log("해당 연습실은 이미 예약되었습니다.")
                        setRefresh(true)
                    }
                }
            }

        }
    }
    const clickedDeleteBook = async (bookingIndex) => {

        if (window.confirm("예약을 취소하시겠습니까?")) {

            const result = await customAxios.post("/api/removePractice", {
                BookingPR_Index: bookingIndex,
            })
            if (result.data) {
                if (result.data.state === 100) {
                    setRefresh(true)
                }
            }
        }

    }
    const displayTable2 = () => {
        if (practiceRoomList && practiceRoomGroupData) {

            const TABLE_HEAD = ["시간"];
            const TABLE_TYPE = [true]
            practiceRoomList.forEach(practiceRoomData => {
                if (practiceRoomData.PracticeRoom_Capacity === 1) {
                    TABLE_HEAD.push(practiceRoomData.PracticeRoom_Name)
                    TABLE_TYPE.push(!TABLE_TYPE[TABLE_TYPE.length - 1])
                }
                else {

                    const type = (!TABLE_TYPE[TABLE_TYPE.length - 1])

                    for (let i = 0; i < practiceRoomData.PracticeRoom_Capacity; i++) {
                        const text = practiceRoomData.PracticeRoom_Name + "-" + (i + 1)
                        TABLE_HEAD.push(text)
                        TABLE_TYPE.push(type)
                    }
                }
            });
            const TABLE_ROWS = []
            // console.log(practiceRoomList[0])
            // console.log(practiceRoomGroupData)

            practiceRoomGroupData.arrayTime.forEach((dateTime, row) => {
                const TABLE_ROW = []

                //-------------------------------------------
                // const currentDayjs = dayjs("2024-06-05 14:00:01") //현재 시간 테스트용
                const currentDayjs = dayjs(selectDay) //현재 시간
                const rowStartDayjs = dayjs(dateTime) // 해당 row 시작 시간
                const rowEndDayjs = dayjs(dateTime).add(practiceRoomGroupData.PracticeRoomGroup_Standard, "m") // 해당 row 종료 시간

                const startDayjs = dayjs(rowStartDayjs).subtract(10, "m")
                const endDayjs = dayjs(rowEndDayjs).subtract(10, "m")

                const timeTD = (
                    <td
                        className="border-b border-blue-gray-200 text-center w-9 bg-blue-gray-50"
                        key={row + "td" + 0}>
                        <div className="flex flex-col">
                            <div>{rowStartDayjs.format("hh:mm")}</div>
                            <div>~</div>
                            <div>{rowEndDayjs.format("hh:mm")}</div>
                        </div>
                    </td>
                )
                TABLE_ROW.push(timeTD)
                //-------------------------------------------

                let count = 1

                practiceRoomList.forEach((practiceRoomData) => {
                    let limit = practiceRoomData.PracticeRoom_Capacity
                    let span = 1
                    for (let i = 0; i < limit; i++) {
                        let tdData
                        //예약 가능
                        if (practiceRoomData.arrayStates[row] === "wait") {
                            span = 1

                            if (currentDayjs.isAfter(startDayjs) && currentDayjs.isBefore(endDayjs)) {
                                tdData = <button className="rounded bg-Cgcolor text-white"
                                    onClick={(e) => {
                                        clickedBooking({
                                            rowStartDayjs: rowStartDayjs,
                                            rowEndDayjs: rowEndDayjs,
                                            row: row,
                                            clickPracticeRoomData: practiceRoomData,
                                        })
                                    }}>
                                    예약</button>
                            }
                            else {
                                tdData = (
                                    <div className="flex flex-col">{" "}</div>
                                )
                            }
                            //----test시에만 주석
                            // tdData = <button className="rounded bg-Cgcolor text-white"
                            //     onClick={(e) => {
                            //         clickedBooking({
                            //             rowStartDayjs: rowStartDayjs,
                            //             rowEndDayjs: rowEndDayjs,
                            //             row: row,
                            //             clickPracticeRoomData: practiceRoomData,
                            //         })
                            //     }}>
                            //     예약</button>
                            //----test시에만 주석


                        }
                        //이미 예약됨 ('wait'가 아닌 데이터가 있는 경우)
                        else {
                            if (practiceRoomData.arrayStates[row][i]) {
                                const idx = practiceRoomData.arrayStates[row][i].BookingPR_Index
                                const display = practiceRoomData.arrayStates[row][i].BookingPR_Display
                                const name = practiceRoomData.arrayStates[row][i].BookingPR_Name
                                const UID = practiceRoomData.arrayStates[row][i].Member_UID

                                if (loginState.Member_UID === UID
                                    && (currentDayjs.isAfter(startDayjs) && currentDayjs.isBefore(endDayjs))) {
                                    tdData = (
                                        <div className="flex flex-col">
                                            <div>{display}</div>
                                            {name === "관리자" ?
                                                null :
                                                <div> <button className="rounded bg-Cgcolor text-white" onClick={(e) => { clickedDeleteBook(idx) }}>취소</button></div>}
                                        </div>
                                    )
                                }
                                else {
                                    tdData = (
                                        <div className="flex flex-col">
                                            <div>{display}</div>
                                        </div>
                                    )
                                }

                                span = practiceRoomData.arrayStates[row][i].BookingPR_NumberOfUser
                                limit = limit - (span - 1)

                            }
                            else {
                                span = 1

                                if (currentDayjs.isAfter(startDayjs) && currentDayjs.isBefore(endDayjs)) {
                                    tdData = <button className="rounded bg-Cgcolor text-white"
                                        onClick={(e) => {
                                            clickedBooking({
                                                rowStartDayjs: rowStartDayjs,
                                                rowEndDayjs: rowEndDayjs,
                                                row: row,
                                                clickPracticeRoomData: practiceRoomData,
                                            })
                                        }}>
                                        예약</button>
                                }

                            }
                        }
                        let tdClass = ""
                        if (TABLE_TYPE[count]) {
                            tdClass = "border-b border-blue-gray-200 text-center bg-blue-gray-50"
                        }
                        else {
                            tdClass = "border-b border-blue-gray-200 text-center"
                        }

                        const tdKey = row + "td" + (count)
                        // console.log(practiceRoomData.arrayStates[row][i])
                        const tds = (
                            <td
                                colSpan={span}
                                className={tdClass}
                                key={tdKey}>
                                {tdData}
                            </td>
                        )
                        TABLE_ROW.push(tds)
                        count++
                    }
                    //-------------------------------------------
                })

                TABLE_ROWS.push(TABLE_ROW)
            })

            // console.log(TABLE_ROWS)

            return (
                <table className="w-full min-w-max table-auto mt-2">
                    <thead>
                        <tr>
                            {
                                TABLE_HEAD.map((head, col) => {
                                    let classes = ""

                                    //맨 왼쪽줄
                                    if (col === 0) {
                                        classes = "sticky left-0 "
                                    }
                                    //그 외
                                    else {
                                        classes = "p-1"
                                    }

                                    if (TABLE_TYPE[col]) {
                                        classes += " bg-blue-gray-50"
                                    }
                                    return (
                                        <th key={head} className={"whitespace-pre border-b border-blue-gray-200 text-center " + classes}>
                                            {head.replace(/\\n/g, '\n')}
                                        </th>)
                                })}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            TABLE_ROWS.map((rowData, row) => {
                                return (
                                    <tr key={"tr" + row}>
                                        {rowData}
                                    </tr>
                                )

                            })
                        }
                    </tbody>
                </table>
            )
        }
    }




    if (selectDay && practiceRoomGroupList) {

        const DOW = dateKor(dayjs(selectDay).day())

        return (
            <div className="w-full flex-col justify-center">
                <div className="flex flex-row justify-center gap-2 mt-5">
                    <button className={`rounded ${mode === "CG" ? "bg-Cgcolor text-white" : "bg-gray-400"} `}
                        onClick={(e) => { onClickMode("CG") }}
                    >
                        창꼬 실용음악학원
                    </button>
                    <button className={`rounded ${mode === "CGM" ? "bg-Cgcolor text-white" : "bg-gray-400"} `}
                        onClick={(e) => { onClickMode("CGM") }}
                    >

                        창꼬M 실용음악학원
                    </button>
                </div>
                <div className="flex justify-center">
                    <div className="flex-col w-96">
                        <div className="flex flex-row justify-center gap-3">
                            <div className="flex mt-5">
                                <Typography variant="h6" >
                                    {dayjs(selectDay).format("YYYY-MM-DD")}
                                </Typography>
                                <div className="ml-1">
                                    <Typography variant="h6" >
                                        ({DOW})
                                    </Typography>
                                </div>
                            </div>
                        </div>
                        {displaySelectRoomGroup()}
                        {displayTable2()}
                        <div className="flex flex-row my-5">
                            <div className="flex flex-col">
                                <div className="text-red-500 font-bold">연습실은 한시간씩 예약이 가능합니다.(연속 예약 불가) </div>
                                <div className="">단, 대기자가 없을 경우, 연장해서 이용 가능합니다. </div>
                                <div className="mt-2">두 시간 이상 이용 시, 대기자가 발생할 경우, </div>
                                <div className="">{"다음 대기자에게 양보 부탁드려요 :)"} </div>
                                <div className="mt-3 text-red-500 font-bold">{"연습실 예약은 10분 전부터 가능!"} </div>

                                {ChangGGoIP === IP ?
                                    <div className="mt-3">
                                        {mode === "CG" ? "창꼬 실용음악학원 와이파이 입니다 :)" : "창꼬M 실용음악학원 와이파이 입니다 :)"}
                                    </div> :
                                    <div className="mt-3">
                                        <div className="">{mode === "CG" ? "창꼬 실용음악학원 와이파이가 아닙니다." : "창꼬M 실용음악학원 와이파이가 아닙니다."}</div>
                                        <div className="">창꼬 와이파이에 접속해주세요. </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default PracticeTable