import { useState , useEffect } from "react";
import { customAxios } from '../Common/customAxios';
import { useSelector } from "react-redux";

function MyPassword(props) {
    
  const loginState = useSelector((state)=> state.login)

    const noticeGreen = "text-green-500  text-right mt-2 whitespace-pre-line";
    const noticeRed = "text-Cgcolor  text-right mt-2 whitespace-pre-line";

    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");
    const [passwordStr, setPasswordStr] = useState("");
    const [passwordCorrectState, setPasswordCorrectState] = useState(false);
    const [formValidated, setFormValidated] = useState(false);


    const handlePassword1 = (e) =>{setPassword1(e.target.value);}
    const handlePassword2 = (e) =>{setPassword2(e.target.value);}

    useEffect(()=>
    {
        if(password1 === "" && password2 ==="")
        {
            setPasswordStr("");
            setPasswordCorrectState(false);
        }
        else if(password1 === password2)
        {
            if(props.originalPassword === password1)
            {
                setPasswordStr("이전 비밀번호와 동일한 비밀번호입니다.");
                setPasswordCorrectState(false);
            }
            else
            {
                setPasswordStr("두 비밀번호가 일치합니다.");
                setPasswordCorrectState(true);
            }
        }
        else
        {
            setPasswordStr("두 비밀번호가 일치하지 않습니다.");
            setPasswordCorrectState(false);
        }

    },[password1, password2])
    

    const handleSubmit = (event) =>
    {
        event.preventDefault();
        event.stopPropagation();

        customAxios.post("/api/changePassword",{
            member_uid : loginState.Member_UID,
            password : password1,

        })
        .then((res)=>{
            if(res.data.state === 100){
                alert("비밀번호 변경에 성공하였습니다.")

            }
            else{
                alert("비밀번호 변경에 실패하였습니다.")
            }
        })

    };
    
    return (
     
        <form noValidate validated={formValidated ? "true" : "false"} onSubmit={handleSubmit}>
            <div key="MyPassword" className='basic text-black box-border py-5 px-5'>
                <div>
                    <h1 className='font-bold mb-5'>비밀번호 변경</h1>
                    <ul>
                        <li className='mb-5'>
                            <div>
                                <h2 className='mb-2'>새로운 비밀번호</h2>
                                <input
                                    required
                                    maxLength="20"
                                    type="password"
                                    className="text-black rounded-lg block w-full flex-1 rounded border-gray-300 focus:border-Cgcolor focus:ring-Cgcolor focus:ring-Cgcolor text-xs mr-2"
                                    placeholder='새로운 비밀번호를 입력해 주세요.'
                                    onChange={handlePassword1}
                                />
                            </div>
                        </li>
                        <li>
                            <div>
                                <h2 className='mb-2'>비밀번호 확인</h2>
                                <input
                                    required
                                    maxLength="20"
                                    type="password"
                                    className="text-black rounded-lg block w-full flex-1 rounded border-gray-300 focus:border-Cgcolor focus:ring-Cgcolor focus:ring-Cgcolor mr-2"
                                    placeholder='비밀번호를 한번 더 입력해 주세요.'
                                    onChange={handlePassword2}
                                />
                                <p
                                    className={passwordCorrectState? noticeGreen : noticeRed}>
                                        {passwordStr}
                                    </p>
                                <div className='flex justify-end'> 
                                    <button
                                        type="submit"
                                        className="w-1/5 block rounded-lg  bg-Cgcolor py-3 px-3 font-bold text-white mt-3"
                                        onClick={handleSubmit}
                                        >
                                        변경
                                    </button>  
                                </div>   
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </form>
      
    );
  }
    
    export default MyPassword;
    
  