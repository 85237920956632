import { Checkbox } from "@material-tailwind/react"

 const CategoryList = (props) => {

    if (props.categoryList) {
        return (
            props.categoryList.map((element, index) => {
                return (
                    <Checkbox
                        color="red"
                        key={element.value}
                        id={element.value}
                        label={element.label}
                        containerProps={{ className: "ml-2.5" }}
                        checked={props.checkBoxStates[index]}
                        onChange={(e) => { props.onChangedCheckBox(index) }}
                    />
                )
            })
        )
    }
}

export default CategoryList