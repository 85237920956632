import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom';
import { pageCollection } from '../Page/PageCollection';

export default function MyMenu() {

  const loginState = useSelector((state) => state.login)
  const location = useLocation()
  let mode = 0
  if (location.pathname.includes("Portfolio")) {
    mode = 0
  }
  else if (location.pathname.includes("Feedback")) {
    mode = 1
  }
  const normalClassName = "w-full text-left block "
  const selectClassName = "w-full text-left block text-Cgcolor"

  return (
    <div className='w-full'>
      <div>
        {/* <figure className='w-24 box-border p-5 rounded-lg bg-white mx-auto'>
            <img
                src={Profile_img}
                className="w-24"
            />
        </figure> */}
        <figcaption className='border-b border-white pb-5 my-3 w-full'>
          <h1 className='flex justify-center items-end flex-wrap w-full mb-3'>
            <span className='font-bold mr-2 break-all'>
              {loginState.Member_Nickname}
            </span>
            <span>님</span>
          </h1>

          {/* <h2 className='text-center flex flex-col w-full'>
            <Link to="/MyBusinessJoin"
              className='inline-block box-border rounded-lg bg-gray-500 px-2 py-2 text-white font-bold'>
              비즈니스 계정으로 전환
            </Link>
          </h2> */}
        </figcaption>

        <div className="text-right ">
          <Link to="/MyPrivacy"
            className='inline-block box-border rounded-lg bg-Cgcolor px-2 py-2 text-white font-bold'>
            개인정보 수정
          </Link>
        </div>
      </div>
      <div className='w-full'>
        <ul className='space-y-3 mt-10 flex flex-col'>
          <li className='box-border border-b border-white px-2 py-3 text-white hover:text-Cgcolor duration-300'>
            <Link to={pageCollection.ManagementMemberPages[11].url}
              className={location.pathname === pageCollection.ManagementMemberPages[11].url ? selectClassName : normalClassName}>
              {pageCollection.ManagementMemberPages[11].name}
            </Link>
          </li>
          {/* <li className='box-border border-b border-white px-2 py-3 text-white hover:text-Cgcolor duration-300'>
            <Link to={pageCollection.ManagementMemberPages[12].routeUrl}
              className={location.pathname === pageCollection.ManagementMemberPages[12].routeUrl ? selectClassName : normalClassName}>
              {pageCollection.ManagementMemberPages[12].name}
            </Link>
          </li> */}
        </ul>

      </div>
    </div>
  );
}

