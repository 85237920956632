import CircularProgress from '@mui/material/CircularProgress';

export default function ProductDisplay(props) {

    const handleSelectChange = (event) => {
        if(typeof(event.target.value) === 'number'){
            props.setProductCount(event.target.value);
        }
        else{
            props.setProductCount(Number(event.target.value));
        }
    };

    if (props.product && props.store) {

        let options = [];
        
        if(props.store.storeMode ==="limit"){
            if(props.product.Product_Quantity ===0){
                return (
                    <div className="flex flex-row bg-white p-6 rounded shadow-md w-full gap-3">
                        <div className="flex flex-col w-full">
                            <div className='text-lg sm:leading-normal font-bold leading-normal w-full'>
                                {props.product.Product_Name}
                            </div>
                            <div className='text-sm w-full'>
                                {/* 남은 수량 [{props.product.Product_Quantity}] */}
                            </div>
                        </div>
                        <div className="flex justify-center items-center w-full">
                            <h2 className=' text-xl sm:leading-normal font-bold leading-normal w-full'>
                                <span className='block text-sm'>
                                    <span className='text-Cgcolor text-xl'>품절</span>
                                </span>
                            </h2>
                        </div>
                    </div>
                )
            }
           if(props.product.Product_Quantity<10){
                for(let i=1; i<props.product.Product_Quantity+1; i++){
                    options.push(i)
                }
            }
            else{
                options=[1,2,3,4,5,6,7,8,9,10]
            }
        }
        
        else if(props.store.storeMode ==="noLimit"){
            options=[1,2,3,4,5,6,7,8,9,10]
        } 
        
      
        
        return (
            <div className="bg-white p-5 rounded border-box border w-full">
                <div className="flex flex-col w-full border-box py-3 mb-5 border-b">
                    <div className='text-lg sm:leading-normal font-bold leading-normal w-full'>
                        {props.product.Product_Name}
                    </div>
                    {props.store.storeMode === "limit" ? 
                    <div className='text-sm w-full'>
                        {/* 남은 수량 [{props.product.Product_Quantity}] */}
                    </div> : null}
                </div>
                <div className="flex mb-3">
                    <label className="flex items-center text-gray-700 text-sm font-bold mr-2" htmlFor="exampleSelect">
                        수량 :
                    </label>
                    <select
                        className="w-16 border border-gray-300 rounded"
                        id="exampleSelect"
                        value={props.productCount}
                        onChange={handleSelectChange}
                    >
                        {options.map((option, index) => (
                            <option key={index} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="flex justify-center items-center w-full">
                    <h2 className=' text-xl sm:leading-normal font-bold leading-normal w-full'>
                        <span className='block text-sm'>
                            <span className='text-Cgcolor text-xl'>{Intl.NumberFormat().format(props.product.Product_Price * props.productCount)}</span>원
                        </span>
                    </h2>
                </div>
            </div>
        )

    }
    else {
        return (<CircularProgress />)
    }




}