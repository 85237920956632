import Header from '../../Components/Fixed/Header';
import Footer from '../../Components/Fixed/Footer';

import ClassData from '../../Components/Fixed/ClassData';


function Class() {

  return (
    <div className='max-w-lg mx-auto'>
      <Header />
      <ClassData />
      <Footer />
    </div>

  );
}

export default Class;

