import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { customAxios } from "../../Components/Common/customAxios";

import AdminHeader from "../../Components/Fixed/AdminHeader";
import Footer from "../../Components/Fixed/Footer";
import TableAdminPayment from "../../Components/Table/TableAdminPayment";
import { DefaultPagination } from "../../Components/Pagination/DefaultPagination";
import { deliveryStateStruct } from "../../Components/Common/dataStruct";
import dayjs from "dayjs";
import { Input } from "@material-tailwind/react";


const AdminPaymentList = () => {

    const [start, setStart] = useState(false)
    const navigate = useNavigate()
    const loginState = useSelector((state) => state.login)
    const [productCount, setProductCount] = useState(0)
    const [filterProdcut, setFilterProdcut] = useState()
    const [prodcut, setProdcut] = useState()
    const [searchParams, setSearchParams] = useSearchParams()

    let pageMin = 1
    let pageMax = 1

    const limit = Number(searchParams.get("limit"))
    const active = Number(searchParams.get("active"))
    const deliveryState = searchParams.get("deliveryState")
    const startDate=searchParams.get("startDate")
    const endDate=searchParams.get("endDate")

    const [searchNickname,setSearchNickname] =useState("")

    useEffect(()=>{
        if(searchNickname.length>0){
            const filters = []
            prodcut.forEach(element => {
                if (element.Member_Nickname.includes(searchNickname)){
                    filters.push(element)
                }
            })
            setFilterProdcut(filters)
        }
        else{
            setFilterProdcut(prodcut)
        }

    },[searchNickname,prodcut])

    const setLimit = (index) => {
        searchParams.set("limit", index)
        setSearchParams(searchParams, { replace: true })
        setActive(1)
    }
    const setActive = (index) => {
        searchParams.set("active", index)
        setSearchParams(searchParams, { replace: true })
    }
    const setDeliveryState = (value) => {
        searchParams.set("deliveryState", value)
        setSearchParams(searchParams, { replace: true })
        setActive(1)
    }
    const setStartDate = (value) => {
        searchParams.set("startDate", value)
        setSearchParams(searchParams, { replace: true })
        setActive(1)
    }
    const setEndDate = (value) => {
        searchParams.set("endDate", value)
        setSearchParams(searchParams, { replace: true })
        setActive(1)
    }

    if(limit>0){
        if (productCount % (limit) === 0) {
            pageMax = parseInt(productCount / (limit))
        }
        else {
            pageMax = parseInt(productCount / (limit)) + 1
        }
    }
  
    const getAdminPayment = async () => {
        const result = await customAxios.get("/api/getAdminPayment", { params: { 
            active: active, 
            limit: limit, 
            deliveryState:deliveryState,
            startDate:startDate,
            endDate:endDate,
        } })
        if (result.data.state === 101) {
            setProdcut(result.data.response)
            setFilterProdcut(result.data.response)
        }
    }

    useEffect(() => {
        setStart(true)
    }, [])

    useEffect(() => {
        const getPaymentCount = async () => {
            const result = await customAxios.get("/api/getPaymentCount",{ params: { 
                active: active, 
                limit: limit, 
                deliveryState:deliveryState,
                startDate:startDate,
                endDate:endDate,
            } })
            if (result.data.state === 101) {
                setProductCount(result.data.response)
            }

        }
        if (start) {

            if (loginState.isLogin === null) {

            }
            else if (loginState.isLogin === true) {

                getPaymentCount()

                if (active === 0) {
                    searchParams.set("active", 1)
                    setSearchParams(searchParams, { replace: true })
                }
                if (limit === 0) {
                    searchParams.set("limit", 100)
                    setSearchParams(searchParams, { replace: true })
                }
                if (deliveryState === null) {
                    searchParams.set("deliveryState", "all")
                    setSearchParams(searchParams, { replace: true })
                }
                if (startDate === null) {
                    searchParams.set("startDate", "")
                    setSearchParams(searchParams, { replace: true })
                }
                if (endDate === null) {
                    searchParams.set("endDate", "")
                    setSearchParams(searchParams, { replace: true })
                }
                if (active > 0 && limit > 0 && deliveryState!==null &&startDate!==null && endDate!==null) {
                    getAdminPayment()
                }
            }
            else if (loginState.isLogin === false) {
                alert("권한이 없습니다.")
                navigate("/", { replace: true })
            }
        }
    }, [start, loginState.isLogin, loginState.Member_Auth, active,limit,deliveryState,startDate,endDate])

    const normalDeliveryMenu = "bg-gray-500"
    const selectDeliveryMenu = "bg-green-600"

    const todayjs = dayjs(new Date())
    

    if (loginState.isLogin) {
        return (
            <div>
                <section>
                    <article className='main-visual_wrap'>
                        <div className='mx-auto  px-5'>
                            <div className='flex flex-col py-10'>
                                <div className="flex border border-b-2 gap-5">
                                    <div className="">출력 개수</div>
                                    <div className="flex">
                                        <button className={limit === 100? selectDeliveryMenu:normalDeliveryMenu} onClick={(e)=>{setLimit(100)}}>100</button>
                                        <button className={limit === 200? selectDeliveryMenu:normalDeliveryMenu} onClick={(e)=>{setLimit(200)}}>200</button>
                                        <button className={limit === 300? selectDeliveryMenu:normalDeliveryMenu} onClick={(e)=>{setLimit(300)}}>300</button>
                                    </div>
                                </div>
                                <div className="flex border border-b-2 gap-5">
                                    <div className="">주문일자</div>
                                    { startDate !==null ?
                                        <div className="flex">
                                            <input type="date" value={startDate} onChange={(e) => { setStartDate(e.target.value) }} max={todayjs.format("YYYY-MM-DD")} />
                                            <input type="date" value={endDate} onChange={(e) => { setEndDate(e.target.value) }} max={todayjs.format("YYYY-MM-DD")} />
                                            <button className={normalDeliveryMenu} onClick={(e) => {
                                                setStartDate("")
                                                setEndDate("")
                                            }}>전체</button>
                                            <button className={normalDeliveryMenu} onClick={(e) => {
                                                setStartDate(todayjs.format("YYYY-MM-DD"))
                                                setEndDate(todayjs.format("YYYY-MM-DD"))
                                            }}>오늘</button>
                                            <button className={normalDeliveryMenu} onClick={(e) => {
                                                const yesterday = todayjs.subtract(1, "day")
                                                setStartDate(yesterday.format("YYYY-MM-DD"))
                                                setEndDate(yesterday.format("YYYY-MM-DD"))
                                            }}>어제</button>
                                            <button className={normalDeliveryMenu} onClick={(e) => {
                                                setStartDate(dayjs().day(0).format("YYYY-MM-DD"))
                                                setEndDate(todayjs.format("YYYY-MM-DD"))
                                            }}>이번주</button>
                                            <button className={normalDeliveryMenu} onClick={(e) => {
                                                setStartDate(dayjs().day(-7).format("YYYY-MM-DD"))
                                                setEndDate(dayjs().day(-1).format("YYYY-MM-DD"))
                                            }}>지난주</button>
                                            <button className={normalDeliveryMenu} onClick={(e) => {
                                                const startMonth = dayjs().day(0).set("D", 1)
                                                setStartDate(startMonth.format("YYYY-MM-DD"))
                                                setEndDate(todayjs.format("YYYY-MM-DD"))
                                            }}>이번달</button>
                                            <button className={normalDeliveryMenu} onClick={(e) => {
                                                const startMonth = dayjs().day(0).set("D", 1).subtract(1, "M")
                                                const daysInMonth = startMonth.daysInMonth()
                                                const endMonth = startMonth.set("D", daysInMonth)
                                                setStartDate(startMonth.format("YYYY-MM-DD"))
                                                setEndDate(endMonth.format("YYYY-MM-DD"))
                                            }}>지난달</button>
                                        </div>
                                    :null
                                    }
                                </div>
                                <div className="flex border border-b-2 gap-5">
                                    <div className="">주문상태</div>
                                    <div className="flex">
                                        <button className={deliveryState === "all"? selectDeliveryMenu:normalDeliveryMenu} onClick={(e)=>{setDeliveryState("all")}}>전체</button>
                                        <button className={deliveryState === deliveryStateStruct[0].value? selectDeliveryMenu:normalDeliveryMenu} onClick={(e)=>{setDeliveryState(deliveryStateStruct[0].value)}}>{deliveryStateStruct[0].label}</button>
                                        <button className={deliveryState === deliveryStateStruct[1].value? selectDeliveryMenu:normalDeliveryMenu} onClick={(e)=>{setDeliveryState(deliveryStateStruct[1].value)}}>{deliveryStateStruct[1].label}</button>
                                        <button className={deliveryState === deliveryStateStruct[2].value? selectDeliveryMenu:normalDeliveryMenu} onClick={(e)=>{setDeliveryState(deliveryStateStruct[2].value)}}>{deliveryStateStruct[2].label}</button>
                                        <button className={deliveryState === deliveryStateStruct[3].value? selectDeliveryMenu:normalDeliveryMenu} onClick={(e)=>{setDeliveryState(deliveryStateStruct[3].value)}}>{deliveryStateStruct[3].label}</button>
                                        <button className={deliveryState === deliveryStateStruct[4].value? selectDeliveryMenu:normalDeliveryMenu} onClick={(e)=>{setDeliveryState(deliveryStateStruct[4].value)}}>{deliveryStateStruct[4].label}</button>
                                        <button className={deliveryState === deliveryStateStruct[5].value? selectDeliveryMenu:normalDeliveryMenu} onClick={(e)=>{setDeliveryState(deliveryStateStruct[5].value)}}>{deliveryStateStruct[5].label}</button>
                                    </div>
                                </div>
                                <div className="flex border border-b-2 gap-5">
                                    <div className="">조건</div>
                                    <div>
                                        <Input label="닉네임" value={searchNickname} onChange={(e) => { setSearchNickname(e.target.value) }}/>
                                    </div>
                                </div>
                                <div className="flex border border-b-2 gap-5">
                                    {/* <button key={"refresh"} className="flex   bg-blue-gray-100"
                                    // onClick={refreshMemberData}
                                    > 새로고침
                                    </button> */}
                                </div>
                                <TableAdminPayment 
                                    TABLE_DATA={filterProdcut}
                                    getAdminPayment={getAdminPayment}
                                />
                                <div className="flex w-full justify-center">
                                    <DefaultPagination
                                        key="DefaultPagination"
                                        active={active}
                                        setActive={setActive}
                                        min={pageMin}
                                        max={pageMax}
                                    />
                                </div>
                            </div>
                        </div>

                    </article>

                </section>

                <Footer />
            </div>
        )
    }
}

export default AdminPaymentList