
import { BsYoutube} from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import { Link } from "react-router-dom";
import { pageCollection } from "../Page/PageCollection";
import { useState } from "react";
import { customAxios } from "../Common/customAxios";

function Footer() {


  const [countLogo, setCountLogo] = useState(0);

  const onClickLogo = async () => {
    setCountLogo(countLogo + 1);

    if (countLogo === 7) {
      const toast = document.createElement('div');
      toast.className = 'fixed bottom-5 right-5 bg-gray-800 text-white px-4 py-2 rounded shadow-lg';
      toast.innerText = '3';
      document.body.appendChild(toast);
      setTimeout(() => {
        toast.remove();
      }, 1000);
    } else if (countLogo === 8) {
      const toast = document.createElement('div');
      toast.className = 'fixed bottom-5 right-5 bg-gray-800 text-white px-4 py-2 rounded shadow-lg';
      toast.innerText = '2';
      document.body.appendChild(toast);
      setTimeout(() => {
        toast.remove();
      }, 1000);
    } else if (countLogo === 9) {
      const toast = document.createElement('div');
      toast.className = 'fixed bottom-5 right-5 bg-gray-800 text-white px-4 py-2 rounded shadow-lg';
      toast.innerText = '1';
      document.body.appendChild(toast);
      setTimeout(() => {
        toast.remove();
      }, 1000);
    }
    if(countLogo >= 10){
      setCountLogo(0);

      const userAgent = navigator.userAgent;
      const browser = (() => {
        if (userAgent.indexOf("Firefox") > -1) {
          return "Firefox";
        } else if (userAgent.indexOf("SamsungBrowser") > -1) {
          return "Samsung Internet";
        } else if (userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1) {
          return "Opera";
        } else if (userAgent.indexOf("Trident") > -1) {
          return "Internet Explorer";
        } else if (userAgent.indexOf("Edge") > -1) {
          return "Microsoft Edge";
        } else if (userAgent.indexOf("Chrome") > -1) {
          return "Chrome";
        } else if (userAgent.indexOf("Safari") > -1) {
          return "Safari";
        }
        return "Unknown";
      })();
      const version = (() => {
        const match = userAgent.match(/(firefox|msie|trident|chrome|safari|opr|edg|samsungbrowser)\/?\s*(\d+)/i);
        if (match && match.length > 2) {
          return match[2];
        }
        return "Unknown";
      })();
      const os = (() => {
        if (userAgent.indexOf("Win") > -1) {
          return "Windows";
        } else if (userAgent.indexOf("like Mac") > -1) {
          return "iOS";
        } else if (userAgent.indexOf("Mac") > -1) {
          return "MacOS";
        } else if (userAgent.indexOf("Android") > -1) {
          return "Android";
        } else if (userAgent.indexOf("Linux") > -1) {
          return "Linux";
        }
        return "Unknown";
      })();
      const osVersion = (() => {
        const match = userAgent.match(/(Windows NT|Mac OS X|Android|CPU OS|Linux) ([\d_\.]+)/i);
        if (match && match.length > 2) {
          return match[2].replace(/_/g, '.');
        }
        return "Unknown";
      })();
      const popupEnabled = (() => {
        const testPopup = window.open("", "", "width=100,height=100");
        if (testPopup) {
          testPopup.close();
          return true;
        }
        return false;
      })();
      // const redirectEnabled = (() => {
      //   const testRedirect = window.location.href;
      //   try {
      //     window.location.href = testRedirect;
      //     return true;
      //   } catch (e) {
      //     return false;
      //   }
      // })();

      const getIP = async () => {
        const result = await customAxios.get("/api/getIP")

        let ip = "Unknown";
        if (result.data) {
            ip = result.data.ip
        }

        const data = {
          browser,
          version,
          os,
          osVersion,
          popupEnabled,
          ip,
          // redirectEnabled
        }
        alert(JSON.stringify(data, null, '\n'));
      }

      getIP();
    }
  }
  
  return (
    <div className=" px-5 sm:py-10 sm:pb-40 py-5  bg-white rounded border-t border-gray-300">
      <div className="flex mx-auto max-w-7xl">
        <ul className="w-full flex mb-5 justify-between">
          <li className="space-x-3 flex">
            <a href="https://www.instagram.com/changggo_/" target="_blank" className="text-2xl text-gray-700 transition hover:text-black">
              <AiFillInstagram/>
            </a>
            <a href="https://www.youtube.com/channel/UCuaRx-4wUbMuCSkA6jX4tmA" target="_blank" className="text-2xl text-gray-700 transition hover:text-black">
              <BsYoutube/>
            </a>
          </li>
        </ul>
      </div>
      <div className="relative flex items-center justify-between mx-auto max-w-7xl">
        <ul className="text-gray-600 gap-5">
          <li className="mb-2 rounded-2xl p-2">
            <p className="mb-1"><span>(주)창꼬</span></p>
            <p className="sm:ml-3 mb-1"><span>대표 : 이창섭</span></p>
            <p className="sm:ml-3 mb-1"><span>경기도 수원시 팔달구 덕영대로 895</span></p>
            <p className="sm:ml-3 mb-1"><span>전화번호 : 070-7782-0309</span></p>
            <p className="sm:ml-3 mb-1"><span>사업자등록번호 : 221-87-02867</span></p>
            <p className="sm:ml-3 mb-1"><span>통신판매업신고 : 2023-수원팔달-0368</span></p>
          </li>
          <li className="mb-2 rounded-2xl p-2">
            <p className="mb-1"><span>창꼬실용음악학원</span></p>
            <p className="sm:ml-3 mb-1"><span>경기도 수원시 팔달구 덕영대로 895</span></p>
            <p className="sm:ml-3 mb-1"><span>학원등록번호 : 수원 제7609호</span></p>
            <p className="mt-3 mb-1"><span>창꼬엠실용음악학원</span></p>
            <p className="sm:ml-3 mb-1"><span>경기도 수원시 팔달구 덕영대로 895번길 22-15, 2층</span></p>
            <p className="sm:ml-3 mb-1"><span>학원등록번호 : 수원 제7903호</span></p>
          </li>
          
          <li className="flex flex-row gap-2 mb-2">
            <Link to={pageCollection.etcPages[0].url}
              className ={"hover:text-black font-bold transition"}> 
              {pageCollection.etcPages[0].name}
            </Link>
            <Link to={pageCollection.etcPages[1].url}
              className ={"hover:text-black hover:font-bold transition"}> 
              {pageCollection.etcPages[1].name}
            </Link>
          </li>
          <li>
            © 2023 창꼬. All Rights Reserved.
          </li>
          <li>
          <div className='mt-5 Cgfont text-Cgcolor md:text-sm text-sm Cglogo' onClick={onClickLogo}>
            <img src={"logo_new.png"} alt='logo' />
          </div>
          </li>
        </ul>
      </div>
    </div> 
  );
}
  
  export default Footer;
  
