import { createSlice } from '@reduxjs/toolkit'

export const storeCategorySlice = createSlice({
  name: 'storeCategory',
  initialState: {
    isReady: false,
    storeCategoryData : null,
  },
  reducers: {
    setStoreCategoryData: (state, action) => {
      state.storeCategoryData = action.payload
      state.isReady = true
    },
   
  },
})

// Action creators are generated for each case reducer function
export const { setStoreCategoryData } = storeCategorySlice.actions

export default storeCategorySlice.reducer