import { useEffect, useState } from "react";
import { Card, Input, Checkbox, Button, Typography, Textarea, } from "@material-tailwind/react";
import dayjs from "dayjs";

import { customAxios } from "../Common/customAxios";
import { useNavigate, useSearchParams } from "react-router-dom";

function AdminEventWriteForm() {

    const [start, setStart] = useState(false)
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()

    const [eventUID, setEventUID] = useState('')
    const [eventTitle, setEventTitle] = useState('');
    const [eventAbstract, setEventAbstract] = useState('');
    const [eventCategory, setEventCategory] = useState('00');
    const [eventImg, setEventImg] = useState([])
    const [eventContext, setEventContext] = useState('');
    const [eventURL, setEventURL] = useState('');

    const page = searchParams.get("page")


    useEffect(() => {
        setStart(true)
    }, [])

    useEffect(() => {
        const getEventData = async () => {
            const res = await customAxios.get('/api/getEventData' + "?page=" + page)
            const eventData = res.data.response.current

            if(eventData.Event_UID)
                setEventUID(eventData.Event_UID)
            if(eventData.Event_Title)
                setEventTitle(eventData.Event_Title)
            if(eventData.Event_Abstract)
                setEventAbstract(eventData.Event_Abstract)
            if(eventData.Event_Category)
                setEventCategory(eventData.Event_Category)
            if(eventData.Event_Context)
                setEventContext(eventData.Event_Context)
            if(eventData.Event_URL)
                setEventURL(eventData.Event_URL)

            const URLs = JSON.parse(eventData.Event_ThumbnailURL)
            const files = []

            for (let i = 0; i < URLs.length; i++) {
              const response = await fetch(URLs[i]);
              // 응답 데이터를 blob 형식으로 변환
              const blob = await response.blob();
              files.push(blob)
            }
            setEventImg(files)
            
        }

        if(start){
            if(page){
                getEventData()
            }
        }
    }, [start])

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (window.confirm('이 게시물을 등록 하시겠습니까?')) {
            try {
                const formData = new FormData()
                formData.append('Event_UID', eventUID)
                formData.append('Event_Title', eventTitle)
                formData.append('Event_Abstract', eventAbstract)
                formData.append('Event_Category', eventCategory)
                formData.append('Event_Context', eventContext)
                formData.append('Event_URL', eventURL)
                // 본문 이미지 추가
                eventImg.forEach((image) => {
                    formData.append('Event_Thumbnail', image);
                });

                const response = await customAxios.post('/api/WriteEvent', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                if(response.data){
                    if(response.data.state===100){
                        alert("등록이 완료되었습니다.")
                        navigate("/AdminEventList")
                    }
                    else{
                        alert("등록에 실패하였습니다.\n["+response.data.message+"]")
                    }
                }
                else{
                    alert("요청에 실패하였습니다.")
                }
                console.log('Event created successfully:', response.data);
            } catch (error) {
                console.error('Error creating event:', error);
            }

        }
    };

    const removeContextImg = (index) => {
        setEventImg(prevContextImg => prevContextImg.filter((_, idx) => idx !== index));
      };

    const ContextImgChange = (event) => {
        const files = event.target.files; // 업로드된 파일들
    
        // 파일이 존재하는 경우에만 처리
        if (files && files.length > 0) {
          const updatedContextImg = [...eventImg]; // 기존 이미지 배열 복사
    
          // 업로드된 각 파일을 배열에 추가
          for (let i = 0; i < files.length; i++) {
            updatedContextImg.push(files[i]);
          }
    
          // 변경된 이미지 배열을 상태에 업데이트
          setEventImg(updatedContextImg);
        }
      };


    return (
        <section>
            <article className='main-visual_wrap'>
                <Card className="p-2" color="transparent" shadow={false}>
                    <form className="mb-2 " onSubmit={handleSubmit}>
                        <div className="mb-1 flex flex-col gap-3">
                            <Textarea
                                className="min-h-full "
                                label="제목"
                                value={eventTitle}
                                rows={1}
                                maxLength={100}
                                onChange={(e) => setEventTitle(e.target.value)} />

                            <Textarea label="요약"
                                maxLength={100}
                                value={eventAbstract}
                                onChange={(e) => setEventAbstract(e.target.value)} />
                            <Typography variant="h6" color="blue-gray" className="">
                                본문 이미지
                            </Typography>
                            <div className="flex flex-wrap gap-4">
                                {eventImg.map((image, index) => (
                                    <div key={index} className="relative">
                                        <img src={URL.createObjectURL(image)} alt={`Thumbnail ${index}`} className="w-40 h-40 object-cover rounded-lg" />
                                        <button type="button" className="absolute top-0 right-0 p-1 bg-red-500 text-white rounded-full" onClick={() => removeContextImg(index)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                        </button>
                                    </div>
                                ))}
                            </div>
                            <label htmlFor="file-upload2" className="px-4 py-2 w-12 bg-white border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                <span className="text-xl font-bold mr-2">+</span>
                                <input id="file-upload2" name="file-upload2" type="file" accept="image/*" className="sr-only" onChange={ContextImgChange} />
                            </label>
                            <div>
                                <Textarea label="내용"
                                    size="lg"
                                    rows={25}
                                    resize={true}
                                    value={eventContext}
                                    onChange={(e) => setEventContext(e.target.value)}
                                />
                            </div>
                            <div>
                                <Input label="URL" value={eventURL} onChange={(e) => setEventURL(e.target.value)}/>
                            </div>
                        </div>
                        <div className="flex justify-end">
                            <Button type="submit" variant="outlined" className="rounded bg-Cgcolor text-white" > 업로드 </Button>
                        </div>
                    </form>
                </Card>
            </article>
        </section>
    )
}

export default AdminEventWriteForm;
