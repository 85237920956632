import AdminHeader from "../../Components/Fixed/AdminHeader";
import AdminAuthCheck from "../../Components/Admin/AdminAuthCheck";
import AdminEventWriteForm from "../../Components/Admin/AdminEventWriteForm";

const AdminEventWrite = () => {

    return (
        <div className="h-[calc(100vh-2rem)] flex flex-row ">
            <AdminAuthCheck auth="M1"/>
            <AdminHeader mode={3}/>
            <div className="p-1 w-full">
                <AdminEventWriteForm/>
            </div>
        </div>
    )
}

export default AdminEventWrite