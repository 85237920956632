import React, { useState } from 'react';

const BirthDay = ({ selectedDate, onDateChange }) => {
  
  const handleYearChange = (e) => {
  
    if (onDateChange) {
      onDateChange({ 
        year: e.target.value, 
        month : selectedDate.month, 
        day: selectedDate.day 
      });
    }
  };

  const handleMonthChange = (e) => {
  
    if (onDateChange) {
      onDateChange({ 
        year: selectedDate.year, 
        month : e.target.value, 
        day: selectedDate.day 
       });
    }
  };

  const handleDayChange = (e) => {
  
    if (onDateChange) {
      onDateChange({ 
        year: selectedDate.year, 
        month : selectedDate.month, 
        day: e.target.value });
    }
  };

  const getYears = () => {
    const years = [];
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i >= 1940; i--) {
      years.push(i);
    }
    return years;
  };

  const getMonths = () => {
    return Array.from({ length: 12 }, (v, k) => k + 1);
  };

  const getDays = () => {
    const daysInMonth = new Date(selectedDate.year, selectedDate.month, 0).getDate();
    return Array.from({ length: daysInMonth }, (v, k) => k + 1);
  };

  return (
    <div className="flex gap-1">
      <select className='w-full' value={selectedDate.year} onChange={handleYearChange}>
        <option value="">년</option>
        {getYears().map((y) => (
          <option key={y} value={y}>
            {y}
          </option>
        ))}
      </select>

      <select className='w-full' value={selectedDate.month} onChange={handleMonthChange} disabled={!selectedDate.year}>
        <option value="">월</option>
        {getMonths().map((m) => (
          <option key={m} value={m}>
            {m}
          </option>
        ))}
      </select>

      <select className='w-full' value={selectedDate.day} onChange={handleDayChange} disabled={!selectedDate.month}>
        <option value="">일</option>
        {getDays().map((d) => (
          <option key={d} value={d}>
            {d}
          </option>
        ))}
      </select>
    </div>
  );
};

export default BirthDay;
