import { configureStore } from '@reduxjs/toolkit'
import counterSlice from './counterSlice'
import loginSlice from './loginSlice'
import memberSlice from './memberSlice'
import storeCategorySlice from './storeCategorySlice'

export default configureStore({
  reducer: {
    counter: counterSlice,
    member: memberSlice,
    login: loginSlice,
    storeCategory: storeCategorySlice,
  },
})