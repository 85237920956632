import React, { Component } from "react";
import Header from '../Components/Fixed/Header';
import Footer from '../Components/Fixed/Footer';
import HomeData from '../Components/Fixed/HomeData';

function Home() {
    return (
        <div className="max-w-lg mx-auto">
            <Header/>
            <HomeData />
            <Footer />
        </div>

    );
}

export default Home;


