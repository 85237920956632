import { useEffect, useState } from "react";
import { Card, Input, Textarea, } from "@material-tailwind/react";
import { customAxios } from "../Common/customAxios";
import { useNavigate, useSearchParams } from "react-router-dom";

import CategoryList from "../Checkbox/CategoryList"
import { categoryList } from "../Common/dataStruct";
import dayjs from "dayjs";


function AdminExamWriteForm() {

    const [start, setStart] = useState(false)
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()

    const examUID = searchParams.get('Exam_UID')=== null ? '' : searchParams.get('Exam_UID')
    const [examTitle, setExamTitle] = useState('');
    const [examText, setExamText] = useState(dayjs().get('month')+1)
    const [examContext, setExamContext] = useState('')
    const [examLimit, setExamLimit] = useState(20)

    useEffect(() => {
        setStart(true)
    }, [])


    useEffect(() => {
        const getExamData = async () => {
            const res = await customAxios.get('/api/getExam' + "?page=" + examUID)
            console.log(res.data)
            if(res.data && res.data.state===101){

                const examData = res.data.response

                if(examData.Exam_Title)
                    setExamTitle(examData.Exam_Title)
                if(examData.Exam_Text)
                    setExamText(examData.Exam_Text)
                if(examData.Exam_Category){

                    const objExamCategory = JSON.parse(examData.Exam_Category)

                    const initialBoxState = []
                    categoryList.forEach(() => {
                        initialBoxState.push(false)
                    })

                    objExamCategory.forEach((value)=>{
                        const index = categoryList.findIndex(category => category.value === value);
                        initialBoxState[index] = true
                    })

                    setCheckBoxStates(initialBoxState)

                    // console.log(examData.Exam_Category)
    
                }
                if(examData.Exam_Context)
                    setExamContext(examData.Exam_Context)
                if(examData.Exam_Limit)
                    setExamLimit(examData.Exam_Limit)

            }
            
            
        }
        if (start) {
            if(examUID !== '')
                getExamData()
        }
    }, [start])

    const initialBoxState = []

    categoryList.forEach(() => {
        initialBoxState.push(false)
    })

    const [checkBoxStates, setCheckBoxStates] = useState(initialBoxState)

    const onChangedCheckBox = (index) => {
        const nextCheckBoxStates = checkBoxStates.map((element, i) => {
            if (index === i) {
                return element = !element
            }
            else {
                return element
            }
        })
        setCheckBoxStates(nextCheckBoxStates)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(examTitle ===''){
            alert("제목을 입력해주세요.")
            return
        }

        let check = false
        checkBoxStates.forEach((element)=>{

            if(element)
                check = true 
        })
        if(!check){
            alert("분류를 선택해주세요.")
            return
        }

        if (window.confirm('등록 하시겠습니까?')) {
            try {
                const examCategory = checkBoxStates.map((element, index) => element ? categoryList[index].value : null).
                filter(value => value !== null);


                let text = String(examText)

                const response = await customAxios.post('/api/writeExam', 
                    {
                        Exam_UID: examUID,
                        Exam_Title: examTitle,
                        Exam_Context: examContext,
                        Exam_Category: examCategory,
                        Exam_Limit: examLimit,
                        Exam_Text: text,
                    }
                )
                if (response.data) {
                    if (response.data.state === 100) {
                        alert("등록이 완료되었습니다.")
                        navigate("/AdminExam")
                    }
                    else {
                        alert("등록에 실패하였습니다.\n[" + response.data.message + "]")
                    }
                }
                else {
                    alert("요청에 실패하였습니다.")
                }
                console.log(' created successfully:', response.data);
            } catch (error) {
                console.error('Error creating:', error);
            }

        }
    };


    return (
        <section className="flex p-2 w-full justify-start">
            <article className='main-visual_wrap'>
                <Card className="pt-2" color="transparent" shadow={false}>
                    <form className="mb-2 " onSubmit={handleSubmit}>
                        <div className="mb-1 flex flex-col w-80 gap-2">
                            <div className="">
                                <Textarea 
                                    className="text-lg" 
                                    label="오디션 평가 제목" 
                                    value={examTitle}
                                    rows={2}
                                    maxLength={255}
                                    onChange={(e) => setExamTitle(e.target.value)} />
                            </div>
                            <div className="">
                                <Input 
                                    className="" 
                                    label="( ) 월 평가"
                                    type="number"
                                    min={1}
                                    max={12}
                                    step={1}
                                    value={examText}
                                    onChange={(e)=>{
                                        let text = e.target.value
                                        let num = Number(text)
                                        
                                        if(num>12)
                                            num=12
                                        if(num<1)
                                            num=1
                                        
                                        setExamText(num)}}
                                />
                            </div>
                            <div>
                                <CategoryList
                                    checkBoxStates={checkBoxStates}
                                    categoryList={categoryList}
                                    onChangedCheckBox={onChangedCheckBox}
                                />
                            </div>
                            <Textarea
                                className="min-h-full "
                                label="내용"
                                value={examContext}
                                rows={35}
                                maxLength={1000}
                                onChange={(e) => setExamContext(e.target.value)} />
                            <div>
                                <Input type="number" label="평가 인원" value={examLimit} onChange={(e) => setExamLimit(e.target.value)} />
                            </div>
                        </div>
                        <div className="flex justify-end">
                            <button type="submit" className="rounded bg-Cgcolor text-white" > 등록 </button>
                        </div>
                    </form>
                </Card>
            </article>
        </section>
    )
}

export default AdminExamWriteForm;
