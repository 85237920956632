
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Textarea,
  IconButton,
} from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { customAxios } from "../Common/customAxios";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import dayjs from "dayjs";


export default function PaymentListDialog(props) {

  const [start, setStart] = useState(false)
  const loginState = useSelector((state) => state.login)
  const [myPayment, setMyPayment] = useState(null)

  useEffect(() => {
    setStart(true)
  }, [])

  useEffect(() => {
    const asyncGetMyPayment = async () => {
      const result = await customAxios.get("/api/getMyPayment")
      setMyPayment(result.data.response)
    }

    if (loginState.isLogin === false) {
      alert("로그인이 필요합니다.")
    }
    else if (loginState.isLogin === true) {
      if (start) {
        asyncGetMyPayment()
      }
    }
  }, [start, loginState.isLogin])


  const onClickCancel = () => {
    props.setOpen(false)
  }

  let itemDisplay
  if (myPayment === null) {
    itemDisplay = (<CircularProgress key="CircularProgress" />)
  }
  else if (myPayment) {

    // console.log(props.myPayment)

    itemDisplay = []
    myPayment.forEach((element, idx) => {
      const mDay = dayjs(element.Payment_Date)
      itemDisplay.push(
        <div key={"itemDisplay" + idx} className="">
          <div className='text-base font-bold ml-2 mt-2'>
            {mDay.format("YYYY.MM.DD")} 주문
          </div>
          <div className='border m-2'>
            <div className='flex flex-col gap-1 m-3 basis-3/4 hover:bg-gray-100 cursor-pointer' 
              onClick={(e)=>{
                props.setSelectPayment(element)
                props.setOpen(false)
              }}
            >
              <div className=''>
                주문 번호 {element.Payment_Index}
              </div>
              <div className=''>
                상품 이름 : {element.Payment_Custom.products[0].Product_Name}
              </div>
              <div className=''>
                상품 수량 : {element.Payment_Custom.products[0].selectProductQuantity}
              </div>
              <div className=''>
                결제 금액 : {Intl.NumberFormat().format(element.Payment_Buyer.amount)}
              </div>
            </div>
          </div>

        </div>
      )
    });
  }


  return (
    <div>
      <Dialog
        open={props.open}
        className=""
        size="md"
        onClose={onClickCancel
        }
      >
        <DialogHeader id="alert-dialog-title" className='bg-Cgcolor text-white justify-between'>
          <div className="text-base"> 문의하실 상품을 선택해주세요 </div>
          <IconButton color="white" size="sm" variant="text" onClick={onClickCancel} >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
              className="h-5 w-5"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </IconButton>
        </DialogHeader>
        <DialogBody className='flex flex-col overflow-y-scroll h-96' >
          <div className="text-black">
            {itemDisplay}
          </div>
        </DialogBody>
      </Dialog>
    </div>
  );
}