import Header from '../../Components/Fixed/Header';
import Footer from '../../Components/Fixed/Footer';

import { useState } from 'react';
import { customAxios } from '../../Components/Common/customAxios';


  
function Lost() {

    const noticeGreen = "text-green-500  text-right mt-5 whitespace-pre-line";
    const noticeRed = "text-Cgcolor  text-right mt-5 whitespace-pre-line";


    const [email, setEmail] = useState(null)
    const [emailStr, setEmailStr] = useState("")
    const [emailValidated, setEmailValidated] = useState(null)

    const emailForm = () =>{

        if(emailValidated === true){
            return (
                <div className='mx-auto max-w-5xl px-5 flex justify-center flex-col'>
                {/* form  start*/}
                 <form className='mt-10'>
                     <div>
                         <h2 className={emailValidated? noticeGreen : noticeRed}>
                             {emailStr}
                         </h2>
                         
                     </div>
                    
                 </form>
             </div>   
            )
        }
        else{
            return (
                <div className='mx-auto max-w-5xl px-5 flex justify-center flex-col'>
                {/* form  start*/}
                 <form className='mt-10'>
                     <div>
                         <h2 className='mb-5'>이메일을 입력해주세요.</h2>
                         <div className='flex'>
                             <input
                                 type="email"
                                 placeholder='chang-ggo@chang-ggo.com'
                                 className="text-black rounded-lg block w-full flex-1 rounded border-gray-300 focus:border-Cgcolor focus:ring-Cgcolor focus:ring-Cgcolor  mr-2"
                                 onChange={(e)=>{ setEmail(e.target.value)}}
                             />
                             <button
                             type="submit"
                             className="w-2/5 block rounded-lg  bg-Cgcolor py-3 px-3 font-bold text-white"
                             onClick={onClickBtn}
                             >
                                 비밀번호 찾기
                             </button>     
                         </div>
                         <h2 className={emailValidated? noticeGreen : noticeRed}>
                             {emailStr}
                         </h2>
                         
                     </div>
                    
                 </form>
             </div>   
            )
        }



    }
    
    
    const onClickBtn = (event) =>{

        event.preventDefault();
        event.stopPropagation();

        setEmailValidated(null)

        customAxios.post("/api/lostPassword",{
            email : email
        })
        .then((res)=>{
            if(res.data.state === 100){
                setEmailValidated(true)
                setEmailStr(email+"으로 \n 비밀번호 재설정 링크가 발송되었습니다.")
            }
            else{
                setEmailValidated(false)

                if(res.data.message ==="Not Found")
                    setEmailStr("유효하지 않은 회원입니다.")

                else
                    setEmailStr("이메일 발송에 실패하였습니다.")
            }
        })

    }

    return (
        <div className="max-w-lg mx-auto">
            <Header/>
            <section>
                <article className='bg-Maincolor1 text-white break-keep py-24'>
                  {emailForm()}
                </article>
            </section>
            <Footer/>  
        </div>
        
              
    );
}
  
  export default Lost;


