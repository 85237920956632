
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { customAxios } from "../Common/customAxios";
import { AdminEventCard } from "../Card/AdminEventCard";
import { DefaultPagination } from "../Pagination/DefaultPagination";
import { useNavigate } from "react-router-dom";

export const AdminEventList = () => {

    const [start, setStart] = useState(false)
    const loginState = useSelector((state) => state.login)

    const [eventListRefresh, setEventListRefresh] = useState(true)
    const [eventList, setEventList] = useState()
    const [active, setActive] = useState(1)
    
    const navigate = useNavigate()

    useEffect(() => {
        setStart(true)
    }, [])

    useEffect(() => {

        const getEventTable = async () => {
            if (start) {
                const result = await customAxios.get('/api/getEventTable')
                console.log(result.data)

                if (result.data.state === 101) {
                    setEventList(result.data.rows)
                    setEventListRefresh(false)
                }
            }
        }
       

        if (start) {
            if(eventListRefresh === true)
                getEventTable()
        }
    }, [start,eventListRefresh])

    const getDisplayTable = () => {

        let result = []

        let row = 4
        let col = 3
        let grid = row * col

        let pageMin = 1
        let pageMax = 1

        let selectTable = eventList

        if (selectTable && selectTable.length > 0) {
            //개수 딱 맞을때
            if (selectTable.length % (grid) === 0) {
                pageMax = parseInt(selectTable.length / (grid))
            }
            else {
                pageMax = parseInt(selectTable.length / (grid)) + 1
            }

            let dataStart = grid * (active - 1)
            let dataEnd = grid * (active)
            if (dataEnd >= selectTable.length)
                dataEnd = selectTable.length

            for (let i = dataStart; i < dataEnd; i++) {
                let data = selectTable[i]
                
                if(i===0){
                    // console.log(data)
                }

                result.push(<AdminEventCard 
                    key={"AdminEventCard" + i} 
                    cardData={data}
                    setEventListRefresh = {setEventListRefresh} 
                    />)
            }

        }
        else {
            result.push(<div className="col-span-4" key="noEvent">이벤트가 없습니다.</div>)
        }
        

        const outline = (
            <div key="outline" className="flex flex-col gap-4 items-center mt-10">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    {result}
                </div>
                <DefaultPagination
                    key="DefaultPagination"
                    active={active}
                    setActive={setActive}
                    min={pageMin}
                    max={pageMax}
                />
            </div>
        )

        return outline
    }

    const onClickAdd = (e) =>{
        navigate("/AdminEventWrite")

    }

    return (
        <div className='flex flex-col p-1'>
            <div className="flex justify-end">
                <button className="rounded bg-Cgcolor text-white" onClick={onClickAdd}>등록</button>
            </div>
            {getDisplayTable()}
        </div>
    )
}