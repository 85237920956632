import { useNavigate } from "react-router-dom";
import { pageCollection } from "../Page/PageCollection";
import { useEffect, useState } from "react";
import { Textarea, Select, Option, Button, IconButton } from "@material-tailwind/react";
import PaymentListDialog from "../Dialog/PaymentListDialog";
import { customAxios } from "../Common/customAxios";
import { useSelector } from "react-redux";
import { Inquiry_CategoryList, categoryList } from "../Common/dataStruct";
import dayjs from "dayjs";

const DisplayInquiry = (props) => {

    const loginState = useSelector((state) => state.login)

    const nomalButton = "block rounded-lg bg-gray-900 text-white py-3 px-3 text-center w-full lg:w-32";
    const clickedButton = "block rounded-lg bg-Cgcolor text-white py-3 px-3 text-center w-full lg:w-32";

    const [start, setStart] = useState(false)
    const [open, setOpen] = useState(false)
    const [context, setContext] = useState("")
    const [category, setCategory] = useState(0)

    const [myInquiry, setMyInquiry] = useState(null)
    const [selectPayment, setSelectPayment] = useState(null)
    const [selectInquiry, setSelectInquiry] = useState(null)

    const [openModal, setOpenModal] = useState(false)
    const navigate = useNavigate()

    const onClickInquiry = (data) =>{

        setSelectInquiry(data)
    }

    const onClickSaved = async() => {

        const getMyInquiry= async() =>{
            const result = await customAxios.get("/api/getMyInquiry")
            setMyInquiry(result.data.rows)
        }

        if(category ===0){
            alert("유형이 선택되지 않았습니다.")
            return
        }
        else if(category ===1 && selectPayment ===null){
            alert("주문내역이 선택되지 않았습니다.")
            return
        }
        let Index = null
        if(selectPayment){
            Index =selectPayment.Payment_Index
        }
        const result = await customAxios.post("/api/insertInquiry",{
            category:category,
            Payment_Index:Index,
            context:context,
        })
        if(result.data.state===100){
            getMyInquiry()
            alert("문의가 등록되었습니다.")
            setOpen(false)
        }

    }
    const onClickCanceled = () => {
        setOpen(false)
    }

    useEffect(()=>{
        setStart(true)
    },[])

    useEffect(() => {

        const getMyInquiry= async() =>{
            const result = await customAxios.get("/api/getMyInquiry")
            setMyInquiry(result.data.rows)
        }

        if (loginState.isLogin === false) {
            alert("로그인이 필요합니다.")
            navigate("/")
        }
        else if (loginState.isLogin === true) {
            if (start) {
                getMyInquiry()
            }
        }
    }, [start, loginState.isLogin])

    // useEffect(()=>{
    //     console.log(category)
    // },[category])

    const result = []

    let writeDisplay = null
    let selectPaymentDisplay = null


    if (category === Inquiry_CategoryList[0].value) {

        if(selectPayment === null){
            selectPaymentDisplay = (
                <Button className="pl-3 text-left font-normal text-black" color="gray" variant="outlined" onClick={(e) => { setOpenModal(true) }}>
                    주문 내역을 선택하세요.
                </Button>
            )
        }
        else if(selectPayment){
            selectPaymentDisplay = (
                <div className="flex justify-between rounded-lg border border-blue-gray-300 py-1 px-1">
                    <div className="inline-block p-2">
                        {selectPayment.Payment_Custom.products[0].Product_Name}
                    </div>
                    <IconButton color="gray" size="sm" variant="text" onClick={(e)=>{setSelectPayment(null)}} >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                            className="h-5 w-5"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </IconButton>
                </div>
            )
        }
    }

    

    if (open) {
        writeDisplay = (
            <div className="flex flex-col gap-2">
                <div className="text-sm">
                    문의 내용을 작성해주세요.
                </div>
                <div className="text-sm">
                    상담사가 확인 후, 답변 드리겠습니다.
                </div>
                <Select label="유형" 
                    onChange={(index) => { 
                    setCategory(index) 
                    setSelectPayment(null)}}>

                    <Option value="0" >유형을 선택해주세요</Option>
                    <Option value={Inquiry_CategoryList[0].value} >{Inquiry_CategoryList[0].label}</Option>
                    <Option value={Inquiry_CategoryList[1].value} >{Inquiry_CategoryList[1].label}</Option>
                </Select>

                {selectPaymentDisplay}

                <Textarea label="문의사항" rows={20} resize={true} onChange={(e) => { setContext(e.target.value) }} />
                <div className="flex flex-row gap-2">
                    <button className={clickedButton} onClick={onClickSaved}>저장</button>
                    <button className={nomalButton} onClick={onClickCanceled}>취소</button>
                </div>
            </div>
        )
    }
    else {
    let displaySelectInquiry = [];
    if(selectInquiry){
        displaySelectInquiry.push( <div className="flex flex-row" key={"select"+selectInquiry.Inquiry_Index}>hi</div>)
    }
    
        writeDisplay = (
            <div className="flex flex-col p-2">
               {displaySelectInquiry}
                <button className={nomalButton} onClick={(e) => (setOpen(true))}>문의하기</button>
            </div>
        )
    }

    let displayMyInquiry = [];
    if(myInquiry){

        myInquiry.forEach((element,idx) => {
            let categoryLabel = ""
            
            for(let i=0; i<Inquiry_CategoryList.length; i++){
                if(element.Inquiry_Category === Inquiry_CategoryList[i].value)
                categoryLabel = Inquiry_CategoryList[i].label
            }

            let displayProduct = (
                null
            )
            if(element.Payment_Index){
                displayProduct = (
                    <div className="text-sm" >상품</div>
                )
            }

            const normalClass = "flex flex-col hover:cursor-pointer hover:bg-gray-300 border border-gray-300 p-2"
            const selectClass = "flex flex-col bg-gray-300 hover:cursor-pointer hover:bg-gray-300 border border-gray-300 p-2"
            

            displayMyInquiry.push(
            <div className={selectInquiry===element? selectClass:normalClass} key={"myInquiry"+element.Inquiry_Index}
                onClick={(e)=>{onClickInquiry(element)}}>
                <div className="font-bold text-sm">{categoryLabel}</div>
                {displayProduct}
                <div className="text-xs">{dayjs(element.Inquiry_Date).format("YYYY-MM-DD")}</div>
            </div>
            )
        });

    
    }


    return (

        <div className='m-2 flex flex-row h-4/5 rounded-lg border-2'>
            <div className='basis-2/5  bg-gray-100'>
                <div className="p-2 text-base w-full ">
                    문의 내역 리스트
                </div>
                <div className="flex flex-col  ">
                {displayMyInquiry}
                    <div className="text-sm text-center mt-3">
                        최근 문의 내역을 모두 확인하셨습니다.
                    </div>
                </div>
            </div>
            <div className='basis-3/5  bg-gray-50'>
                <div className="flex flex-col gap-2 p-3">
                    {writeDisplay}
                </div>
            </div>
        <PaymentListDialog open={openModal} setOpen={setOpenModal} setSelectPayment={setSelectPayment}/>
        </div>

    )

}


export default DisplayInquiry