import { useSelector } from "react-redux";

function MyPrivacyButtons(props) {

    const nomalButton = "block rounded-lg bg-gray-900 text-white py-3 px-3 text-center w-full xl:w-32";
    const clickedButton = "block rounded-lg bg-Cgcolor text-white py-3 px-3 text-center w-full xl:w-32";
    
    const loginState = useSelector((state) => state.login)


    if(loginState.isLogin=== true){

        if(loginState.Member_TokenType !=="C"){
            return (
                <div className='basic text-black box-border py-5 px-5'>
                    <div className=' border-b pb-5 border-gray-500'>
                        <h1 className='font-bold mb-5'>개인정보 수정</h1>
                        <ul className='xl:flex xl:space-x-5 xl:space-y-0 space-y-5 '>
                            <li>
                                <button
                                    onClick={(e) => { props.setPrivacyModeState(5) }}
                                    className={props.privacyModeState === 5 ? clickedButton : nomalButton}>
                                    본인인증</button>
                            </li>
                            <li>
                                <button
                                    onClick={(e) => { props.setPrivacyModeState(2) }}
                                    className={props.privacyModeState === 2 ? clickedButton : nomalButton}>
                                    닉네임 변경</button>
                            </li>
                            <li>
                                <button
                                    className={props.privacyModeState === 4 ? clickedButton : nomalButton}
                                    onClick={(e) => { props.setPrivacyModeState(4) }}>
                                    회원 탈퇴</button>
                            </li>
                        </ul>
                    </div>
                </div>
            );

        }
        else{
            return (
                <div className='basic text-black box-border py-5 px-5'>
                    <div className=' border-b pb-5 border-gray-500'>
                        <h1 className='font-bold mb-5'>개인정보 수정</h1>
                        <ul className='xl:flex xl:space-x-3 xl:space-y-0 space-y-5 '>
                            <li>
                                <button
                                    onClick={(e) => { props.setPrivacyModeState(5) }}
                                    className={props.privacyModeState === 5 ? clickedButton : nomalButton}>
                                    본인 인증</button>
                            </li>
                            <li>
                                <button
                                    onClick={(e) => { props.setPrivacyModeState(1) }}
                                    className={props.privacyModeState === 1 ? clickedButton : nomalButton}>
                                    이메일 변경</button>
                            </li>
                            <li>
                                <button
                                    onClick={(e) => { props.setPrivacyModeState(2) }}
                                    className={props.privacyModeState === 2 ? clickedButton : nomalButton}>
                                    닉네임 변경</button>
                            </li>
                            <li>
                                <button
                                    className={props.privacyModeState === 3 ? clickedButton : nomalButton}
                                    onClick={(e) => { props.setPrivacyModeState(3) }}>
                                    비밀번호 변경</button>
                            </li>
                            <li>
                                <button
                                    className={props.privacyModeState === 4 ? clickedButton : nomalButton}
                                    onClick={(e) => { props.setPrivacyModeState(4) }}>
                                    회원 탈퇴</button>
                            </li>
                        </ul>
                    </div>
                </div>
            );
    
        }

       
    }
    
}

export default MyPrivacyButtons;

