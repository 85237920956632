
import Header from '../../Components/Fixed/Header';
import Footer from '../../Components/Fixed/Footer';

import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { customAxios } from '../../Components/Common/customAxios';


function ChangePassword() {

    

    const noticeGreen = "text-green-500  text-right mt-2 whitespace-pre-line";
    const noticeRed = "text-Cgcolor  text-right mt-2 whitespace-pre-line";

    const location = useLocation();
    const navigate = useNavigate();
    const [token, setToken] = useState(null)
    const [tokenCheck, setTokenCheck] = useState(null)

    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");
    const [passwordStr, setPasswordStr] = useState("");
    const [passwordValidated, setPasswordValidated] = useState();


    useEffect(() => {

        let param = new URLSearchParams(location.search)

        if (param.get('Token') === null) {
            setTokenCheck(false)
        }
        else {
            setToken(param.get('Token'))
        }

    }, [])

    useEffect(() => {

        const getTokenCheck = async() =>{
            if (token !== null) {
                const res = await customAxios.get("/api/checkChangePassword?Token=" + token)
                if (res.data.state === 100) {
                    setTokenCheck(true)
                }
                else {
                    setTokenCheck(false)
                }
            }
        }
        getTokenCheck()
    }, [token])

    useEffect(() => {
        if (password1.length < 8 || password2.length < 8) {
            setPasswordStr("비밀번호의 길이가 8자리 이상이여야 합니다.");
            setPasswordValidated(false);
        }
        else {

            if (password1 === password2) {
                setPasswordStr("두 비밀번호가 일치합니다.");
                setPasswordValidated(true);
            }
            else {
                setPasswordStr("두 비밀번호가 일치하지 않습니다.");
                setPasswordValidated(false);
            }
        }

    }, [password1, password2])


    const onCickedSubmit = (event) => {

        // console.log("onCickedSubmit")
        // console.log(passwordValidated)
        event.preventDefault();
        event.stopPropagation();

        if(passwordValidated){
            if (token !== null) {
                customAxios.post("/api/changePasswordFromToken", {
                    token: token,
                    password : password1
                })
                .then((res) => {
                    if(res.data.state === 100){
                        alert("비밀번호 변경이 완료 되었습니다. \n 로그인을 다시 진행해주세요.")
                        navigate("/Login",{replace:true})
                    }
                    else{
                        alert("비밀번호 변경에 실패하였습니다. \n 다시 시도해주세요")
                    }
                })
            }
        }
    }

        const getSection = () => {

            if (tokenCheck === true) {
                return (
                    <section>
                        <article className='bg-Maincolor1 text-white break-keep py-24'>
                            <div className='mx-auto max-w-5xl px-5 flex justify-center flex-col'>
                                {/* form  start*/}
                                <form className='mt-10' onSubmit={onCickedSubmit}>
                                    <div className='mt-5'>
                                        <h2 className='mb-2'><span className='text-Cgcolor'>*</span> 새로운 비밀번호를 입력해주세요.</h2>
                                        <div>
                                            <input
                                                required
                                                maxLength="64"
                                                type="password"
                                                name="formPassword1"
                                                id="formPassword1"
                                                className="text-black block w-full rounded border-gray-300 focus:border-Cgcolor focus:ring-Cgcolor focus:ring-Cgcolor sm:"
                                                onChange={(e)=>{ setPassword1(e.target.value)}}
                                            />
                                        </div>
                                    </div>
                                    <div className='mt-5'>
                                        <h2 className='mb-2'> <span className='text-Cgcolor'>*</span>새로운 비밀번호를 다시 입력해주세요.</h2>
                                        <div>
                                            <input
                                                required
                                                maxLength="64"
                                                type="password"
                                                name="formPassword2"
                                                id="formPassword2"
                                                className="text-black block w-full rounded border-gray-300 focus:border-Cgcolor focus:ring-Cgcolor focus:ring-Cgcolor sm:"
                                                onChange={(e)=>{ setPassword2(e.target.value)}}
                                            />
                                        </div>
                                    </div>
                                    <div className='mt-3'>
                                        <span className={passwordValidated ? noticeGreen : noticeRed}>
                                            {passwordStr}
                                        </span>
                                    </div>
                                    <button
                                        type="submit"
                                        className="w-2/5 mx-auto mt-10 block rounded-lg  bg-Cgcolor py-3 px-3  font-bold text-white"
                                    >
                                        비밀번호 변경
                                    </button>
                                </form>
                                {/* <div className=' mt-10 text-center'>
                            <div className='bg-white rounded-lg text-black box-border py-5 px-5 border border-gray-900 leading-6'>
                                <h3>회원님의 이메일은</h3>
                                <h4 className='text-Cgcolor font-bold'>창꼬@naver.com</h4>
                                <h5>입니다.</h5>
                            </div>
                        </div> */}

                                {/* form  end*/}
                            </div>
                        </article>
                    </section>
                )
            }
            else if (tokenCheck === false) {
                return (
                    <section>
                        <article className='bg-Maincolor1 text-white break-keep py-24'>
                            <div className='mx-auto max-w-5xl px-5 flex justify-center flex-col'>
                                <form className='mt-10'>
                                    <h2 className='mb-2 whitespace-pre-line'>
                                        {"올바르지 않은 경로로 접근하였거나 인증 메일이 만료되었습니다. \n 비밀번호 찾기를 다시 시도해주세요."}
                                    </h2>
                                </form>
                            </div>
                        </article>
                    </section>
                )
            }
            else if (tokenCheck === null) {
                return (
                    <section>
                        <article className='bg-Maincolor1 text-white break-keep py-24'>
                            <div className='mx-auto max-w-5xl px-5 flex justify-center flex-col'>
                                <form className='mt-10'>
                                    <h2 className='mb-2'>
                                        인증 진행 중 입니다.
                                    </h2>
                                </form>
                            </div>
                        </article>
                    </section>
                )
            }

        }



        return (
        <div className="max-w-lg mx-auto">
                <Header/>
                {getSection()}
                <Footer/>
            </div>


        );
    }

    export default ChangePassword;


