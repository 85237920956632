import { createSlice } from '@reduxjs/toolkit'

export const loginSlice = createSlice({
  name: 'login',
  initialState: {
    isLogin : null,
    Member_UID : null,
    Member_Email : null,
    Member_Nickname : null,
    Member_Auth : null,
    Member_TokenType : null,

  },

  reducers: {

    setLogin: (state, action) => {

      state.isLogin = true
      state.Member_UID = action.payload.Member_UID
      state.Member_Email = action.payload.Member_Email
      state.Member_Nickname = action.payload.Member_Nickname
      state.Member_Auth = action.payload.Member_Auth
      state.Member_TokenType = action.payload.Member_TokenType
    },
    
    setLogout: (state) => {
      state.isLogin = false
      state.Member_UID = null
      state.Member_Email = null
      state.Member_Nickname = null
      state.Member_Auth = null
      state.Member_TokenType = null
    },

  },
})

// Action creators are generated for each case reducer function
export const { setLogin,setLogout } = loginSlice.actions

export default loginSlice.reducer