import Header from '../../Components/Fixed/Header';
import Footer from '../../Components/Fixed/Footer';

import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';

import { customAxios, getDelivery, getProduct, insertPayment } from '../../Components/Common/customAxios';
import { Typography, Input } from "@material-tailwind/react";
import { requestPay, certification, payMethodArr } from '../../Components/Payment/PortOne';
import Address from '../../Components/Dialog/Address';
import ProductDisplay from '../../Components/Payment/ProductDisplay';

function PaymentDetailSpecial() {

    const [start, setStart] = useState(false)
    const loginState = useSelector((state) => state.login)
    const [searchParams, setSearchParams] = useSearchParams()
    const location = useLocation()
    const navigate = useNavigate()

    const [payMethod, setPayMethod] = useState(payMethodArr[0].value)

    const imp_uid = searchParams.get("imp_uid")
    const merchant_uid = searchParams.get("merchant_uid")
    const poMode = searchParams.get("poMode")
    const selectDeliveryIdx = Number(searchParams.get("selectDeliveryIdx"))

    const [userInfo, setUserInfo] = useState(null)
    const [delivery, setDelivery] = useState(null)

    const [email, setEmail] = useState(null)

    const [openAddress, setOpenAddress] = useState(false)

    const Product_Index = searchParams.get("Product_Index")
    const [product, setProduct] = useState(null)
    const [productQuantity, setProductQuantity] = useState(1)

    const [isButtonDisabled, setButtonDisabled] = useState(false);

    const addressOK = () => {
        setOpenAddress(false)
    }
    const addressCancel = () => {
        setOpenAddress(false)
    }

    const getProductAsync = async () => {
        const result = await customAxios("/api/getPersonalProduct?Product_Index=" + Product_Index)
        if (result.data.state === 101) {
            console.log(result.data.response)
            setProduct(result.data.response)
        }
        else {
            setProduct(false)
        }
    }

    const getUserInfoAsync = async () => {
        const result = await customAxios("/api/getUserInfo")
        if (result.data.state === 101) {
            setUserInfo(result.data.userInfo)
        }
        else {
            setUserInfo(false)
        }
    }
    const getDeliveryAsync = async () => {
        let result = await getDelivery()
        if (result.data.state === 101) {

            let index
            for (let i = 0; i < result.data.rows.length; i++) {
                if (result.data.rows[i].Delivery_Default === 1) {
                    index = i
                    break;
                }
            }
            setDelivery(result.data.rows)
            if (searchParams.get("selectDeliveryIdx") === null)
                setSelectDeliveryidx(index)
            else {

                if (selectDeliveryIdx >= result.data.rows.length)
                    setSelectDeliveryidx(index)
            }
        }
        else {
            setDelivery(false)
        }
    }
    const setSelectDeliveryidx = (index) => {
        searchParams.set("selectDeliveryIdx", index)
        setSearchParams(searchParams, { replace: true })
    }
    const updateDelivery = () => {
        getDeliveryAsync()
    }

    const syncSetUserInfo = async () => {
        if (poMode === "certification") {
            const success = searchParams.get("success")
            if (success === "true") {
                const result = await customAxios.post("/api/setUserInfo", {
                    imp_uid: imp_uid,
                    merchant_uid: merchant_uid,
                })

                if (result.data.state === 100) {
                    searchParams.delete("imp_uid")
                    searchParams.delete("merchant_uid")
                    searchParams.delete("poMode")
                    setSearchParams(searchParams, { replace: true })
                    getUserInfoAsync()
                }
            }
        }
    }

    const syncInsertPayment = async () => {
        if (poMode === "payment") {
            const result = await customAxios.post("/api/insertPaymentPersonal",{ imp_uid: imp_uid,merchant_uid: merchant_uid,})

            // console.log(result.data)
            if (result.data.state === 100) {//결제 성공 결제 페이지로 이동
                navigate("/MyPayment")
            }
            else { //결제 취소

                if (result.data.state === -103) {
                    if (!result.data.reason.includes("CANCELED"))
                        alert(`결제가 취소되었습니다.\n${result.data.reason}`)
                    getProductAsync()
                }

                searchParams.delete("imp_uid")
                searchParams.delete("merchant_uid")
                searchParams.delete("poMode")
                searchParams.delete("success")
                setSearchParams(searchParams, { replace: true })
            }
        }
    }

    useEffect(() => {
        setStart(true)
    }, [])

    useEffect(() => {

        if (start) {
            window.scrollTo(0, 0);

            if (loginState.isLogin === false) {
                alert("로그인이 필요합니다.")
                navigate("/")
            }
            else if (loginState.isLogin === true) {

                setEmail(loginState.Member_Email)
                getUserInfoAsync()
                getDeliveryAsync()

                syncSetUserInfo()
                syncInsertPayment()
                getProductAsync()
            }
        }

    }, [start, loginState.isLogin, imp_uid, navigate])


    if (loginState.isLogin === true) {

    }
    else {
        return (<CircularProgress />)
    }
    const displayBuyer = () => {
        let resultDisplayBuyer = []
        const classes = "p-4 border-b border-blue-gray-50"

        let resultUserInfo = []
        if (userInfo === null) {
            resultUserInfo.push(<CircularProgress key="resultUserInfo1" />)
        }
        else if (userInfo === false) {
            resultUserInfo.push(
                <div key="resultUserInfo2" className='flex flex-col w-full'>
                    <span>인증이 필요합니다.</span>
                    <button
                        onClick={onClickCertification}
                        className='w-36 bg-Cgcolor text-white text-sm font-bold rounded-lg py-3 px-4 inline-block '>
                        본인 인증
                    </button>
                </div>

            )
        }
        else if (userInfo) {

            let phoneStr = userInfo.phone.substr(0, 3) + "-" + userInfo.phone.substr(3, 4) + "-" + userInfo.phone.substr(7, 4)
            resultUserInfo.push(
                <table key="resultUserInfo3" className="mt-3 w-full table-auto text-left" >
                    <tbody>
                        <tr>
                            <td className={`${classes} bg-blue-gray-50/50 text-right w-28`}>
                                <Typography variant="small" color="blue-gray" className="font-normal">
                                    이름
                                </Typography>
                            </td>
                            <td className={classes}>
                                <Typography variant="small" color="blue-gray" className="font-normal">
                                    {userInfo.name}
                                </Typography>
                            </td>
                        </tr>
                        <tr>
                            <td className={`${classes} bg-blue-gray-50/50 text-right`}>
                                <Typography variant="small" color="blue-gray" className="font-normal">
                                    휴대폰 번호
                                </Typography>
                            </td>
                            <td className={classes}>
                                <Typography variant="small" color="blue-gray" className="font-normal">
                                    {phoneStr}
                                </Typography>
                            </td>
                        </tr>
                        <tr>
                            <td className={`${classes} bg-blue-gray-50/50 text-right`}>
                                <Typography variant="small" color="blue-gray" className="font-normal">
                                    이메일
                                </Typography>
                            </td>
                            <td className={classes}>
                                <Input
                                    variant="static"
                                    label={""}
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value)
                                    }} />
                            </td>
                        </tr>
                        {/* <tr>
                            <td className={`${classes} bg-blue-gray-50/50 text-right`}>
                                <Typography variant="small" color="blue-gray" className="font-normal">
                                    구매자 주소
                                </Typography>
                            </td>
                            <td className={classes}>
                          
                            </td>
                        </tr> */}
                    </tbody>
                </table>
            )
        }

        resultDisplayBuyer.push(
            <h2 key="resultDisplayBuyer" className='text-xs sm:leading-normal font-bold leading-normal w-full my-5 border-t pt-5 border-box border-b pb-5'>
                <span className='block'>구매자 정보</span>
                {resultUserInfo}
            </h2>
        )
        return resultDisplayBuyer
    }
    const displayDelivery = () => {
        let resultDisplayDelivery = []
        const classes = "p-4 border-b border-blue-gray-50"

        let resultDilivery = []

        if (delivery === null) {
            resultDilivery.push(<CircularProgress key="resultDilivery1" />)
        }
        else if (delivery === false) {
            resultDilivery.push(
                <div key="resultDilivery2" className='border-box p-5 border rounded mt-5'>
                    <Typography variant="small" color="blue-gray" className="font-normal mt-3">
                        배송지 정보가 없습니다.
                    </Typography>
                    <button onClick={(e) => { setOpenAddress(true) }}
                        className='bg-Cgcolor text-white text-sm font-bold rounded-lg py-3 px-4 inline-block mt-2'>
                        추가하기
                    </button>
                </div>
            )
        }
        else if (delivery) {

            const selectDelivery = delivery[selectDeliveryIdx]

            resultDilivery.push(
                <div key="resultDilivery3">
                    <table className="mt-3 w-full table-auto text-left">
                        <tbody>
                            <tr>
                                <td className={`${classes} bg-blue-gray-50/50 text-right w-36`}>
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        이름
                                    </Typography>
                                </td>
                                <td className={classes}>
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        {selectDelivery.Delivery_PostName}
                                    </Typography>
                                </td>
                            </tr>
                            <tr>
                                <td className={`${classes} bg-blue-gray-50/50 text-right`}>
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        연락처
                                    </Typography>
                                </td>
                                <td className={classes}>
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        {selectDelivery.Delivery_Number}
                                    </Typography>
                                </td>
                            </tr>
                            <tr>
                                <td className={`${classes} bg-blue-gray-50/50 text-right`}>
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        배송 주소
                                    </Typography>
                                </td>
                                <td className={`${classes} flex items-center gap-2`}>
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        {selectDelivery.Delivery_Address},
                                    </Typography>
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        {selectDelivery.Delivery_Detail}
                                    </Typography>
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        [{selectDelivery.Delivery_Postcode}]
                                    </Typography>
                                </td>
                            </tr>
                            <tr>
                                <td className={`${classes} bg-blue-gray-50/50 text-right`}>
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        배송 도로명 주소
                                    </Typography>
                                </td>
                                <td className={`${classes} flex items-center gap-2`}>
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        {selectDelivery.Delivery_RoadAddress},
                                    </Typography>
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        {selectDelivery.Delivery_Detail}
                                    </Typography>
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        [{selectDelivery.Delivery_Postcode}]
                                    </Typography>
                                </td>
                            </tr>
                            <tr>
                                <td className={`${classes} bg-blue-gray-50/50 text-right`}>
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        배송 요청사항
                                    </Typography>
                                </td>
                                <td className={classes}>
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        {selectDelivery.Delivery_Requests}
                                    </Typography>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )
        }

        resultDisplayDelivery.push(
            <h2 key="resultDisplayDelivery" className='text-xs sm:leading-normal font-bold leading-normal w-full my-5 border-box border-b pb-5'>
                <div className=''>
                    <span className='block'>배송지 정보
                        {
                            delivery ? <button onClick={(e) => { setOpenAddress(true) }}
                                className='bg-Cgcolor text-white text-sm font-bold rounded-lg inline-block ml-2'>
                                변경하기
                            </button> : null
                        }
                    </span>
                    {resultDilivery}
                </div>
            </h2>
        )
        return resultDisplayDelivery
    }

    const displayRadio = () => {
        let result = []
        payMethodArr.forEach((element, idx) => {
            result.push(
                <div className='flex mr-5' key={"displayRadioDiv" + idx}>
                    <input
                        className='mr-2'
                        type="radio"
                        id={"radio" + idx}
                        value={element.value}
                        checked={payMethod === element.value}
                        onChange={(e) => { setPayMethod(e.target.value) }}
                    />
                    <label id={"radio" + idx} htmlFor={"radio" + idx}>
                        {element.label}
                    </label>
                </div>

            )

        })
        return result
    }


    const onClickPayment = () => {

          // 버튼 비활성화
        setButtonDisabled(true);

        if (userInfo === null || userInfo === false) {
            alert("본인 인증이 완료되지 않았습니다.")
            return
        }
        if (selectDeliveryIdx === null) {
            alert("배송지가 선택되지 않았습니다.")
            return
        }

        if (product === null) {
            return
        }
        const selectDelivery = delivery[selectDeliveryIdx]

        if (selectDelivery === undefined) {
            alert("배송지가 선택되지 않았습니다.")
            return
        }

        if (product.Product_Quantity === 0) {
            alert("매진입니다.")
            return
        }

        const url = window.location.origin + location.pathname + location.search + "&poMode=payment"


        const products = [
            { ...product, selectProductQuantity: productQuantity },
        ]


        const productData = {
            pay_method: payMethod,                                 // 결제수단
            merchant_uid: `mid_${Product_Index}`,   // 주문번호
            amount: product.Product_Price * productQuantity,                                 // 결제금액
            buyer_name: userInfo.name,                           // 구매자 이름
            buyer_tel: userInfo.phone,                     // 구매자 전화번호
            buyer_email: email,               // 구매자 이메일
            buyer_addr: '',                    // 구매자 주소
            buyer_postcode: '',                      // 구매자 우편번호
            m_redirect_url: url, // 모바일 환경에서 필수 입력
            name: product.Product_Name,                           // 주문명
            custom_data: {
                ...selectDelivery,
                products: products,
            }
        }
        requestPay(productData, paymentCallback)

        // 3초 후에 버튼 활성화
        setTimeout(() => {
            setButtonDisabled(false);
        }, 3000);
    }
    const paymentCallback = (response) => {

        // console.log(response)

        searchParams.set("imp_uid", response.imp_uid)
        searchParams.set("merchant_uid", response.merchant_uid)
        searchParams.set("poMode", "payment")
        setSearchParams(searchParams)
    }

    const onClickCertification = () => {
        const url = window.location.origin + location.pathname + location.search + "&poMode=certification"
        let certificationData = {
            m_redirect_url: url
        }
        certification(certificationData, certificationCallback)
    }
    const certificationCallback = async (res) => {

        searchParams.set("imp_uid", res.imp_uid)
        searchParams.set("merchant_uid", res.merchant_uid)
        searchParams.set("poMode", "certification")
        searchParams.set("success", res.success)

        setSearchParams(searchParams)
    }
    const getbuyBtn = () =>{

        if(product){
            if(product.Product_Quantity > 0){
                return (
                    <button
                    onClick={onClickPayment}
                    disabled={isButtonDisabled}
                    className='bg-Cgcolor text-white text-sm font-bold rounded-lg py-3 px-4 inline-block '>
                    구매하기
                </button>
                )
            }
        }
        return (null)        
    }

    return (
        <div className='break-keep'>
            <Header />
            <div>
                <section className='sm:flex mx-auto max-w-7xl px-5 py-32'>
                    <article className='w-full'>
                        <div>
                            <h1 className='text-2xl sm:leading-normal font-bold leading-normal w-full flex items-end'>
                                <span className='block'>주문 결제</span>
                            </h1>
                            <div className='' key="displayBuyer">
                                {displayBuyer()}
                            </div>
                            <div className=''>
                                {displayDelivery()}
                            </div>
                            <h2 className='text-xs sm:leading-normal font-bold leading-normal w-full my-5 '>
                                <span className='block'>결제 정보</span>

                            </h2>
                            <div className='w-full'>
                                <ProductDisplay
                                    Product_Index={Product_Index}
                                    product={product}
                                    productCount={productQuantity}
                                    setProductCount={setProductQuantity}
                                />
                            </div>


                            <div className='w-full '>
                                <div className='flex w-full items-center text-center border-box border rounded p-5 my-5'>
                                    {displayRadio()}
                                </div>
                                {
                                    getbuyBtn()
                                }


                            </div>
                            <div className='mt-10 border-box rounded border p-5'>
                                <h1 className='mb-3 font-bold'>
                                    배송 기간
                                </h1>
                                <ul>
                                    <li>평균 배송기간 3일 ~5일 소요됩니다.</li>
                                </ul>
                            </div>
                            <div className='mt-5 border-box rounded border p-5'>
                                <h1 className='mb-3 font-bold'>
                                    환불/교환
                                </h1>
                                <ul>
                                    {/* <li>판매 기간 내에만 주문 취소/환불이 가능합니다.</li> */}
                                    <li>주문 제작 방식으로 단순변심으로 인한 교환/환불이 불가합니다.</li>
                                    <li>수령하신 상품이 오배송되었거나, 불량인 경우에는 7일 이내 연락주시면 교환 또는 반품 및 환불이 가능합니다.</li>
                                    <li>오배송, 불량인 경우는 카카오채널 창꼬(<a href='https://pf.kakao.com/_QxbxmRG' target='_blank'>https://pf.kakao.com/_QxbxmRG</a>)로 문의해주세요.</li>
                                </ul>
                            </div>
                        </div>
                    </article>
                </section>
            </div>
            <Footer />
            <Address
                open={openAddress}
                DialogOK={addressOK}
                DialogCancel={addressCancel}
                delivery={delivery}
                setSelectDeliveryidx={setSelectDeliveryidx}
                updateDelivery={updateDelivery}
            />

        </div>

    );
}

export default PaymentDetailSpecial;

