import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom"
import { customAxios } from "../../Components/Common/customAxios";
import { useSelector } from "react-redux";


const DeleteKakao = ()=>{

    const loginState = useSelector((state) => state.login)

    const navigate = useNavigate()
    const [start, setStart] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const code=searchParams.get('code')

    useEffect(()=>{
        setStart(true)
    },[])

    useEffect(()=>{
        const getToken = async() =>{
            const res = await customAxios.post("https://kauth.kakao.com/oauth/token",{
                grant_type : "authorization_code",
                client_id : process.env.REACT_APP_REST_API_KAKAO,
                redirect_uri : process.env.REACT_APP_DELETE_KAKAO,
                code : code},{
                headers : {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },}).catch((err)=>{
                    // console.log(err)
                })

                if(res !==undefined){
                    const result = await customAxios.post("/api/deleteKakaoMember",{kakaoData : res.data})

                    if(result.data.state===100){
                        navigate("/logout",{replace:true})
                    }
                }
                
        }
        if(start){
            if(code!==null && loginState.isLogin ===true){
                getToken()
            }
        }
    },[start,code,loginState.isLogin])

}

export default DeleteKakao