import AdminAuthCheck from "../../Components/Admin/AdminAuthCheck";
import AdminCategoryList from "../../Components/Admin/AdminCategoryList";
import AdminHeader from "../../Components/Fixed/AdminHeader";

function AdminCategoryPage() {

    return (
        <div className="h-[calc(100vh-2rem)] flex flex-row ">
            <AdminAuthCheck auth="M3"/>
            <AdminHeader mode={1} />
            <AdminCategoryList/>
        </div>

    );

}

export default AdminCategoryPage;


