import DisplayDelivery from '../../Components/Delivery/DisplayDelivery';
import { CircularProgress } from '@mui/material';
import dayjs from 'dayjs';
import { deliveryStateStruct } from '../Common/dataStruct';
import PaymentDialog from '../Dialog/PaymentDialog';
import { useState } from 'react';
import { customAxios } from '../Common/customAxios';
import { useNavigate } from 'react-router-dom';

const PaymentDisplay = (props) => {
    
    const navigate = useNavigate()

    const onClickInquiry = async(e) =>{
        const idx = e.target.value

        navigate("/MyInquiry")
        
        // console.log(props.myPayment[idx])
        // setContents(props.myPayment[idx])

        // setOpen(true)
    }

    const onClickRefund = async(e) =>{
        if(window.confirm("환불을 진행하시겠습니까?")){

            const idx = e.target.value
            // console.log(props.myPayment[idx])
            // console.log(props.myPayment[idx].Payment_Index)
            const result = await customAxios.post("/api/refundPayment",{
                Payment_Index: props.myPayment[idx].Payment_Index
            })

            if(result.data.state===100){
                await props.GetMyPayment()
            }
        }

    }

    const onYesNoDialogYes = ()=>{
        // setOpen(false)
    }
    const onYesNoDialogNo = () =>{
        // setOpen(false)
    }

    const renderTrackingNumber = (TrackingNumber, Cop) =>{
        if(Cop ==="hanjin"){
            return <a href={"https://www.hanjin.com/kor/CMS/DeliveryMgr/WaybillResult.do?mCode=MN038&schLang=KR&wblnumText2="+TrackingNumber}>{TrackingNumber} </a>
        }
    }


    const renderDeliveryCop = (Cop) =>{
        if(Cop ==="hanjin"){
            return "한진"
        }
    }

    let itemDisplay
    if (props.myPayment === null) {
        itemDisplay = (<CircularProgress key="CircularProgress" />)
    }
    else if (props.myPayment) {

        // console.log(props.myPayment)

        itemDisplay = []
        props.myPayment.forEach((element, idx) => {

            let deliveryStateLabel;
            deliveryStateStruct.forEach((state)=>{

                if(element.Payment_DeliveryState ===state.value){
                    deliveryStateLabel=state.label
                }
                
            })

            const mDay = dayjs(element.Payment_Date)
            itemDisplay.push(
                <div key={"itemDisplay" + idx}>
                    <div className='text-base font-bold ml-2 mt-2'>
                        {mDay.format("YYYY.MM.DD")} 주문
                        {/* {mDay.format("YYYY-MM-DD HH:mm:ss")} 주문 */}
                    </div>
                    <div className='border m-2'>
                        <div className='flex flex-col gap-1 m-3 basis-3/4'>
                            <div className='text-lg'>
                                {deliveryStateLabel}
                            </div>
                            <div className=''>
                                주문 번호 {element.Payment_Index}
                            </div>
                            <div className=''>
                                상품 이름 : {element.Payment_Custom.products[0].Product_Name}
                            </div>
                            <div className=''>
                                상품 수량 : {element.Payment_Custom.products[0].selectProductQuantity}
                            </div>
                            <div className=''>
                                결제 금액 : {Intl.NumberFormat().format(element.Payment_Buyer.amount)}
                            </div>
                            {
                                element.Payment_TrackingNumber === null? 
                                null:
                                    <div className='flex flex-col'>
                                        <div>
                                            송장번호 : {renderTrackingNumber(element.Payment_TrackingNumber,element.Payment_DeliveryCop)}
                                        </div>
                                        <div>
                                            배송회사 : {renderDeliveryCop(element.Payment_DeliveryCop)}
                                        </div>
                                    </div>
                            }
                            <div className='border-box border p-3 rounded mt-3'>
                                <DisplayDelivery delivery={element.Payment_Custom} />
                            </div>
                        </div>
                        <div className='flex flex-col gap-1 mb-3 px-3 basis-1/4 justify-end'>
                            {/* <button className='inline-block box-border rounded-lg bg-Cgcolor px-2 py-2 text-white font-bold text-center'
                                value={idx}
                            onClick={onClickInquiry}>
                                문의하기
                            </button> */}
                            {element.Payment_DeliveryState === "paid" ?
                                <button className='inline-block box-border rounded-lg bg-Cgcolor px-2 py-2 text-white font-bold text-center'
                                    value={idx}
                                    onClick={onClickRefund}>
                                    환불하기
                                </button> :
                                null}
                        </div>
                    </div>

                </div>
            )
        });
    }

    return (
        <div className='flex flex-col gap-3 text-black mt-5'>
            {itemDisplay}
            {/* <PaymentDialog
                yesNoDialogOpen={open}
                setOpen={setOpen}
                onYesNoDialogYes={onYesNoDialogYes}
                onYesNoDialogNo={onYesNoDialogNo}
                contents={contents}
            /> */}
        </div>
    )

}

export default PaymentDisplay