import AdminAuthCheck from "../../Components/Admin/AdminAuthCheck";
import AdminHeader from "../../Components/Fixed/AdminHeader";

function AdminHome() {

    return (
        
        <div className="h-full flex flex-row ">
            <AdminAuthCheck auth="M1"/>
            <AdminHeader mode={0}/>
            <div className="p-1">home</div>
        </div>

    );

}

export default AdminHome;


