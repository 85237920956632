
import CircularProgress from '@mui/material/CircularProgress';

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { customAxios } from "../../Components/Common/customAxios";
import dayjs from "dayjs";
import logo from "../../Images/Layout/logo.png"
import EventChip from "../../Components/Chip/EventChip";
import { Chip } from "@material-tailwind/react";

import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
    Button,
} from "@material-tailwind/react";


function EventDetailCard() {

    const [start, setStart] = useState(false)

    const loginState = useSelector((state) => state.login)
    const [searchParams, setSearchParams] = useSearchParams()
    const [eventData, setEventData] = useState(null)
    const currentPage = searchParams.get('page')
    
    const [prev, setPrev] = useState("")
    const [next, setNext] = useState("")
    const [pos, setPos] = useState("")

    const navigate = useNavigate()

    useEffect(() => {
        setStart(true)
    }, [])

    useEffect(() => {
        const getEventData = async () => {
            const res = await customAxios.get('/api/getEventData' + "?page=" + currentPage)

            if(res.data.state === 101){
                setEventData(res.data.response.current)
                setPrev(res.data.response.prev)
                setNext(res.data.response.next)

                // console.log(res.data.response.current)

                // console.log("next: "+res.data.response.next)
                // console.log("prev: "+ res.data.response.prev)
                // console.log("position: "+ Number(res.data.response.position))
                // console.log("length: "+ Number(res.data.response.length))
                
                setPos(res.data.response.position)
                // const active =  Math.floor(parseInt(res.data.response.position-1)/12) +1
                // console.log(active)

            }
            else{
                console.log(res.data.message)
            }
        }

        if (start) {
            if (currentPage === null) {
            }
            else {
                getEventData()
                window.scrollTo({top:0,behavior:'auto'})

            }
        }
    }, [start,currentPage])

    useEffect(() => {
        // console.log(loginState.isLogin)
    }, [loginState.isLogin])

    const getDisplayEventDetail = () => {

        let result = []
        if (eventData) {
            const RDay = dayjs(eventData.Event_RegistrationDate)

            let cardImage
            const Event_ThumbnailURL = JSON.parse(eventData.Event_ThumbnailURL)

            if (Event_ThumbnailURL.length === 0) {
                cardImage = <img key={"img0"} className="object-contain" src={"logo_new.png"} alt="card-image" />
            }
            else {
                cardImage = (
                    <div className="flex flex-col justify-center items-center">
                        {
                            Event_ThumbnailURL.map((url, index) => {
                                return (<img key={"img" + index} className="object-contain" src={url} alt="card-image" />)
                            })}
                    </div>
                )
            }

            result.push(
                <div key="Detail" className="flex break-all w-full max-w-7xl mt-4 mx-1 sd:mx-14  ">
                    <Card className="flex w-full " key="Card">
                        <CardBody className="flex flex-col items-center gap-4 py-4 mb-5">
                            <p className='font-bold text-lg sm:text-2xl whitespace-pre-wrap'>{eventData.Event_Title}</p>
                            <div className='flex w-full justify-end items-end'>
                                <p className=''>{RDay.format("YYYY-MM-DD")}</p>
                            </div>

                            <p className='text-base sm:text-xl whitespace-pre-wrap'>{eventData.Event_Abstract}</p>
                            <div className="flex">{cardImage}</div>
                            <p className='text-base whitespace-pre-wrap'>{eventData.Event_Context}</p>
                            <a href={eventData.Event_URL} target='_blank' className='text-base whitespace-pre-wrap  text-blue-600 dark:text-blue-500 hover:underline'>{eventData.Event_URL}</a>
                            </CardBody>
                    </Card>
                </div>
            )
        }
        else {
            result.push(<CircularProgress key="circle" />)
        }

        return result
    }
    const onclickPrev = (e) =>{
        // navigate("/EventDetail?page="+prev)

        searchParams.set("page", prev)
        setSearchParams(searchParams, { replace: true })
        // console.log("/EventDetail?page="+prev)
        // window.scrollTo({top:0,behavior:'smooth'})

    }
    const onclickNext = (e) =>{
        // navigate("/EventDetail?page="+next)

        searchParams.set("page", next)
        setSearchParams(searchParams, { replace: true })
        // console.log("/EventDetail?page="+next)
    }

    const onclickList = (e)=>{
        
        const active =  Math.floor((parseInt(pos)-1)/12) +1
        // console.log(active)
        navigate("/EventBoard?active="+active)
    }

    return (
        <section className='flex flex-col items-center w-full'>
            {getDisplayEventDetail()}
            <div className='flex w-full justify-between'>
                <div className='flex p-4 sm:p-10 py-10 gap-2'>
                    <button disabled={prev ===null? true : false} className=' bg-Cgcolor text-white text-sm font-bold rounded-lg py-3 px-4 inline-block disabled:bg-gray-700' onClick={onclickPrev}>이전</button>
                    <button disabled={next ===null? true : false} className=' bg-Cgcolor text-white text-sm font-bold rounded-lg py-3 px-4 inline-block disabled:bg-gray-700' onClick={onclickNext}>다음</button>
                </div>
                <div className='flex p-10 py-10 gap-2'>
                <button className=' bg-Cgcolor text-white text-sm font-bold rounded-lg py-3 px-4 inline-block' onClick={onclickList}>목록</button>
                </div>
            </div>
        </section>
    )
}

export default EventDetailCard;
