import AdminAuthCheck from "../../Components/Admin/AdminAuthCheck";
import AdminPaymentList from "../../Components/Admin/AdminPaymentList";
import AdminHeader from "../../Components/Fixed/AdminHeader";

const AdminPayment = () => {

    return (
        <div className="flex flex-row ">
            <AdminHeader mode={1}/>
            <div className="p-1">
                <AdminPaymentList/>
            </div>
            <AdminAuthCheck auth="M3"/>

        </div>
    )
}

export default AdminPayment