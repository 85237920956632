import { Card, CardHeader, CardBody, CardFooter, Typography, Chip, Button, } from "@material-tailwind/react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import logo from "../../Images/Layout/logo.png"

export function EventCard(props) {

  const navigate = useNavigate()


  const onClickCard = async (e)=>{
    navigate("/EventDetail?page=" + props.cardData.Event_UID) 
  }


  const Rday = dayjs(props.cardData.Event_RegistrationDate)
  let cardImage
  const Event_ThumbnailURL = JSON.parse(props.cardData.Event_ThumbnailURL)

  if(Event_ThumbnailURL.length===0){
    cardImage = <img className="w-full object-contain h-64" src={"logo_new.png"} alt="card-image" />
  }
  else{
    cardImage = <img className="w-full object-contain h-64" src={Event_ThumbnailURL[0]} alt="card-image" />
  }

  let abstract = props.cardData.Event_Abstract 
  
  if(abstract.length === 0)
    abstract = props.cardData.Event_Context

  return (
     <Card className="flex justify-center">
      <CardBody className="w-full hover:cursor-pointer " onClick={onClickCard} key={props.cardData.Event_UID}>
        <div className="flex flex-row w-full gap-4 ">
          <div className="flex w-2/5"> {cardImage}</div>
          <div className="flex flex-none flex-col w-3/5 p-1 gap-1 ">
            <p className="whitespace-pre-wrap overflow-hidden ">
              {props.cardData.Event_Title}
            </p>

            <div className="flex grow items-center">
              <p className="abstract">
                {abstract}
              </p>
            </div>

            {/* <p className="whitespace-pre-wrap overflow-hidden">
              {props.cardData.Event_Abstract}
            </p> */}
            
            <div className="flex flex-col justify-end">
              <p className="text-right whitespace-pre-wrap overflow-hidden">
                {Rday.format("YYYY-MM-DD")}
              </p>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
}