import Header from '../../Components/Fixed/Header';
import Footer from '../../Components/Fixed/Footer';

import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';

import StoreHeader from '../../Components/Fixed/StoreHeader';
import StoreList from '../../Components/Store/StoreList';

function Store() {

    const [start, setStart] = useState(false)
    const [load, setLoad] = useState(false)
    const loginState = useSelector((state) => state.login)
    const navigate = useNavigate()

    useEffect(() => {
        setStart(true)
    }, [])

    useEffect(() => {
        if (start) {
            setLoad(true)
            // if (loginState.isLogin === false ) {
            //     alert("로그인이 필요합니다.")
            //     navigate("/")
            // }
            // else if (loginState.isLogin === true ) {
            //     const today = dayjs(new Date())
            //     const taget = dayjs("2024-01-01 17:59:59")
            //     if(taget.isAfter(today)){
            //         alert("18시 이후 사용 가능합니다.")
            //         navigate("/")
            //     }
            //     else{
            //         setLoad(true)
            //     }
            // }
        }

    }, [start, loginState.isLogin, navigate])

    if (load) {

    }
    else {
        return (<CircularProgress />)
    }

    return (
        <div className="max-w-lg mx-auto">
            <Header />
            <StoreHeader />
            <StoreList />
            <Footer />
        </div>

    );
}

export default Store;

