import { Typography } from "@material-tailwind/react"
import { CircularProgress } from "@mui/material"
import { useEffect, useState } from "react"
import { customAxios, getUserInfo } from "../Common/customAxios"
import { certification } from "../Payment/PortOne"
import { useLocation, useSearchParams } from "react-router-dom"
import dayjs from "dayjs"

const MyCertification = () => {

    const [start, setStart] = useState()
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams()
    const poMode = searchParams.get("poMode")
    const imp_uid = searchParams.get("imp_uid")
    const merchant_uid = searchParams.get("merchant_uid")

    const [userInfo, setUserInfo] = useState(null)

    useEffect(() => {
        setStart(true)
    }, [])

    useEffect(() => {

        const getUserInfoAsync = async () => {
            const result = await customAxios("/api/getUserInfo")
            if (result.data.state === 101) {
                setUserInfo(result.data.userInfo)
            }
            else {
                setUserInfo(false)
            }
        }

        const syncSetUserInfo = async () => {
            if (poMode === "certification") {
                const success = searchParams.get("success")
                if (success === "true") {
                    const result = await customAxios.post("/api/setUserInfo", {
                        imp_uid: imp_uid,
                        merchant_uid: merchant_uid,
                    })

                    if (result.data.state === 100) {
                        searchParams.delete("imp_uid")
                        searchParams.delete("merchant_uid")
                        searchParams.delete("poMode")
                        setSearchParams(searchParams, { replace: true })
                        getUserInfoAsync()
                    }
                }
            }
        }


        if (start) {
            syncSetUserInfo()
            getUserInfoAsync()
        }

    }, [start, poMode, imp_uid, merchant_uid])


    let certificationDisplay = []
    const classes = "p-4 border-b border-blue-gray-50"

    const onClickCertification = () => {

        const today = dayjs(new Date())
        const certifiedDay =dayjs(new Date(userInfo.certified_at*1000))
        const dif = today.diff(certifiedDay,"day")
        
        if(dif<7){
            alert("본인인증 변경은 7일에 한번 가능합니다.")
            return
        }

        let url = window.location.origin + location.pathname + location.search

        if(location.search ===""){
            url += "?poMode=certification" 
        }
        else{
            url += "&poMode=certification" 
        }
        console.log(url)


        let certificationData = {
            m_redirect_url: url
        }
        certification(certificationData, certificationCallback)
    }
    const certificationCallback = async (res) => {

        searchParams.set("imp_uid", res.imp_uid)
        searchParams.set("merchant_uid", res.merchant_uid)
        searchParams.set("poMode", "certification")
        searchParams.set("success", res.success)

        setSearchParams(searchParams)
    }

    let resultUserInfo = []
    if (userInfo === null) {
        resultUserInfo.push(<CircularProgress key="resultUserInfo1" />)
    }
    else if (userInfo === false) {
        resultUserInfo.push(
            <div key="resultUserInfo2" className='flex flex-col w-full'>
                <span>인증이 필요합니다.</span>
                <button
                    onClick={onClickCertification}
                    className='w-36 bg-Cgcolor text-white text-sm font-bold rounded-lg py-3 px-4 inline-block '>
                    본인 인증
                </button>
            </div>

        )
    }
    else if (userInfo) {

        let phoneStr = userInfo.phone.substr(0, 3) + "-" + userInfo.phone.substr(3, 4) + "-" + userInfo.phone.substr(7, 4)
        resultUserInfo.push(
            <div key="resultUserInfo">
                <table key="resultUserInfo3" className="mt-3 w-full table-auto text-left" >
                    <tbody>
                        <tr>
                            <td className={`${classes} bg-blue-gray-50/50 text-right w-28`}>
                                <Typography variant="small" color="blue-gray" className="font-normal">
                                    이름
                                </Typography>
                            </td>
                            <td className={classes}>
                                <Typography variant="small" color="blue-gray" className="font-normal">
                                    {userInfo.name}
                                </Typography>
                            </td>
                        </tr>
                        <tr>
                            <td className={`${classes} bg-blue-gray-50/50 text-right`}>
                                <Typography variant="small" color="blue-gray" className="font-normal">
                                    휴대폰 번호
                                </Typography>
                            </td>
                            <td className={classes}>
                                <Typography variant="small" color="blue-gray" className="font-normal">
                                    {phoneStr}
                                </Typography>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <button onClick={onClickCertification}
                    className='mt-3 w-36 bg-Cgcolor text-white text-sm font-bold rounded-lg py-3 px-4 inline-block '>
                    변경하기
                </button>
            </div>

        )
    }

    certificationDisplay.push(
        <div key="MyLeave" className='basic text-black box-border py-5 px-5'>
            <div>
                <h1 className='font-bold mb-5'>본인 인증</h1>
                <div>
                    {resultUserInfo}
                </div>
            </div>
        </div>

    )
    //     </h2>
    return certificationDisplay
}

export default MyCertification