import AdminHeader from "../../Components/Fixed/AdminHeader";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AdminPaymentLostForm from "../../Components/Admin/AdminPaymentLostForm";

function AdminPaymentLost() {

    const [start, setStart] = useState()
    const loginState = useSelector((state) => state.login)
    const navigate = useNavigate()
    const [authCheck, setAuthCheck] = useState(false)

    useEffect(()=>{
        setStart(true)
    },[])

    useEffect(() => {

        if(start){
            if (loginState.isLogin === false) {
                alert("로그인이 필요합니다.")
                navigate("/",{replace:true})
            }
            else if (loginState.isLogin === true) {
                if (loginState.Member_Auth === "U") {
                    alert("권한이 없습니다.")
                    navigate("/",{replace:true})
                }
                setAuthCheck(true)
            }
        }
    }, [start, loginState.isLogin])

    if(!authCheck){
        return
    }

    return (
        <div className="h-[calc(100vh-2rem)] flex flex-row ">
            <AdminHeader mode={0}/>
            <AdminPaymentLostForm/>
        </div>

    );

}

export default AdminPaymentLost;


