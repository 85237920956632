import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom"
import { customAxios } from "../../Components/Common/customAxios";
import { useSelector } from "react-redux";


const DeleteGoogle = ()=>{

    const loginState = useSelector((state) => state.login)

    const navigate = useNavigate()
    const [start, setStart] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const code=searchParams.get('code')

    useEffect(()=>{
        setStart(true)
    },[])

    useEffect(()=>{
        const deleteGoogle = async() =>{

            const res = await customAxios.post("https://oauth2.googleapis.com/token", {
                code: code,
                client_id: process.env.REACT_APP_GOOGLE_ID,
                client_secret: process.env.REACT_APP_GOOGLE_SECRET,
                redirect_uri: process.env.REACT_APP_DELETE_GOOGLE,
                grant_type: 'authorization_code',
            }, {
                headers: { "content-type": "application/x-www-form-urlencoded" },
            })

            if (res.data) {
                const res2 = await customAxios.post("/api/deleteGoogleMember", { googleData: res.data })
                if (res2.data.state === 100) {
                    navigate("/logout", { replace: true })
                }
            }
        
        }
        if(start){
            if(code!==null && loginState.isLogin === true){
                deleteGoogle()
            }
        }
    },[start,code,loginState.isLogin])

}

export default DeleteGoogle