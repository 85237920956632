
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { customAxios } from "../Common/customAxios";

function MyEmail() {

    const loginState = useSelector((state) => state.login)

    const noticeGreen = "text-green-500  text-right mt-2 whitespace-pre-line";
    const noticeRed = "text-Cgcolor  text-right mt-2 whitespace-pre-line";

    const [checkEmail, setCheckEmail] = useState(false)
    const [checkEmailStr, setCheckEmailStr] = useState("")
    const [email, setEmail] = useState(loginState.Member_Email)

    useEffect(() => {

        if (checkEmail) {

            if (loginState.Member_Email === "") {
                setCheckEmailStr("이메일 등록 취소")
            }
            else {
                setCheckEmailStr("이메일 변경 취소")
            }

        }
        else {
            if (loginState.Member_Email === "") {
                setCheckEmailStr("이메일 등록")
            }
            else {
                setCheckEmailStr("이메일 변경")
            }
        }


    }, [checkEmail])

    const onClickChangeEmail = () => {

        setCheckEmail(!checkEmail)
    }


    const onClickedSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();

        if (email === loginState.Member_Email) {

            alert("기존의 이메일과 동일합니다.")
            return
        }

        //이메일 유효성 검사
        let regExp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i
        let valiDated = regExp.test(email);

        if (!valiDated) {

            alert("이메일 형식이 올바르지 않습니다.")
            return
        }

        customAxios.post("/api/changeEmail", {
            member_uid: loginState.Member_UID,
            email: email
        })
            .then((res) => {
                if (res.data.state === 100) {
                    alert("이메일 발송에 성공하였습니다. \n해당 이메일에서 이메일 변경 버튼을 눌러주세요.")
                }
                else {
                    if (res.data.message === "Found email") {
                        alert("이미 가입된 이메일입니다.")
                    }
                    else {
                        alert("이메일 발송에 실패하였습니다.")
                    }
                }
            })
    }


    return (
        <div key="MyEmail_" className='basic text-black box-border py-5 px-5'>
            {
                loginState.Member_Email === "" ?
                    <h1 className='font-bold mb-3'>이메일 등록</h1> :
                    <h1 className='font-bold mb-5'>이메일 변경</h1>
            }
            <ul key="ui1" className=''>
                <li key="li1" className='flex flex-row justify-between mb-5'>
                    {
                        loginState.Member_Email === "" ?
                            <p className="text-Cgcolor">이메일을 등록해주세요.</p>
                            :
                            <div className="">
                                <span className='mr-3 block'>이메일</span>
                                <span className='font-bold mt-3 block'>
                                    {loginState.Member_Email}
                                </span>
                            </div>
                    }
                    <div className="flex justify-end">
                        <button
                            className="mt-3 w-36 rounded-lg  bg-Cgcolor py-3 px-3 font-bold text-white"
                            onClick={onClickChangeEmail}>
                            {checkEmailStr}
                        </button>
                    </div>
                </li>
                <li key="li2">
                    <div>
                        {checkEmail ?
                            <form onSubmit={onClickedSubmit}>
                                <div className='mt-3'>
                                    <input
                                        type="text"
                                        className="text-black rounded-lg block w-full flex-1  border-gray-300 focus:border-Cgcolor focus:ring-Cgcolor focus:ring-Cgcolor mr-2"
                                        onChange={(e) => { setEmail(e.target.value) }}
                                    />
                                    <div className="flex justify-end">
                                        <button
                                            type="submit"
                                            className="w-36 mt-3 block rounded-lg  bg-Cgcolor py-3 px-3 font-bold text-white"
                                        >
                                            인증 메일 보내기
                                        </button>
                                    </div>
                                </div>

                            </form>
                            :
                            null
                        }
                    </div>
                </li>
            </ul>
        </div>

    );
}

export default MyEmail;

