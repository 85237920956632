import { createSlice } from '@reduxjs/toolkit'

export const memberSlice = createSlice({
  name: 'member',
  initialState: {
    memberTable : []
  },

  reducers: {

    setMemberData: (state, action) => {
      state.memberTable = action.payload
    },

  },
})

// Action creators are generated for each case reducer function
export const { setMemberData } = memberSlice.actions

export default memberSlice.reducer