import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function YesNoDialog(props) {

    const onClickYes = () =>{
        props.onYesNoDialogYes()    
    }
    const onClickNo = () =>{
        props.onYesNoDialogNo()    
    }

  return (
    <div>
      <Dialog
        open={props.yesNoDialogOpen}
        onClose={onClickNo}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className='bg-Cgcolor text-white'>
          {props.title}
        </DialogTitle>
        <DialogContent className='mt-3'>
        {props.contents}
          {/* <DialogContentText id="alert-dialog-description"> */}
          {/* {props.contents} */}
          {/* </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <div className='py-1'>
            <button
            className= {props.yesBtnClassName} type="button" onClick={onClickYes}> {props.yesBtnName}
            </button>
            <button
            className= {props.noBtnClassName} type="button" onClick={onClickNo}> {props.noBtnName}
            </button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
YesNoDialog.defaultProps = {
  title : "Title",
  contents : "Contents",
  yesBtnName : "Yes",
  noBtnName : "no",
  yesBtnClassName : "border border-Cgcolor rounded-lg py-3 px-3 text-Cgcolor font-bold mr-2",
  noBtnClassName : "border border-black rounded-lg py-3 px-3 text-black font-bold mr-2 focus:outline-none mr-1 ease-linear transition-all duration-150"

}