import { useEffect, useState } from "react";
import { customAxios } from "../Common/customAxios";
import { Link, useSearchParams } from "react-router-dom";
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
} from "@material-tailwind/react";

function StoreList() {
    const [start, setStart] = useState(false);
    const [storeListRefresh, setStoreListRefresh] = useState(true)
    const [storeList, setStoreList] = useState()

    const [searchParams, setSearchParams] = useSearchParams()
    const storeCategoryID = searchParams.get("storeCategoryID")
    useEffect(() => {
        setStart(true);
    }, []);
    useEffect(() => {

        const getStoreList = async () => {
            const result = await customAxios.get("/api/getStoreList")
            // console.log(result.data.response[0])
            setStoreList(result.data.response)
        }

        if (start) {
            if (storeListRefresh === true) {
                getStoreList()
                setStoreListRefresh(false)
            }
        }

    }, [start, storeListRefresh,storeCategoryID]);

    if (!start || !storeList) {
        return <div>Loading...</div>;
    }

    const displayLinkList = (dataList) =>{

        const display = dataList.map((store, index)=>{
            let soldOut = ""

            if(store.soldOut === "true"){
                soldOut = "soldout"
            }
            
            return (
                <Link key={index} to={"/StoreDetail?Store_Code=" + store.Store_Code}>
                <div className={"img_box border-radius border rounded-lg overflow-hidden mb-3 " +soldOut}><img className="" src={store.Store_ThumbnailURL} alt="card-image" /></div>
                    <div className="text_box">
                        <h1 className="font-bold text-sm mb-2">{store.Store_Title}</h1>
                        <h2 className="text-xs">{store.Store_SubTitle}</h2>
                    </div>
                </Link>
            )
        })

        return display


    }

    if(storeCategoryID ===null){
        searchParams.set("storeCategoryID", "01")
        setSearchParams(searchParams, { replace: true })
        
        return (
            <div className="sm:flex mx-auto max-w-7xl flex-col p-2">
              <div className="">
                추천 리스트
              </div>
            </div>
        );
    
    }
    else{
        const filteredData = storeList.filter(item => item.Store_CategoryId === storeCategoryID);
        const filteredData2 = filteredData.filter(item => item.Store_Visible === 1);
        filteredData2.reverse()

        return (
            <div className="sm:flex mx-auto max-w-7xl flex-col p-2 mb-20 storecate">
                <div>
                    {displayLinkList(filteredData2)}
                </div>
            </div>
        );
    
    }
    
}

export default StoreList;
