import Header from "../../Components/Fixed/Header";
import Footer from "../../Components/Fixed/Footer";
import EventDetailCard from "../../Components/Card/EventDetailCard";


function EventDetail() {
    
    return (
        <div className=''>
            <Header />
            <div className='flex w-full px-2 pt-24 justify-center'>
                <EventDetailCard />
            </div>
            <Footer />
        </div>
    )
}

export default EventDetail;
