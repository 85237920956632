
import CircularProgress from '@mui/material/CircularProgress';

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { customAxios } from "../Common/customAxios";
import dayjs from "dayjs";

import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
    Button,
    Chip,
} from "@material-tailwind/react";
import { categoryList } from '../Common/dataStruct';


function ExamDetailCard() {

    const [start, setStart] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const [examData, setExamData] = useState(null)
    const examUID = searchParams.get('page')
    
    const navigate = useNavigate()

    useEffect(() => {
        setStart(true)
    }, [])

    useEffect(() => {
        const getExam = async () => {
            const res = await customAxios.get('/api/getExam' + "?page=" + examUID)

            if(res.data.state === 101){
                // console.log(res.data.response)
                setExamData(res.data.response)

            }
            else{
                alert("올바르지 않은 접근입니다.")
                navigate("/",{replace:true})
                console.log(res.data.message)
            }
        }

        if (start) {
            if (examUID !== null) {
                getExam()
                window.scrollTo({top:0,behavior:'auto'})
            }
        }
    }, [start,examUID])

    const getDisplayExamDetail = () => {

        if (examData) {
            const RDay = dayjs(examData.Exam_RegistrationDate)

            const categoryListData = JSON.parse(examData.Exam_Category)

            return (
                <div key="Detail" className="flex break-all w-full max-w-7xl mt-4 mx-1 sd:mx-14  ">
                    <Card className="flex w-full " key="Card">
                        <CardBody className="flex flex-col items-center gap-4 py-4 mb-5">
                            <p className='font-bold text-base sm:text-2xl whitespace-pre-wrap'>{examData.Exam_Title}</p>
                            <div className='flex flex-wrap gap-1'>
                                {categoryListData && categoryListData.map((value)=>{
                                    const label = categoryList.find(category => category.value === value).label;
                                    return (<Chip key={value} value={label}/>)
                                })}
                                
                            </div>
                            <div className='flex w-full justify-end items-end'>
                                <p className=''>{RDay.format("YYYY-MM-DD")}</p>
                            </div>
                            <p className='text-base whitespace-pre-wrap'>{examData.Exam_Context}</p>
                            </CardBody>
                    </Card>
                </div>
            )
        }
        else {
            return (<CircularProgress key="circle" />)
        }
    }

    const onClickSignUp = (e) =>{
        navigate("/SignUpExam?page="+examUID)
    }
    const onClickSignUpUpdate = (e) =>{
        navigate("/SignUpCheck")
    } 

    return (
        <section className='flex flex-col items-center w-full max-w-7xl'>
            {getDisplayExamDetail()}
            {examData ? 
                <div className='flex self-end mt-4 p-2 gap-2'>
                    <button className=' bg-Cgcolor text-white text-sm font-bold rounded-lg py-3 px-4 inline-block' onClick={onClickSignUpUpdate}>수정(취소)</button>
                    <button className=' bg-Cgcolor text-white text-sm font-bold rounded-lg py-3 px-4 inline-block' onClick={onClickSignUp}>신청</button>
                </div> 
            : 
            null}
            
        </section>
    )
}

export default ExamDetailCard;
