

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LogoutEmail } from "../../Components/Common/customAxios";

import { useSelector, useDispatch } from "react-redux";
import { setLogout } from "../../Store/loginSlice";

function LogoutKakao() {

    const LoginData = useSelector((state)=> state.login)
    const Navigate = useNavigate();
    const dispatch = useDispatch()

    const logout = async () =>{

        let res = await LogoutEmail()
        if(res.data.state===100){
            dispatch(setLogout())
            Navigate("/Login", {replace:true});
        }
    }
    
    useEffect(() => {
        // console.log(LoginData)
         if(LoginData.Member_TokenType ==="K"){
            logout()
        }

    }, [LoginData])
    

    return (
       <div>

       </div>
    )
}
  
  export default LogoutKakao;


