import { useEffect, useRef, useState } from "react";
import { customAxios } from "../Common/customAxios";
import Product from '../../Images/Store/tumbler.png';

import { Menu, MenuHandler, MenuList, MenuItem } from "@material-tailwind/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { useNavigate, useSearchParams,Link } from "react-router-dom";
import { TimeUtils } from "../Func/TimeUtils";

function StoreDisplay(props) {
    const [start, setStart] = useState(false);

    const [productIndex, setProductIndex] = useState(0)
    const [selectImageIndex, setSelectImageIndex] = useState(0)

    const [searchParams, setSearchParams] = useSearchParams()

    const storeCode = searchParams.get("Store_Code")

    const navigate = useNavigate()

    useEffect(() => {
        setStart(true);
    }, []);

    useEffect(() => {
    }, [start]);

    useEffect(()=>{

        setProductIndex(0)
        setSelectImageIndex(0)

    },[props.displayData.products])

    const onClickBuy = ()=>{

        if(props.mode==="admin"){
            console.log("onClickBuy")
        }
        else{
            
            navigate("/PaymentDetail?Product_Code="+props.displayData.products[productIndex].code+"&Store_Code="+storeCode)
            // console.log("real")
        }

    }


    if (props.displayData.products[productIndex] === undefined)
        return

    const selectImage = (
        props.displayData.products[productIndex].images[selectImageIndex] !==undefined ? (
          <img
            className=""
            src={
                props.mode === 'admin' 
                ? URL.createObjectURL(props.displayData.products[productIndex].images[selectImageIndex])
                : props.displayData.products[productIndex].images[selectImageIndex]
            }
            alt={props.displayData.products[productIndex].name}
          />
        ) : (
          <span>이미지가 없습니다</span>
        )
      );
      

    return (
        <div className="flex-col w-full">
            <div>
                <section className='sm:flex mx-auto max-w-7xl px-5 pt-32'>
                    <article className='sm:mb-0 mb-10 sm:w-6/12'>
                        <div className="">
                            <div className="h-full w-full flex flex-col items-center mb-5">
                                <div className="mx-0 my-auto p-1">{selectImage}</div>
                            </div>
                            <div className="flex gap-4">
                                {props.displayData.products[productIndex].images.length >1 && props.displayData.products[productIndex].images.map((image, imageIndex) => (
                                    <div key={imageIndex} className="w-full">
                                        <img
                                            className="rounded-lg cursor-pointer border-2"
                                            src={
                                                props.mode === 'admin' 
                                                ? URL.createObjectURL(image)
                                                : image
                                            }
                                            onMouseOver={(e) => {
                                                setSelectImageIndex(imageIndex)
                                                e.target.classList.add('border-orange-500')
                                            }}
                                            onMouseOut={(e) => e.target.classList.remove('border-orange-500')}
                                        />
                                    </div>
                                ))}
                            </div>
                            
                        </div>
                    </article>
                    <article className='sm:w-6/12 borer-box sm:p-10'>
                        <div>
                            <div className='border-box border-b mb-3'>
                                <h1 className='text-2xl sm:leading-normal font-bold leading-normal w-full sm:flex justify-between items-end'>
                                    <span className='block'>{props.displayData.title}</span>
                                </h1>
                                <h1 className='text-xl sm:leading-normal font-bold leading-normal w-full mb-3'>
                                    <span className='block text-gray-500 text-right'>{props.displayData.subTitle}</span>
                                </h1>
                            </div>

                            <div className='border-box border-b my-3'>
                                {/* <h1 className='flex mb-3 items-center'>
                                    <span className='block text-sm mr-2'>상품명 : </span>
                                    <Menu className="">
                                        <MenuHandler className="border-2 rounded-lg bg-gray-100 flex gap-2">
                                            <button>
                                                {props.displayData.products[productIndex].name}
                                                <ChevronDownIcon
                                                    strokeWidth={2.5}
                                                    className={`h-3.5 w-3.5 `}
                                                />
                                            </button>
                                        </MenuHandler>
                                        <MenuList>
                                            {props.displayData.products.map((product, index) => (
                                                <MenuItem key={product.code} value={index} onClick={(e) => { setProductIndex(index) }}>
                                                    {product.name}
                                                </MenuItem>
                                            ))}
                                        </MenuList>

                                    </Menu>

                                </h1> */}
                                <h1 className='w-full'>
                                    <span className='whitespace-pre-wrap'>{props.displayData.products[productIndex].description}</span>
                                </h1>
                                <h2 className="my-3">
                                    <a href="http://pf.kakao.com/_EdmiG" target="_blank">
                                        모든 문의는 카카오채널 <span className="font-bold">'창꼬 스토어'</span>로!
                                    </a>
                                </h2>
                            </div>
                            <h2 className='text-xl sm:leading-normal leading-normal w-full flex-col'>
                                <span className='block text-sm'>
                                    배송비 :
                                    <span className='text-Cgcolor font-bold ml-1'>
                                        {props.displayData.products[productIndex].deliveryFee === 0 ? '무료배송' : `${Number(props.displayData.products[productIndex].deliveryFee).toLocaleString()} 원`}
                                    </span>
                                </span>
                                <span className='block mt-2 font-bold text-xl text-Cgcolor'>{Number(props.displayData.products[productIndex].price).toLocaleString()}원</span>
                            </h2>
                            {/* {storeCode === "store_240306091858931" ?
                                <div className='w-full mt-10'>
                                    <TimeUtils limitDateTime="2024/03/12 17:59:59"/>
                                
                                </div>
                                : null} */}
                            <div className='w-full mt-10'>
                                <button
                                    onClick={onClickBuy} 
                                    className='w-full bg-Cgcolor text-white text-sm font-bold rounded-lg py-3 px-4 inline-block '>
                                    구매하기
                                </button>
                            </div>
                        </div>
                    </article>
                </section>
                <section className='mx-auto max-w-7xl px-5 pb-32'>
                    <article>
                        <div className='mt-10 border-box p-3 border rounded whitespace-pre-wrap font-bold'>
                            {props.displayData.context}
                        </div>
                    </article>
                </section>
                <section className='mx-auto max-w-7xl px-5 pb-32'>
                    <article>
                        <div className='mt-10'>
                            {props.displayData.contextImg && props.displayData.contextImg.length > 0 && (
                                props.displayData.contextImg.map((image, index) => (
                                    <img key={index} 
                                    src={
                                        props.mode === 'admin' 
                                        ? URL.createObjectURL(image)
                                        : image
                                    }
                                    className='w-full' alt={`상품디테일 ${index}`} />
                                ))
                            )}
                            {!props.displayData.contextImg || props.displayData.contextImg.length === 0 && (
                                <p>이미지가 없습니다.</p>
                            )}
                        </div>

                    </article>
                    
                </section>
            </div>
        </div>
    );
}

export default StoreDisplay;
