import { Button, IconButton } from "@material-tailwind/react";
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";
 
export function DefaultPagination(props) {
 
  const getItemProps = (index) =>
    ({
      className: "h-16 w-6",
      variant: props.active === index ? "filled" : "text",
      color: "gray",
      onClick: () => props.setActive(index),
    });
    const getNoItemProps = () =>
    ({
      variant: "text",
      color: "gray",
    });
 
  const next = () => {
    if (props.active === props.max) return;
 
    props.setActive(props.active + 1);
  };
 
  const prev = () => {
    if (props.active === props.min) return;
 
    props.setActive(props.active - 1);
  };

  const getNumbers = () =>{

    const result = []


    let difference= props.max- props.min
    if (difference <= 5) {
      for (let i = props.min; i <= props.max; i++) {
        result.push(<IconButton key={"pagination" + i} {...getItemProps(i)}>{i}</IconButton>)
      }
      return result
    }
   

    result.push(<IconButton key={"pagination_min"} {...getItemProps(props.min)}>{props.min}</IconButton>)

    if (props.active === props.min) {
      result.push(<IconButton key={"pagination"+(props.min + 1)} {...getItemProps(props.min + 1)}>{props.min + 1}</IconButton>)
      result.push(<IconButton key={"pagination"+(props.min + 2)}{...getItemProps(props.min + 2)}>{props.min + 2}</IconButton>)
      result.push(<IconButton key={"pagination_dot1"}{...getNoItemProps()}disabled={true}>...</IconButton>)
    }
    else if (props.active === props.min + 1) {
      result.push(<IconButton key={"pagination"+(props.min + 1)}  {...getItemProps(props.min + 1)}>{props.min + 1}</IconButton>)
      result.push(<IconButton key={"pagination"+(props.min + 2)}  {...getItemProps(props.min + 2)}>{props.min + 2}</IconButton>)
      result.push(<IconButton key={"pagination_dot1"} {...getNoItemProps()}disabled={true}>...</IconButton>)
    }
    else if (props.active === props.min + 2) {
      result.push(<IconButton key={"pagination"+(props.min + 1)}  {...getItemProps(props.min + 1)}>{props.min + 1}</IconButton>)
      result.push(<IconButton key={"pagination"+(props.min + 2)} {...getItemProps(props.min + 2)}>{props.min + 2}</IconButton>)
      result.push(<IconButton key={"pagination"+(props.min + 3)} {...getItemProps(props.min + 3)}>{props.min + 3}</IconButton>)
      result.push(<IconButton key={"pagination_dot1"} {...getNoItemProps()}disabled={true}>...</IconButton>)
    }
    else if (props.active === props.max - 2) {
      result.push(<IconButton key={"pagination_dot1"} {...getNoItemProps()}disabled={true}>...</IconButton>)
      result.push(<IconButton key={"pagination"+(props.max - 3)} {...getItemProps(props.max - 3)}>{props.max - 3}</IconButton>)
      result.push(<IconButton key={"pagination"+(props.max - 2)}{...getItemProps(props.max - 2)}>{props.max - 2}</IconButton>)
      result.push(<IconButton key={"pagination"+(props.max - 1)}{...getItemProps(props.max - 1)}>{props.max - 1}</IconButton>)
    }
    else if (props.active === props.max - 1) {
      result.push(<IconButton key={"pagination_dot1"} {...getNoItemProps()}disabled={true}>...</IconButton>)
      result.push(<IconButton key={"pagination"+(props.max - 2)}{...getItemProps(props.max - 2)}>{props.max - 2}</IconButton>)
      result.push(<IconButton key={"pagination"+(props.max - 1)}{...getItemProps(props.max - 1)}>{props.max - 1}</IconButton>)
    }
    else if (props.active === props.max) {
      result.push(<IconButton key={"pagination_dot1"} {...getNoItemProps()}disabled={true}>...</IconButton>)
      result.push(<IconButton key={"pagination"+(props.max-2)}{...getItemProps(props.max - 2)}>{props.max - 2}</IconButton>)
      result.push(<IconButton key={"pagination"+(props.max-1)}{...getItemProps(props.max - 1)}>{props.max - 1}</IconButton>)
    }
    else {
      result.push(<IconButton key={"pagination_dot1"}  {...getNoItemProps()} disabled={true}>...</IconButton>)
      result.push(<IconButton key={"pagination"+(props.active - 1)}{...getItemProps(props.active - 1)}>{props.active - 1}</IconButton>)
      result.push(<IconButton key={"pagination"+(props.active)}{...getItemProps(props.active)}>{props.active}</IconButton>)
      result.push(<IconButton key={"pagination"+(props.active + 1)}{...getItemProps(props.active + 1)}>{props.active + 1}</IconButton>)
      result.push(<IconButton key={"pagination_dot2"}  {...getNoItemProps()} disabled={true}>...</IconButton>)
    }

    result.push(<IconButton key={"pagination_max"} {...getItemProps(props.max)}>{props.max}</IconButton>)

    return result
  }
 
  return (
    <div className="flex items-center p-1">
      <Button
        className="flex items-center"
        size="sm"
        variant="text"
        onClick={prev}
        disabled={props.active === props.min}
      >
        <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" /> 
      </Button>
      <div className="flex ">
        {getNumbers()}
      </div>
      <Button
        className="flex items-center"
        size="sm"
        variant="text"
        onClick={next}
        disabled={props.active === props.max}
      >
        <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
      </Button>
    </div>
  );
}