import { Fragment, useEffect, useState } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import CircularProgress from '@mui/material/CircularProgress';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { pageCollection } from '../Page/PageCollection';
import { useSelector } from 'react-redux';
import { Menu as MM, MenuHandler, MenuList, MenuItem, Button } from '@material-tailwind/react';
import { customAxios } from '../Common/customAxios';


const normalMenu = 'px-3 py-2 rounded-md text-sm font-bold duration-300 hover:bg-gray-200 '
const selectMenu = 'px-3 py-2 rounded-md text-sm font-bold duration-300 bg-Cgcolor text-white'

const normalMobileMenu = 'w-full hover:text-black text-black text-xs block px-3 py-2 font-medium text-left'
const selectMobileMenu = 'w-full text-Cgcolor block px-3 py-2 font-bold text-left'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Header() {

  const navigate = useNavigate()
  const location = useLocation()
  const loginState = useSelector((state) => state.login)
  const storeCategoryState = useSelector((state) => state.storeCategory)

  const [start, setStart] = useState(false)

  useEffect(() => {
    setStart(true)
  }, [])

  useEffect(() => {

  }, [start])

  const renderCategoryMenuList = () => {
    if (storeCategoryState.isReady) {
      return (
        <MenuList>
          {storeCategoryState.storeCategoryData.map(element => (
            <Link key={element.id} to={pageCollection.GeneralPages[2].url + "?storeCategoryID=" + element.id}>
              <MenuItem >
                {element.title}
              </MenuItem>
            </Link>
          ))}
        </MenuList>
      );
    }
    return null; // storeCategoryData가 없는 경우에는 아무것도 반환하지 않음
  }

  const getMobileMenu = () => {
    return (
      <Disclosure.Panel className="">
        <div className="space-y-1 px-2 pt-2 pb-3 bg-white border-t border-b border-black">
          <Disclosure.Button as="button" onClick={() => { navigate(pageCollection.GeneralPages[1].url) }}
            className={location.pathname === pageCollection.GeneralPages[1].url ? selectMobileMenu : normalMobileMenu}>
            {pageCollection.GeneralPages[1].name}
          </Disclosure.Button>

          <Disclosure.Button as="button" onClick={() => { navigate(pageCollection.GeneralPages[4].url) }}
            className={location.pathname === pageCollection.GeneralPages[4].url ? selectMobileMenu : normalMobileMenu}>
            {pageCollection.GeneralPages[4].name}
          </Disclosure.Button>

          <Disclosure.Button as="button" onClick={() => { navigate(pageCollection.GeneralPages[3].url) }}
            className={location.pathname === pageCollection.GeneralPages[3].url ? selectMobileMenu : normalMobileMenu}>
            {pageCollection.GeneralPages[3].name}
          </Disclosure.Button>

          <Disclosure.Button as="button" onClick={() => { navigate("/Store?storeCategoryID=01") }}
            className={location.pathname === pageCollection.GeneralPages[2].url ? selectMobileMenu : normalMobileMenu}>
            {pageCollection.GeneralPages[2].name}
          </Disclosure.Button>

        </div>
      </Disclosure.Panel>
    )
  }



  const CheckLogin = () => {

    // console.log(loginState.isLogin)
    if (loginState.isLogin === true) {
      return OnLogin()
    }
    else if (loginState.isLogin === false) {
      return NeedLogin()
    }
    else {
      return LoginIndicator()
    }

  }
  const LoginIndicator = () => {
    return (
      <div className="absolute inset-y-0 right-0 flex items-center md:static md:inset-auto md:ml-6">
        <ul className='flex'>
          <li className='flex items-center'>
            <CircularProgress />
          </li>
        </ul>
      </div>
    )
  }

  const NeedLogin = () => {
    return (
      <div className="absolute inset-y-0 right-0 flex items-center md:static md:inset-auto md:ml-6 text-black">
        <ul className='flex'>
          <li className='flex items-center'>
            <Link to="/Login" className='font-bold'>로그인</Link>
          </li>
        </ul>
      </div>
    )
  }

  const OnLogin = () => {
    return (
      /* Profile drop`do`wn 로그인 했을 경우 노출 */
      <div className="absolute inset-y-0 right-0 flex items-center md:static md:inset-auto md:ml-6">
        <ul className='flex'>
          <li className='flex items-center'>
            <Menu as="div" className="relative ml-3">
              <div>
                <Menu.Button className="flex rounded-full  text-sm focus:outline-none ">
                  <span className="sr-only">Open user menu</span>
                  {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 text-black"> */}
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-black">
                    {/* <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" /> */}
                    <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                </Menu.Button>
              </ div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <Menu.Item>
                    {({ active }) => (
                      <Link to={pageCollection.ManagementMemberPages[11].url} className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}>
                        마이창꼬
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <Link to="/Logout" className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}>
                        로그아웃
                      </Link>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </li>
        </ul>

      </div>

    )

  }

  return (

    <Disclosure as="nav" className="w-full mx-auto">
      {({ open }) => (
        <div className="max-w-lg mx-auto">
          <div className='bg-Cgcolor text-white py-2'>
            <h1 className='mx-auto max-w-7xl px-5 '>피드백이 필요한 순간, 창꼬</h1>
          </div>
          <div className="px-5 bg-white border-b border-gray-300">
            <div className="relative flex h-16 items-center justify-between mx-auto max-w-7xl">
              <div className="">
                {/* Mobile menu button */}
                <Disclosure.Button className=" inline-flex items-center justify-center hover:text-Cgcolor focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (<XMarkIcon className="block h-6 w-6" aria-hidden="true" />) : (<Bars3Icon className="block h-6 w-6" aria-hidden="true" />)}
                </Disclosure.Button>
              </div>
              <div className=" flex flex-1 items-center justify-center text-black">
                <div className="flex flex-shrink-0 items-center">
                  <Link to={pageCollection.GeneralPages[0].url} className='Cgfont text-Cgcolor text-sm Cglogo'><img src={"logo_new.png"} alt='logo' /></Link>
                </div>
              </div>
              {CheckLogin()}
            </div>
          </div>
          {getMobileMenu()}
        </div>
      )}

    </Disclosure>

  )
}
