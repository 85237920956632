
import CircularProgress from '@mui/material/CircularProgress';

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { customAxios } from "../Common/customAxios";
import dayjs from "dayjs";

import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
    Button,
    Chip,
} from "@material-tailwind/react";
import { categoryList } from '../Common/dataStruct';


function SignUpExamDetailCard() {

    const [start, setStart] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const [signUpExamData, setSignUpExamData] = useState(null)
    const SUE_Index = searchParams.get('idx')

    const navigate = useNavigate()

    useEffect(() => {
        setStart(true)
    }, [])

    useEffect(() => {
        const getSignUpExam = async () => {
            const res = await customAxios.get('/api/getSignUpExam?idx=' + SUE_Index)

            if (res.data.state === 101) {
                // console.log(res.data.response)
                setSignUpExamData(res.data.response)

            }
            else {
                console.log(res.data.message)
            }
        }

        if (start) {
            if (SUE_Index !== null) {
                getSignUpExam()
                window.scrollTo({ top: 0, behavior: 'auto' })
            }
        }
    }, [start, SUE_Index])

    const onClickFile = (e, url) => {
        window.open(url, "_blank", "noopener, noreferrer");
    }

    const baseList = [
        "기본기 + 소화력 (60)",
        "딕션",
        "음정",
        "박자,리듬,바운스",
        "발성",
        "호흡의 쓰임",
        "제스쳐 및 에티튜드",
    ]
    const mannerList = [
        "무대 매너 (20)",
        "표현력과 몰입도",
        "자연스러움",
    ]
    const inSideList = [
        "창꼬 내부 평가 (20)",
        "스타일링 및 비쥬얼",
        "매력 및 호감도",
    ]

    const getDisplaySignUpExamDetail = () => {

        if (signUpExamData) {
            const RDay = dayjs(signUpExamData.SignUpExam_RegistrationDate)
            const fileList = JSON.parse(signUpExamData.SignUpExam_FileList)
            console.log(fileList)

            return (
                <div key="Detail" className="flex break-all w-full max-w-7xl mt-4 mx-1 sd:mx-14  ">
                    <Card className="flex w-full " key="Card">
                        <CardBody className="flex flex-col items-center gap-2 py-4 mb-5">
                            <p className='font-bold text-base sm:text-2xl whitespace-pre-wrap'>{"오디션"}</p>
                            <div className='flex w-full justify-end '>
                                <p className=''>{RDay.format("YYYY-MM-DD")}</p>
                            </div>
                            <div className='flex w-full text-base justify-between '>
                                <div className='flex '>
                                    <div className='text-center w-24 border border-black'>학생이름</div>
                                    <div className='text-center w-32 border border-l-0 border-black'>{signUpExamData.SignUpExam_Name}</div>
                                </div>
                                <div className='flex'>
                                    <div className='text-center w-24 border border-black'>평가 선생님</div>
                                    <div className='text-center w-32 border border-l-0 border-black'></div>
                                </div>
                            </div>
                            <div className='flex w-full text-base justify-between'>
                                <div className='flex'>
                                    <div className='text-center w-24 border border-black'>생년월일</div>
                                    <div className='text-center w-32 border border-l-0 border-black'>{signUpExamData.SignUpExam_BirthDay}</div>
                                </div>
                                <div className='flex'>
                                    <div className='text-center w-24 border border-black'>담당 선생님</div>
                                    <div className='text-center w-32 border border-l-0 border-black'></div>
                                </div>
                            </div>
                            <div className='flex w-full text-base justify-between'>
                                <div className='flex-col'>
                                    {fileList && fileList.map(((fileData, index) => {

                                        const u = (fileData.url)

                                        let borderClass = ""

                                        if(index>0)
                                            borderClass = " border-t-0"


                                        return (
                                            <div className='flex' key={"fileData" + index}>
                                                <div className={'text-center w-24 border border-black'+borderClass}>{"곡명 " + (index + 1)} </div>
                                                <div className={'text-center w-32 border border-l-0 border-black'+borderClass}>{fileData.fileName}</div>
                                                <div className={'text-center w-32 border border-l-0 border-black hover:cursor-pointer font-medium text-blue-600 dark:text-blue-500 hover:underline'+borderClass} onClick={(e) => { onClickFile(e, u) }}>{"링크"}</div>
                                            </div>
                                        )
                                    }))}
                                </div>
                            </div>
                            <div className='flex w-full text-base'>
                                <div className='flex-col w-full text-center'>
                                    {baseList && baseList.map((value, index) => {
                                        if (index === 0) {
                                            return (
                                                <div className='flex' key={"baseList"+index}>
                                                    <div className=' text-left w-full border border-black pl-4 font-bold'>{value}</div>
                                                    <div className='text-center w-36 border border-l-0 border-black'>점수 (0~10)</div>
                                                </div>
                                            )
                                        }
                                        else {
                                            return (
                                                <div className='flex' key={"baseList"+index}>
                                                    <div className='text-center w-full border border-t-0 border-black font-bold'>{value}</div>
                                                    <div className='text-center w-36 border border-t-0 border-l-0 border-black'></div>
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                            </div>
                            <div className='flex w-full text-base mt-3'>
                                <div className='flex-col w-full text-center'>
                                    {mannerList && mannerList.map((value, index) => {
                                        if (index === 0) {
                                            return (
                                                <div className='flex' key={"mannerList"+index}>
                                                    <div className=' text-left w-full border border-black font-bold pl-4'>{value}</div>
                                                    <div className='text-center w-36 border border-l-0 border-black'>점수 (0~10)</div>
                                                </div>
                                            )
                                        }
                                        else {
                                            return (
                                                <div className='flex' key={"mannerList"+index}>
                                                    <div className='text-center w-full border border-t-0 border-black font-bold'>{value}</div>
                                                    <div className='text-center w-36 border border-t-0 border-l-0 border-black '></div>
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                            </div>
                            <div className='flex w-full text-base mt-3'>
                                <div className='flex-col w-full text-center'>
                                    {inSideList && inSideList.map((value, index) => {
                                        if (index === 0) {
                                            return (
                                                <div className='flex' key={"inSideList"+index}>
                                                    <div className=' text-left w-full border border-black pl-4 font-bold'>{value}</div>
                                                    <div className='text-center w-36 border border-l-0 border-black'>점수 (0~10)</div>
                                                </div>
                                            )
                                        }
                                        else {
                                            return (
                                                <div className='flex' key={"inSideList"+index}>
                                                    <div className='text-center w-full border border-t-0 border-black font-bold'>{value}</div>
                                                    <div className='text-center w-36 border border-t-0 border-l-0 border-black'></div>
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                            </div>
                            <div className='flex w-full text-base mt-3'>
                                <div className='flex-col w-full text-center font-bold'>
                                    <div className='text-left w-full border border-black pl-4'>기타 평가 및 코멘트</div>
                                    <div className='text-center w-full border border-t-0  border-black h-32'></div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            )
        }
        else {
            return (<CircularProgress key="circle" />)
        }
    }

    return (
        <section className='flex flex-col items-center w-full max-w-7xl'>
            {getDisplaySignUpExamDetail()}
            {/* <div className='flex self-end mt-4 p-2'>
            </div> */}
        </section>
    )
}

export default SignUpExamDetailCard;
