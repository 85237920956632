
import Header from '../../Components/Fixed/Header';
import Footer from '../../Components/Fixed/Footer';
import LoginData from '../../Components/Fixed/LoginData';

function Login() {

    return (
        <div className="max-w-lg mx-auto">
            <Header/>
            <LoginData/>
            <Footer/>   
        </div>
              
    );
}
  
  export default Login;


