import { useNavigate } from "react-router-dom"
import YesNoDialog from "../Dialog/YesNoDialog"
import { useState } from "react"
import { customAxios } from "../Common/customAxios"
import { useSelector } from "react-redux"
import { v4 } from 'uuid';

function MyLeave() {

    const [check, setCheck] = useState(false)
    const [open, setOpen] = useState(false)
    const navigate = useNavigate()
    const loginState = useSelector((state) => state.login)
    const onClickYes = () =>{

        if(check ===false){
            alert("처리사항 안내 확인에 동의되지 않았습니다.")
        }
        else{
            setOpen(true)
        }
    }
    const onClickNo = () =>{
        navigate("/")
    }

    const onYesNoDialogYes = async() =>{

        if(loginState.Member_TokenType ==="C"){
            
            const result = await customAxios.post("/api/deleteMember")
            if (result.data.state === 100) {
                alert("회원탈퇴가 완료되었습니다.")
                navigate("/logout")
            }

        }
        else if(loginState.Member_TokenType ==="K"){

            const KAKAO_AUTH_URL = "https://kauth.kakao.com/oauth/authorize?response_type=code&"
            +"client_id="+process.env.REACT_APP_REST_API_KAKAO+"&"
            +"redirect_uri="+process.env.REACT_APP_DELETE_KAKAO+"&prompt=login"

            window.location.href=KAKAO_AUTH_URL

        } 
        else if(loginState.Member_TokenType ==="N"){

            const NAVER_AUTH_URL = "https://nid.naver.com/oauth2.0/authorize?response_type=code&"
                +"client_id="+process.env.REACT_APP_REST_API_NAVER+"&"
                +"state="+v4()+"&"
                +"redirect_uri="+process.env.REACT_APP_DELETE_NAVER+"&"
                +"auth_type=reauthenticate"
            
            window.location.href=NAVER_AUTH_URL

        } 
        else if(loginState.Member_TokenType ==="G"){

            const GOOGLE_AUTH_URL = 
            "https://accounts.google.com/o/oauth2/v2/auth?"
            +"scope=https://www.googleapis.com/auth/userinfo.email&"
            +"access_type=offline&"
            +"include_granted_scopes=true&"
            +"response_type=code&"
            +"state="+v4()+"&"
            +"redirect_uri="+process.env.REACT_APP_DELETE_GOOGLE+"&"
            +"client_id="+process.env.REACT_APP_GOOGLE_ID+"&"
            +"prompt=none"

            window.location.href=GOOGLE_AUTH_URL
        } 

    }
    const onYesNoDialogNo = () =>{
        setOpen(false)
    }

    return (
        <div key="MyLeave" className='basic text-black box-border py-5 px-5'>
            <div>
                <h1 className='font-bold text-sm mb-1'>회원 탈퇴</h1>
                <div className="font-bold text-xs whitespace-pre">
                    회원탈퇴 전, 유의사항을 확인 바랍니다
                </div>
                <div className="whitespace-pre-wrap">
{`
- 회원탈퇴 시, 웹 서비스 이용이 불가합니다.
- 거래 시, 전자상거래 등에서의 소비자 보호에 관한 법률에 따라 계약 또는 청약철회에 관한 기록, 대금결제 및 재화 등의 공급에 대한 기록은 5년동안 보존됩니다.
- 회원탈퇴 후, 문의 및 작성하신 글은 삭제되지 않으며, 회원정보 삭제로 인하여 본인 확인이 불가하여 삭제처리가 불가능합니다.
- 문의 및 작성하신 글의 삭제를 원하시는 경우에는 먼저 해당 게시물을 삭제하신 후, 탈퇴 바랍니다.
- 이미 결제 완료된건은 탈퇴로 취소가 되지 않습니다.
`}
                </div>
                <div className="flex mt-2"> 
                    <input id="check"  type="checkbox" checked={check} onChange={(e)=>{setCheck(e.target.checked)}}/>
                    <label className="ml-1 text-xs font-bold" htmlFor="check">회원탈퇴 시 처리사항 안내를 확인하였음에 동의합니다.</label>
                </div>
                <h2 className='font-bold text-center mt-3'>회원 탈퇴를 진행하시겠습니까?</h2>
                <div className='flex justify-center'>
                    <ul className='mt-5 flex space-x-5'>
                        <li className='mb-5'>
                            <button 
                            className='border rounded-lg border-gray-400 box-border py-3 px-5 duration-300 hover:bg-black hover:text-white'
                            onClick={onClickYes}
                            >확인</button>   
                        </li>
                        <li className='mb-5'>
                            <button className='rounded-lg box-border py-3 px-5 bg-Cgcolor text-white duration-300 hover:opacity-70'
                            onClick={onClickNo}
                            >취소</button>    
                        </li>
                    </ul>
                </div>
            </div>
        <YesNoDialog
            yesNoDialogOpen = {open}
            title = "회원 탈퇴"
            contents = "정말로 창꼬에서 탈퇴하시겠습니까?"
            yesBtnName = "탈퇴하기"
            noBtnName = "취소"
            onYesNoDialogYes = {onYesNoDialogYes}
            onYesNoDialogNo = {onYesNoDialogNo}
        />

        </div>

      
    );
  }
    
export default MyLeave;
    
  