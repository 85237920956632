

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { customAxios,LogoutEmail } from "../../Components/Common/customAxios";

import { useSelector, useDispatch } from "react-redux";
import { setLogout } from "../../Store/loginSlice";

function LogOut() {

    const LoginData = useSelector((state)=> state.login)
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const logout = async () =>{

        let res = await LogoutEmail()
        if(res.data.state===100){
            dispatch(setLogout())
            navigate("/Login", {replace:true});
        }
        // console.log(res.data)
    }

    const changGgoLogout = ()=>{
        logout()
       
    }
    const kakaoLogout = ()=>{
        
        const LogoutPath = "https://kauth.kakao.com/oauth/logout?"
        +"client_id="+process.env.REACT_APP_REST_API_KAKAO+"&"
        +"logout_redirect_uri="+process.env.REACT_APP_REDIRECT_URI_LOGOUT

        // window.open(LogoutPath)
        window.location.href=LogoutPath
    }
    const googleLogout = ()=>{
            //   // Google OAuth 로그아웃 URL
            //   const googleLogoutUrl = "https://accounts.google.com/logout";
            //   // Google OAuth 로그아웃 페이지로 리다이렉션
            //   window.location.href = googleLogoutUrl;
        logout()
    }
    
    useEffect(() => {
        if(LoginData.Member_TokenType ==="C"){
            changGgoLogout()
        }
        else if(LoginData.Member_TokenType ==="K"){
            kakaoLogout()
        }
        else if(LoginData.Member_TokenType ==="N"){
            changGgoLogout()
        }
        else if(LoginData.Member_TokenType ==="G"){
            googleLogout()
      
        }

    }, [])
    

    return (
       <div>

       </div>
    )
}
  
  export default LogOut;


