import { useEffect, useState } from "react";
import { Card, Input, Checkbox, Button, Typography, Textarea, } from "@material-tailwind/react";
import dayjs from "dayjs";

import { customAxios } from "../Common/customAxios";
import { useNavigate, useSearchParams } from "react-router-dom";
import BirthDay from "../Select/BirthDay";
import AgreePrivate from "../Checkbox/AgreePrivate";
import { categoryList } from "../Common/dataStruct";
import NoticeDialog from "../Dialog/NoticeDialog";

function SignUpExamForm() {

    const [start, setStart] = useState(false)
    const navigate = useNavigate()

    const [searchParams, setSearchParams] = useSearchParams()
    const examUID = searchParams.get('page')
    // const SignUpExam_Index = searchParams.get('idx')

    const signUpExamNumberCheck = searchParams.get('signUpExamNumberCheck')
    const signUpExamNameCheck = searchParams.get('signUpExamNameCheck')
    const signUpExamBirthCheck = searchParams.get('signUpExamBirthCheck')

    const [SignUpExam_Index, setSignUpExam_Index] = useState(null)


    const [examData, setExamData] = useState(null)
    const [trainerList, setTrainerList] = useState(null)
    const [signUpExamList, setSignUpExamList] = useState(null)
    const [examDataRefresh, setExamDataRefresh] = useState(true)
    const [signUpExamListRefresh, setSignUpExamListRefresh] = useState(true)

    const signUpFile = {
        fileName: "",
        state: "URL", //File
        fileCategory: "",
        url: "",
        fileData: "",
    }
    const [signUpExamName, setSignUpExamName] = useState('');
    const [signUpExamTeacher, setSignUpExamTeacher] = useState('');
    const [signUpExamFileList, setSignUpExamFileList] = useState([signUpFile])
    const [signUpDate, setSignUpDate] = useState({ year: '', month: '', day: '' });
    const [signUpClass, setSignUpClass] = useState("")
    const [signUpExamNote, setSignUpExamNote] = useState("")

    const [agreeState, setAgreeState] = useState([])

    const [openModal, setOpenModal] = useState(false)
    const [modalData, setModalData] = useState(null)

    const onClickOk = () => {
        setOpenModal(false)
        navigate("/ExamDetail?page=" + examUID, { replace: true })
    }

    const handleDateChange = (date) => {
        setSignUpDate(date);
    };
    const handleAgreeState = (states) => {
        setAgreeState(states)
    }
    const onClickFile = (e, url) => {
        window.open(url, "_blank", "noopener, noreferrer");
    }

    useEffect(() => {
        setStart(true)
        window.scrollTo({top:0,behavior:'auto'})
    }, [])

    useEffect(() => {
        const getTrainerList = async () => {
            const res = await customAxios.get('/api/getTrainers')

            if (res.data.state === 101) {
                setTrainerList(res.data.response)

            }
            else {
                console.log(res.data.message)
            }
        }


        const getExam = async () => {
            const res = await customAxios.get('/api/getExam' + "?page=" + examUID)

            if (res.data.state === 101) {
                // console.log(res.data.response)
                setExamData(res.data.response)

            }
            else {
                console.log(res.data.message)
            }
            setExamDataRefresh(false)
        }

        const getSignUpExamList = async () => {
            const result = await customAxios.get("/api/getSignUpExamTable", {
                params: {
                    UID: examUID,
                }
            })
            if (result && result.data) {
                if (result.data.state === 101) {
                    // console.log(result.data.response.rows)
                    setSignUpExamList(result.data.response.rows)
                }
                else {
                    alert("데이터를 가져오는데에 실패하였습니다.\n새로고침해주세요.\n[" + result.data.message + "]")
                }
            }
            else {
                alert("데이터를 가져오는데에 실패하였습니다.\n새로고침해주세요.")
            }
            setSignUpExamListRefresh(false)
        }
        const getSignUpExam = async () => {
            // const res = await customAxios.get('/api/getSignUpExam?idx=' + SignUpExam_Index)
            const res = await customAxios.post("/api/getSignUpExamCheck", {
                signUpExamNumber: signUpExamNumberCheck,
                signUpExamName: signUpExamNameCheck,
                signUpExamBirth: signUpExamBirthCheck,
            })
            // console.log(res.data.response)
            if (res.data.state !== 101) {
                alert("올바르지 않은 접근입니다.")
                navigate("/", { replace: true })
            }

            if (res.data.state === 101) { //불러오기
                // console.log(res.data.response.SignUpExam_FileList)

                let mFileList = ""
                if (res.data.response.SignUpExam_FileList) {
                    mFileList = JSON.parse(res.data.response.SignUpExam_FileList)

                    console.log(mFileList)

                    const list = []

                    for (let i = 0; i < mFileList.length; i++) {

                        const data = mFileList[i]
                        const spliter = data.fileName.split("]")

                        let fileCategory = spliter[0]
                        fileCategory = fileCategory.replace("[", "")
                        // console.log(fileCategory)

                        const fileName = spliter[1]

                        const mFile = {
                            fileName: fileName,
                            state: data.state,
                            fileCategory: fileCategory,
                        }

                        if (mFile.state === "URL") {
                            mFile.url = data.url
                        }
                        else if (mFile.state === "File") {
                            mFile.state = "URL_View"
                            mFile.url = data.url
                        }
                        list.push(mFile)
                    }
                    // console.log(list)
                    // console.log(list[0])
                    // console.log(list[1])
                    // console.log(list[2])
                    setSignUpExamFileList(list)


                }

                setSignUpExam_Index(res.data.response.SignUpExam_Index)
                searchParams.set("page", res.data.response.Exam_UID)
                setSearchParams(searchParams, { replace: true })

                const day = dayjs(res.data.response.SignUpExam_BirthDay)
                const y = day.format("YYYY")
                const m = Number(day.format("MM"))
                const d = Number(day.format("DD"))

                const birthDate = {
                    year: String(y),
                    month: String(m),
                    day: String(d)
                }

                setSignUpExamName(res.data.response.SignUpExam_Name)
                setSignUpExamTeacher(res.data.response.SignUpExam_Teacher)
                setSignUpDate(birthDate)
                setSignUpClass(res.data.response.SignUpExam_Class)
                setSignUpExamNote(res.data.response.SignUpExam_Note)

                // const fileList = JSON.parse(res.data.response.SignUpExam_FileList)
                // for(let i=0; i<fileList.length; i++){
                //     if(fileList[i].state === "File"){
                //         fileList[i].state= "URL_View"

                //     }
                // }

                // const tempList = []
                // fileList.map((file)=>{
                //     tempList.push({
                //         fileName: file.fileName,
                //         state: file.state, //File
                //         url: file.url,
                //         fileData: "",
                //     })
                // })

                // setSignUpExamFileList(tempList)

            }
            else {
                console.log(res.data.message)
            }
        }

        if (start) {

            getTrainerList()

            if (examUID) {
                if (examDataRefresh)
                    getExam()

                if (signUpExamListRefresh)
                    getSignUpExamList()
            }

            // console.log(signUpExamNumberCheck)
            // console.log(signUpExamNameCheck)
            // console.log(signUpExamBirthCheck)

            if (signUpExamNumberCheck && signUpExamNameCheck && signUpExamBirthCheck) {
                getSignUpExam()
            }
        }
    }, [start, examUID, signUpExamNumberCheck, signUpExamNameCheck, signUpExamBirthCheck])

    useEffect(() => {

        if (examData && signUpExamList) {

            if (examData.Exam_State === "terminated") {
                alert("해당 평가는 마감되었습니다.")
                navigate("/", { replace: true })
                return
            }

            if (signUpExamNumberCheck === null) {
                if (signUpExamList.length >= examData.Exam_Limit) {
                    alert("해당 평가의 정원이 초과하였습니다.")
                    navigate("/", { replace: true })
                    return
                }
            }
        }

    }, [examData, signUpExamList])

    const handleSubmit = async (e) => {

        function isValidURL(url) {
            try {
                new URL(url);
                return true; // 유효한 URL
            } catch (e) {
                return false; // 유효하지 않은 URL
            }
        }
        

        e.preventDefault();
        if (window.confirm('신청 하시겠습니까?')) {

            const res = await customAxios.get('/api/getExam' + "?page=" + examUID)
            if (res.data.state === 101) {
                if (res.data.response.Exam_State === "terminated") {
                    alert("신청이 마감되었습니다.")
                    return
                }
            }


            //SignUpExam_Index 있으면 수정 모드
            //SignUpExam_Index 없으면 write
            if (!SignUpExam_Index) {
                const result = await customAxios.get("/api/getSignUpExamTable", { params: { UID: examUID, } })
                if (result && result.data && result.data.state) {
                    if (result.data.response.rows.length >= res.data.response.Exam_Limit) {
                        alert("해당 평가의 정원이 초과하였습니다.")
                        return
                    }
                }
            }

            if (agreeState.length < 3) {
                alert("필수 항목이 동의되지 않았습니다.")
                return
            }
            if (signUpExamName === "") {
                alert("이름을 입력해주세요.")
                return
            }
            // console.log(selectedDate)
            if (signUpDate.year === "" || signUpDate.month === "" || signUpDate.day === "") {
                alert("생년월일을 선택해주세요.")
                return
            }
            if (signUpClass === "") {
                alert("클래스 (반)을 선택해주세요.")
                return
            }

            if (signUpExamTeacher === "") {
                alert("담당 선생님을 입력해주세요.")
                return
            }
            let fileCategoryBool = true
            let fileNameBool = true
            let fileFileBool = true
            let fileFileNameBool = true
            let fileUrlBool = true

            let existVocal = false
            let existDance = false


            for (let i = 0; i < signUpExamFileList.length; i++) {

                if (signUpExamFileList[i].fileCategory === "") {
                    fileCategoryBool = false
                    break;
                }
                if (signUpExamFileList[i].fileName === "") {
                    fileNameBool = false
                    break;
                }
                if (signUpExamFileList[i].url === "" || signUpExamFileList[i].url ===undefined ) {
                    fileFileBool = false
                    break;
                }
                if(isValidURL(signUpExamFileList[i].url)===false){
                    fileUrlBool = false
                }                
                if (signUpExamFileList[i].fileCategory === "댄스") {
                    existDance = true
                }
                if (signUpExamFileList[i].fileCategory === "보컬") {
                    existVocal = true
                }
            }



            const hasDuplicateUrls = (files) => {
                const urlSet = new Set();

                for (let file of files) {
                    if (urlSet.has(file.url)) {
                        return false; // 중복된 URL이 있으면 false 반환
                    }

                    console.log(file.url)
                    if (file.url !== "")
                        urlSet.add(file.url);
                }

                return true; // 중복된 URL이 없으면 true 반환
            };

            // 결과 확인
            fileFileNameBool = hasDuplicateUrls(signUpExamFileList);

            if (fileCategoryBool === false) {
                alert("평가곡의 평가 분야가 선택되지 않았습니다.")
                return
            }
            if (fileNameBool === false) {
                alert("평가곡의 이름을 입력해주세요.")
                return
            }
            if (fileFileBool === false) {
                alert("평가곡의 URL 혹은 파일을 입력해주세요")
                return
            }
            if (fileFileNameBool === false) {
                alert("평가곡의 URL이 동일 합니다.")
                return
            }
            // if (fileUrlBool === false) {
            //     alert("평가곡의 URL이 유효하지 않습니다.")
            //     return
            // }

            if (signUpClass === "vocal" || signUpClass === "audition") {
                if (existDance === false || existVocal === false) {
                    alert("오디션 클래스 신청자는 보컬, 댄스 각 1개씩 필수적으로 신청해야합니다.")
                    return
                }

            }

            try {
                const formData = new FormData()

                const birthDay =
                    signUpDate.year + "-" +
                    String(signUpDate.month).padStart(2, "0") + "-" +
                    String(signUpDate.day).padStart(2, "0")


                    // console.log(signUpExamFileList)
                    
                const signUp_FileList = []
                signUpExamFileList.forEach((signUpExamFile) => {

                    if (signUpExamFile.state === "File") {
                        formData.append('signUpExam_Files', signUpExamFile.fileData)
                    }

                    signUp_FileList.push({
                        fileName: "[" + signUpExamFile.fileCategory + "] " + signUpExamFile.fileName,
                        state: signUpExamFile.state,
                        url: signUpExamFile.url
                    })

                })


                formData.append('signUpExam_Index', SignUpExam_Index)
                formData.append('signUpExam_ExamUID', examUID)
                formData.append('signUpExam_Name', signUpExamName)
                formData.append('signUpExam_Teacher', signUpExamTeacher)
                formData.append('signUpExam_Note', signUpExamNote)
                formData.append('signUpExam_BirthDay', birthDay)
                formData.append('signUpExam_Class', signUpClass)
                formData.append('signUpExam_FileList', JSON.stringify(signUp_FileList))



                const response = await customAxios.post('/api/writeSignUpExam', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                if (response.data) {
                    if (response.data.state === 101) {
                        console.log(response.data.res)
                        // alert("등록이 완료되었습니다.")
                        setModalData(response.data.res)
                        setOpenModal(true)
                    }
                    else if (response.data.state === 100) {
                        alert("수정이 완료되었습니다.")
                        navigate("/ExamDetail?page=" + examUID, { replace: true })
                    }
                    else {
                        alert("등록에 실패하였습니다.\n[" + response.data.message + "]")
                        window.location.reload()
                    }
                }
                else {
                    alert("요청에 실패하였습니다.")
                }
                // console.log('Event created successfully:', response.data);
            } catch (error) {
                console.error('Error creating event:', error);
            }

        }
    };

    const addSignUpFile = (e) => {

        const list = [...signUpExamFileList]

        if (list.length > 4) {
            alert("평가곡은 5개 이하 제한 입니다.")
            return
        }

        list.push(signUpFile)
        setSignUpExamFileList(list)
    }

    const removeSignUpFile = (e, index) => {

        const list = [...signUpExamFileList]
        list.splice(index, 1)
        setSignUpExamFileList(list)

    }


    const changeSignUpFile = (fileName, state, fileCategory, url, index, fileData = null) => {

        const list = [...signUpExamFileList]

        let tmpName = fileName.replace("[", "")
        tmpName = tmpName.replace("]", "")
        tmpName = tmpName.replace("\"", "")
        tmpName = tmpName.replace("\'", "")
        tmpName = tmpName.replace("\\", "")

        list[index].fileName = tmpName

        if (list[index].state !== state) {
            list[index].url = ""
        }
        else {
            list[index].url = url
        }

        list[index].state = state
        list[index].fileData = fileData
        list[index].fileCategory = fileCategory

        setSignUpExamFileList(list)
    }

    const onChangeFile = (e, index) => {

        const { files } = e.target
        // console.log(files[0].name)

        if (files && files.length > 0) {

            const MB = 100
            if (files[0].size > (1024 * 1024 * MB)) {
                alert("음원 파일은 " + MB + "MB를 초과할 수 없습니다.")
                changeSignUpFile(
                    signUpExamFileList[index].fileName,
                    signUpExamFileList[index].state,
                    signUpExamFileList[index].fileCategory,
                    "",
                    index)
                return
            }
            else {

                changeSignUpFile(
                    signUpExamFileList[index].fileName,
                    signUpExamFileList[index].state,
                    signUpExamFileList[index].fileCategory,
                    e.target.value,
                    index,
                    files[0])
            }
        }


        else {
            console.log("파일 선택되지 않음")
            changeSignUpFile(
                signUpExamFileList[index].fileName,
                signUpExamFileList[index].state,
                signUpExamFileList[index].fileCategory,
                "",
                index)
        }

    }

    const displayClass = () => {

        if (examData) {
            const examCategory = JSON.parse(examData.Exam_Category)
            return (
                <div className="">
                    <select className='w-full' value={signUpClass} onChange={(e) => { setSignUpClass(e.target.value) }}>
                        <option value="">클래스 (반)</option>
                        {categoryList.map((mCategory) => {
                            const index = examCategory.findIndex(category => category === mCategory.value);
                            if (index >= 0) {
                                return (<option key={mCategory.value} value={mCategory.value}>{mCategory.label}</option>)
                            }
                        })}
                    </select>
                </div>
            )
        }
    }

    const displayTrainer = () => {
        // console.log(trainerList)
        if (trainerList) {
            return (
                <div className="">
                    <select className='w-full' value={signUpExamTeacher} onChange={(e) => { setSignUpExamTeacher(e.target.value) }}>
                        <option value="">담당 선생님</option>
                        {trainerList.map((value, index) => {
                            return (<option key={"trainer_" + index} value={value}>{value}</option>)
                        })}
                    </select>
                </div>
            )
        }
    }

    const displayfileData = (fileData, index) => {

        let stateDiv = (
            <input value={fileData.url}
                className="w-full"
                onChange={(e) => {
                    changeSignUpFile(
                        fileData.fileName,
                        fileData.state,
                        fileData.fileCategory,
                        e.target.value,
                        index)
                }} />
        )

        if (fileData.state === "File") {
            stateDiv = (
                <input
                    className="w-full text-xs"
                    value={fileData.url}
                    onChange={(e) => { onChangeFile(e, index) }} type="file"
                    // accept="audio/*"
                    // accept=".m4a,.mp3,.wav,.aac"
                    accept="audio/*,.m4a,.mp3,.wav,.aac"
                />)
        }
        else if (fileData.state === "URL_View") {
            stateDiv = (
                <div className={'w-full mt-1 pl-2 hover:cursor-pointer text-blue-600 dark:text-blue-500 hover:underline '}
                    onClick={(e) => { onClickFile(e, fileData.url) }}>
                    {/* {fileData.url} */}
                    {"음원 파일"}
                </div>
            )
        }
        return stateDiv
    }

    const displayFileDiv = () => {

        return (
            <div className="flex-col">
                <div className="px-2">
                    음원
                </div>
                <div className="flex flex-col mt-2 gap-1">
                    {signUpExamFileList && signUpExamFileList.map((fileData, index) => {
                        return (
                            <div className="flex w-full gap-2" key={index}>
                                <div className="w-5 h-5 rounded-md bg-white self-center text-center font-bold ">{index + 1}</div>
                                <div className="flex flex-col w-full "  >
                                    <div className="flex w-full">
                                        <select className='w-28'
                                            value={fileData.fileCategory}
                                            onChange={(e) => {
                                                changeSignUpFile(
                                                    fileData.fileName,
                                                    fileData.state,
                                                    e.target.value,
                                                    fileData.url,
                                                    index)
                                            }}>
                                            <option value="">평가 분야</option>
                                            <option value={"보컬"}>보컬</option>
                                            <option value={"댄스"}>댄스</option>
                                            <option value={"가요"}>가요</option>
                                            <option value={"팝"}>팝</option>
                                        </select>
                                        <input
                                            className="w-full"
                                            value={fileData.fileName}
                                            placeholder="평가곡 제목"
                                            maxLength={255}
                                            onChange={(e) => {
                                                changeSignUpFile(
                                                    e.target.value,
                                                    fileData.state,
                                                    fileData.fileCategory,
                                                    fileData.url,
                                                    index)
                                            }}
                                        />
                                    </div>
                                    <div className="flex w-full">
                                        <select
                                            className="w-28"
                                            value={fileData.state}
                                            onChange={(e) => {
                                                changeSignUpFile(
                                                    fileData.fileName,
                                                    e.target.value,
                                                    fileData.fileCategory,
                                                    fileData.url,
                                                    index)
                                            }}>음원 형식
                                            <option value="URL">음원 URL</option>
                                            {/* <option value="File">음원 파일</option> */}
                                            {/* <option value="URL_View" hidden={true} >음원 파일</option> */}
                                        </select>
                                        {displayfileData(fileData, index)}
                                    </div>
                                </div>
                                {
                                    index === 0 ?
                                        <div className="w-5 h-5"></div> :
                                        <div className="w-5 h-5 rounded-md bg-white self-center text-center border border-gray-300  text-sm font-medium text-gray-700 hover:cursor-pointer hover:bg-gray-50 "
                                            onClick={(e) => { removeSignUpFile(e, index) }}>
                                            <span className="font-bold">-</span>
                                        </div>
                                }

                            </div>
                        )
                    })}

                </div>
                <div className="flex justify-end p-2 ">
                    <div className="hover:cursor-pointer px-4 py-2 w-12 bg-white border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" onClick={addSignUpFile}>
                        <span className="text-xl font-bold mr-2">+</span>
                    </div>
                </div>
            </div>

        )
    }


    return (
        <section className="w-full p-2 max-w-md ">
            <article className='main-visual_wrap'>
                <Card className="pt-2" color="transparent" shadow={false}>
                    <form className="mb-2 " onSubmit={handleSubmit}>
                        <div className="mb-1 flex flex-col gap-3">
                            <div className="w-full">
                                <input
                                    className="w-full"
                                    type="text"
                                    placeholder="이름"
                                    value={signUpExamName}
                                    onChange={(e) => {
                                        let tmp = e.target.value.replace("[", "")
                                        tmp = tmp.replace("]", "")
                                        tmp = tmp.replace("\"", "")
                                        tmp = tmp.replace("\'", "")
                                        tmp = tmp.replace("\\", "")

                                        setSignUpExamName(tmp)
                                    }}
                                    maxLength={255}
                                />
                            </div>
                            {displayClass()}
                            {displayTrainer()}

                            <div className="">
                                <div className="px-2">생년월일</div>
                                <BirthDay selectedDate={signUpDate} onDateChange={handleDateChange} />
                            </div>

                            {displayFileDiv()}

                            <div className="w-full">
                                <div className="px-2">비고 (추가 설명)</div>
                                <input className="w-full"
                                    type="text"
                                    placeholder="음원 파일 1:55초부터 재생"
                                    value={signUpExamNote}
                                    onChange={(e) => {

                                        let tmp = e.target.value.replace("[", "")
                                        tmp = tmp.replace("]", "")
                                        tmp = tmp.replace("\"", "")
                                        tmp = tmp.replace("\'", "")
                                        tmp = tmp.replace("\\", "")
                                        setSignUpExamNote(tmp)
                                    }}
                                    maxLength={255}
                                />
                            </div>

                            <div className="">
                                <AgreePrivate onStateChange={handleAgreeState} />
                            </div>
                        </div>
                        <div className="flex justify-end">
                            <Button type="submit" variant="outlined" className="rounded bg-Cgcolor text-white" > 신청하기 </Button>
                        </div>
                    </form>
                </Card>
            </article>
            <NoticeDialog open={openModal} onClickOk={onClickOk} modalData={modalData} />
        </section>
    )
}

export default SignUpExamForm;
