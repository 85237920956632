import Footer from "../../Components/Fixed/Footer";
import SignUpExamCheckForm from "../../Components/Form/SignUpExamCheckForm";


function SignUpCheck() {
    
    return (
        <div className=''>
            <div className='flex w-full px-2 pt-1 justify-center'>
                <SignUpExamCheckForm/>
            </div>
            <Footer />
        </div>
    )
}

export default SignUpCheck;
