import { Typography } from '@material-tailwind/react';
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import { findDataAndParentsByID, findDataByID } from '../../Store/storeCategoryFunc';
import { Breadcrumbs } from "@material-tailwind/react";


export default function StoreHeader() {

  const storeCategoryState = useSelector((state) => state.storeCategory)

  const [searchParams, setSearchParams] = useSearchParams()
  const storeCategoryID = searchParams.get("storeCategoryID")

  const [start, setStart] = useState(false)

  useEffect(() => {
    setStart(true)
  }, [])

  useEffect(() => {
    // console.log(storeCategoryState)
  }, [start])

  const renderMenu = () => {
    if (!storeCategoryState.isReady) {
      return null; // 데이터가 준비되지 않았을 때 null 반환
    }

    // 메인 메뉴 렌더링
    let mainMenuTitle = "스토어"; // 기본 메인 메뉴 제목
    let findData = null
    let parents = [] 
    let subMenuItems = storeCategoryState.storeCategoryData
    
    
    if (storeCategoryID !== null) {
      findData = findDataAndParentsByID(storeCategoryState.storeCategoryData, storeCategoryID);
      mainMenuTitle = findData.data ? findData.data.title : mainMenuTitle;
      subMenuItems = findData.data ? findData.data.dataList : subMenuItems
      findData.parents.push(findData.data)
      parents = findData.parents
    }
    const mainMenu = (
      <Typography variant='h4' color='blue-gray' className='font-bold'>
        {mainMenuTitle}
      </Typography>
    );

    // 서브 메뉴 렌더링
    const subMenu = subMenuItems === undefined ? null : 
    subMenuItems.map(element => (
      <Link key={element.id} to={"/Store?storeCategoryID=" + element.id}>
        <Typography color='blue-gray' className='text-xl'>
          {element.title}
        </Typography>
      </Link>
    ));

    //Breadcrumbs
    const breadcrumbsMenu = (
      <Breadcrumbs >
        <Link to={"/home"} className="rounded-full font-medium text-gray-900">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
            <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
          </svg>
        </Link>
        <Link to={"/Store"} className="font-medium text-gray-900">
          <span>{"스토어"}</span>
        </Link>
        {
          parents.map(element=>(
            <Link key={"Breadcrumbs"+element.id} to={"/Store?storeCategoryID=" + element.id} className=" font-medium text-gray-900">
                <span>{element.title}</span>
              </Link>
          ))
        }
      </Breadcrumbs>
    )


    return (
      <div className='sm:flex mx-auto max-w-7xl px-5 pt-24'>
        <div className='flex flex-col w-full'>
          <div className='text-center mt-20'>
            {mainMenu}
          </div>
          <div className='flex mt-10 justify-center gap-9'>
            {subMenu}
          </div>
          <div className='flex justify-end mb-9'>
            {breadcrumbsMenu}     
          </div>

        </div>
      </div>
    );
  };

  return renderMenu()

}
