import Header from "../../Components/Fixed/Header";
import Footer from "../../Components/Fixed/Footer";

import { useEffect, useRef, useState } from "react";
import { Card, Input, Checkbox, Button, Typography, Textarea, } from "@material-tailwind/react";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

import { customAxios } from "../../Components/Common/customAxios";
import { categoryList } from "../../Components/Common/dataStruct";
import { useNavigate, useSearchParams } from "react-router-dom";
import CategoryList from "../../Components/Checkbox/CategoryList";
import axios from "axios";

function WriteEvent() {

    const loginState = useSelector((state) => state.login)

    const [start, setStart] = useState(false)
    const [eventTitle, setEventTitle] = useState('');
    const [eventAbstract, setEventAbstract] = useState('');
    const [eventCategory, setEventCategory] = useState('');
    const [eventThumbnailFile, setEventThumbnailFile] = useState(null);
    const [eventContext, setEventContext] = useState('');
    const [eventZoomMeeting, setEventZoomMeeting] = useState(false);
    const [eventDueDate, setEventDueDate] = useState('');
    const [currentDateTime, setCurrentDateTime] = useState('');
    let initialBoxState = []
    categoryList.forEach(() => {
        initialBoxState.push(false)
    })
    const [checkBoxStates, setCheckBoxStates] = useState(initialBoxState)

    const datetimeLocalRef = useRef()
    const navigate = useNavigate()

    useEffect(() => {
        setStart(true)
    }, [])

    useEffect(() => {

        const sync = async()=>{

            // customAxios.defaults.withCredentials = true
            // const result = await customAxios.get("https://www.chang-ggo.com/api/getMessage",
            // {
            //     headers:{ 
            //         "Access-Control-Allow-Origin": `http://localhost:3000`,
            //     'Access-Control-Allow-Credentials':"true",}
            // })
            const result = await customAxios.get("/api/getMessage")
            console.log(result.data)

        }


        if (start) {

            let now = dayjs(new Date())
            setCurrentDateTime(now.format("YYYY-MM-DDTHH:mm"));

            if (loginState.isLogin === true) {
                if (loginState.Member_Auth === "U") {
                    alert("권한이 없습니다.")
                    navigate("/", { replace: true })
                }
                
                sync()
               
                
            }
            else if (loginState.isLogin === false) {
                navigate("/")
            }
            else if (loginState.isLogin === null) {
            }
        }
    }, [start, loginState.isLogin, loginState.Member_Auth])

    const onChangedCheckBox = (index) => {
        const nextCheckBoxStates = checkBoxStates.map((element, i) => {
            if (index === i) {
                return element = !element
            }
            else {
                return element
            }
        })
        setCheckBoxStates(nextCheckBoxStates)
    }

    useEffect(() => {
        let arr = []
        checkBoxStates.map((element, idx) => {
            if (element === true) {
                arr.push(categoryList[idx].value)
            }
            return element
        })
        setEventCategory(arr)
    }, [checkBoxStates])


    const handleThumbnailChange = (e) => {
        // 파일 선택 시 호출되는 함수
        const file = e.target.files[0];
        setEventThumbnailFile(file);
    };

    useEffect(() => {

        if (datetimeLocalRef) {
            datetimeLocalRef.current.min = currentDateTime
        }
    }, [currentDateTime])

    const onClickDueDate = () => {
        let now = dayjs(new Date())
        setCurrentDateTime(now.format("YYYY-MM-DDTHH:mm"));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (eventDueDate === "") {
            alert("이벤트 날짜가 설정되지 않았습니다.")
            return
        }
        if (eventCategory.length === 0) {
            alert("카테고리가 설정되지 않았습니다.")
            return
        }

        const isConfirmed = window.confirm('이 이벤트를 생성하시겠습니까?');
        if (isConfirmed) {
            try {
                const formData = new FormData()
                formData.append('Event_Title', eventTitle)
                formData.append('Event_Abstract', eventAbstract)
                formData.append('Event_Category', eventCategory)
                formData.append('Event_Thumbnail', eventThumbnailFile)
                formData.append('Event_Context', eventContext)
                formData.append('Event_ZoomMeeting', eventZoomMeeting)
                formData.append('Event_DueDate', eventDueDate)

                const response = await customAxios.post('/api/WriteEvent', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                console.log('Event created successfully:', response.data);
                // 여기에서 필요한 처리를 수행합니다.
            } catch (error) {
                console.error('Error creating event:', error);
            }

        }
    };
    const onClickZoomLogin = async () => {
        window.open("/api/ZoomAuth", "_blank", "noopener, noreferrer");
    }


    const checkBoxZoom = () => {
        // if (existToken === true) {
        //     return (
        //         <div className="flex w-max gap-4">
        //             <Checkbox
        //                 color="red"
        //                 id="zoomMeet"
        //                 label={
        //                     <Typography
        //                         variant="small" color="gray"
        //                         className="flex items-center font-normal">
        //                         zoom 미팅 예약하기
        //                     </Typography>
        //                 }
        //                 checked={eventZoomMeeting}
        //                 onChange={(e) => { setEventZoomMeeting(!eventZoomMeeting) }}
        //                 containerProps={{ className: "-ml-2.5" }}
        //             />
        //         </div>
        //     )

        // }
        // else if (existToken === false) {
        //     return (
        //         <div className="flex w-max gap-4">
        //             <Checkbox
        //                 color="red"
        //                 id="zoomMeet"
        //                 label={
        //                     <Typography
        //                         variant="small" color="gray"
        //                         className="flex items-center font-normal">
        //                         zoom 미팅 예약하기
        //                     </Typography>
        //                 }
        //                 onClick={(e) => { alert("Zoom에 로그인 하세요") }}
        //                 checked={eventZoomMeeting}
        //                 onChange={(e) => { setEventZoomMeeting(false) }}
        //                 containerProps={{ className: "-ml-2.5" }}
        //             />
        //         </div>
        //     )
        // }

    }


    return (
        <div>
            <Header />
            <section>
                <article className='main-visual_wrap'>
                    <div className='pt-24 w-full  px-5'>
                        <Card color="transparent" shadow={false}>
                            <form className="mt-8 mb-2 " onSubmit={handleSubmit}>
                                <div className="mb-1 flex flex-col gap-3">
                                    <Textarea
                                        className="min-h-full "
                                        label="제목"
                                        value={eventTitle}
                                        rows={1}
                                        maxLength={100}
                                        onChange={(e) => setEventTitle(e.target.value)} />

                                    <Textarea label="요약"
                                        maxLength={100}
                                        value={eventAbstract}
                                        onChange={(e) => setEventAbstract(e.target.value)} />
                                    <Typography variant="h6" color="blue-gray" className="-mb-3">
                                        썸네일 이미지
                                    </Typography>
                                    <input type="file" accept="image/*" onChange={handleThumbnailChange} />
                                    <Typography variant="h6" color="blue-gray">
                                        카테고리
                                    </Typography>

                                    <div className="flex w-max gap-4">
                                        <CategoryList
                                            checkBoxStates={checkBoxStates}
                                            categoryList={categoryList}
                                            onChangedCheckBox={onChangedCheckBox}
                                        />

                                    </div>
                                    <div>
                                        <Textarea label="내용"
                                            size="lg"
                                            rows={25}
                                            resize={true}
                                            value={eventContext}
                                            onChange={(e) => setEventContext(e.target.value)}
                                        />
                                    </div>

                                    {/* {
                                        existToken === true ?
                                            null :
                                            <Button
                                                key={"zoomLogin"}
                                                variant="outlined"
                                                className="mt-3"
                                                onClick={onClickZoomLogin}
                                            >
                                                zoom 로그인
                                            </Button>
                                    } */}
                                    {/* {checkBoxZoom()} */}

                                </div>

                                <div className="flex flex-col w-96">
                                    <Typography variant="h6" color="blue-gray" className="mb-1">
                                        이벤트 날짜
                                    </Typography>
                                    <Input
                                        type="datetime-local"
                                        value={eventDueDate}
                                        min={currentDateTime}
                                        onClick={onClickDueDate}
                                        ref={datetimeLocalRef}
                                        onChange={(e) => setEventDueDate(e.target.value)}
                                    />
                                </div>

                                <Button
                                    key={"write"}
                                    type="submit"
                                    variant="outlined"
                                    className="flex items-center gap-3 mt-3"
                                >
                                    업로드
                                </Button>


                            </form>
                        </Card>

                    </div>

                </article>

            </section>

            <Footer />
        </div>
    )
}

export default WriteEvent;
