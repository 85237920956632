import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';



export default function WaitDialog(props) {

  const [message, setMessage] = useState("잠시만 기다려주세요.")
  const [seconds, setSeconds] = useState(0)

  useEffect(() => {

    if(seconds <=10){
      setMessage("시간이 오래 소요되는 작업입니다.")
    }
    else if(seconds <= 20){
      setMessage("개수가 많을 수록 오래 작업이 진행됩니다.")
    }
    else if(seconds <=30){
      setMessage("잠시만 기다려주세요.")
    }
    else if(seconds <=40){
      setMessage("다른 일을 하면서 기다려주세요!")
    }
    else if(seconds <=50){
      setMessage("금방 완료될거에요!")
    }
    else if(seconds <=60){
      setMessage("금방 완료될거에요!")
    }
    else if(seconds <=70){
      setSeconds(0)
    }

    const stopWatch = setInterval(() => {
      setSeconds(seconds+1)
    }, 1000);

    return () => clearInterval(stopWatch);

}, [seconds,message]);

    const onClickNo = () =>{

      if(props.WaitDialogClose)
        props.WaitDialogClose()    
    }


  return (
    <div>
      <Dialog open={props.Open} onClose={onClickNo}>
        <DialogContent className='w-full flex flex-col items-center gap-2'>
            <CircularProgress key="circle" />
          <div>
             {message}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}