import { Typography, Input } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { customAxios } from "../Common/customAxios";



function AdminPaymentInsertForm() {

    const [start, setStart] = useState(false)
    const [memberUID, setMemberUID] = useState("")
    const [imp_uid, setImp_uid] = useState("")
    const [merchant_uid, setMerchant_uid] = useState("")

    useEffect(()=>{
        setStart(true)
    },[])

    useEffect(()=>{

        if(start){

        }

    },[start])

    const clickedInsertBtn = async() =>{

        console.log("clickedInsertBtn")
        const result = await customAxios.post("/api/insertPaymentAdmin",
        {
            Member_UID : memberUID,
            imp_uid : imp_uid,
            merchant_uid : merchant_uid,
            storeMode : "noLimit"
        })

        if(result.data.state ===100){
            alert("추가가 완료되었습니다.")
        }
        else if(result.data.state===-100){
            alert(result.data.reason)
        }

    }


    return(
        <div className="p-2">
            <div className="mb-1 flex flex-col gap-2 ">
              <Typography variant="h6" color="blue-gray" className="mb-1">MemberUID</Typography>
              <Input
                value={memberUID}
                onChange={(e) => setMemberUID(e.target.value)}
                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
              />
              <Typography variant="h6" color="blue-gray" className="mb-1">imp_uid</Typography>
              <Input
                value={imp_uid}
                onChange={(e) => setImp_uid(e.target.value)}
                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
              />
              <Typography variant="h6" color="blue-gray" className="mb-1">merchant_uid</Typography>
              <Input
                value={merchant_uid}
                onChange={(e) => setMerchant_uid(e.target.value)}
                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
              />

                <button onClick={() => clickedInsertBtn()} className='  bg-Cgcolor text-white text-sm font-bold rounded-lg py-3 px-4 inline-block w-24'>결제 등록</button>

              </div>
        </div>
    )

}

export default AdminPaymentInsertForm;
