import { useEffect, useState } from "react";
import { Card, Input, Checkbox, Button, Typography, Textarea, } from "@material-tailwind/react";
import dayjs from "dayjs";

import { customAxios } from "../Common/customAxios";
import { useNavigate, useSearchParams } from "react-router-dom";
import BirthDay from "../Select/BirthDay";

function SignUpExamCheckForm() {

    const [start, setStart] = useState(false)
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const examUID = searchParams.get('page')

    const [signUpExamNumber, setSignUpExamNumber] = useState("")
    const [signUpExamName, setSignUpExamName] = useState("")
    const [signUpExamBirth, setSignUpBirth] = useState({year: '',month: '',day: ''});

    const handleDateChange = (date) => {
        setSignUpBirth(date);
    };
    useEffect(() => {
        setStart(true)
    }, [])

    useEffect(() => {
        if (start) {
        }
    }, [start])

    const handleSubmit = async (e) => {

        e.preventDefault();

        if (signUpExamNumber === "") {
            alert("참가번호를 입력해주세요.")
            return
        }

        if (signUpExamName === "") {
            alert("이름을 입력해주세요.")
            return
        }
        // console.log(selectedDate)
        if (signUpExamBirth.year === "" || signUpExamBirth.month === "" || signUpExamBirth.day === "") {
            alert("생년월일을 선택해주세요.")
            return
        }
      

        const strBirth = signUpExamBirth.year.padStart(4,'0')+"-"+signUpExamBirth.month.padStart(2,'0')+"-"+signUpExamBirth.day.padStart(2,'0')
        if (window.confirm('참가 정보를 수정 하시겠습니까?')) {
            const getSignUpExamCheck = await customAxios.post("/api/getSignUpExamCheck", {
                signUpExamNumber : signUpExamNumber,
                signUpExamName : signUpExamName,
                signUpExamBirth : strBirth,
            })

            if(getSignUpExamCheck && getSignUpExamCheck.data){
                if(getSignUpExamCheck.data.state === 101){

                    // console.log(getSignUpExamCheck.data.response)
                    const url = `/SignUpExam?signUpExamNumberCheck=${signUpExamNumber}&signUpExamNameCheck=${signUpExamName}&signUpExamBirthCheck=${strBirth}`
                    navigate(url)
                    // console.log(url)
                }
                else{
                    alert("정보가 올바르지 않습니다 다시 시도해주세요.")
                }

            }
            else{
                alert("다시 시도해주세요.")
            }

        }
    };
    const onClickCancel = async (e) => {

        if (signUpExamNumber === "") {
            alert("참가번호를 입력해주세요.")
            return
        }

        if (signUpExamName === "") {
            alert("이름을 입력해주세요.")
            return
        }
        // console.log(selectedDate)
        if (signUpExamBirth.year === "" || signUpExamBirth.month === "" || signUpExamBirth.day === "") {
            alert("생년월일을 선택해주세요.")
            return
        }

        const strBirth = signUpExamBirth.year.padStart(4,'0')+"-"+signUpExamBirth.month.padStart(2,'0')+"-"+signUpExamBirth.day.padStart(2,'0')
        if (window.confirm('참가를 취소 하시겠습니까?')) {
            const getSignUpExamCheck = await customAxios.post("/api/getSignUpExamCheck", {
                signUpExamNumber : signUpExamNumber,
                signUpExamName : signUpExamName,
                signUpExamBirth : strBirth,
            })

            if(getSignUpExamCheck && getSignUpExamCheck.data){
                if(getSignUpExamCheck.data.state === 101){
                    // console.log(getSignUpExamCheck.data.response.SignUpExam_Index)

                    const removeSignUpExam = await customAxios.post("/api/removeSignUpExam",{
                        SignUpExam_Index: getSignUpExamCheck.data.response.SignUpExam_Index
                    })
            
                    if(removeSignUpExam && removeSignUpExam.data){
                        if(removeSignUpExam.data.state === 100){
                            alert("참가 신청이 취소 되었습니다.")
                            navigate("/",{replace:true})
                        }
                        else{
                            alert("신청 취소에 실패하였습니다.\n"+removeSignUpExam.data.message)
                        }
                    }
                    else{
                        alert("다시 시도해주세요.\n")
                    }
                }
                else{
                    alert("정보가 올바르지 않습니다 다시 시도해주세요.")
                }

            }
            else{
                alert("다시 시도해주세요.")
            }

        }
    };

    return (
        <section className="w-full p-2 max-w-md ">
            <article className='main-visual_wrap'>
                <Card className="pt-2" color="transparent" shadow={false}>
                    <form className="mb-2 " onSubmit={handleSubmit}>
                        <div className="mb-1 flex flex-col gap-3">
                            <div className="w-full">
                                <input className="w-full" type="text" placeholder="참가번호"  value={signUpExamNumber} onChange={(e) => setSignUpExamNumber(e.target.value)} />
                            </div>
                            <div className="w-full">
                                <input className="w-full" type="text" placeholder="이름"  value={signUpExamName} onChange={(e) => setSignUpExamName(e.target.value)} />
                            </div>
                            <div className="">
                                <div className="px-2">생년월일</div>
                                <BirthDay selectedDate={signUpExamBirth} onDateChange={handleDateChange}  />
                            </div>
                        </div>
                        <div className="flex gap-1 justify-end mt-2">
                            <Button type="submit" variant="outlined" className="rounded bg-Cgcolor text-white" > 수정 </Button>
                            <Button variant="outlined" className="rounded bg-Cgcolor text-white" onClick={onClickCancel} > 참가취소 </Button>
                        </div>
                    </form>
                </Card>
            </article>
        </section>
    )
}

export default SignUpExamCheckForm;
