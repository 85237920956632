import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

export default  function NotFound() {

    const Navigate = useNavigate()

    useEffect(()=>{

        Navigate("/",{replace:true});

    },[])

}