import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom"
import { customAxios } from "../../Components/Common/customAxios";
import { useSelector } from "react-redux";


const DeleteNaver = ()=>{

    const loginState = useSelector((state) => state.login)

    const navigate = useNavigate()
    const [start, setStart] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const code=searchParams.get('code')

    useEffect(()=>{
        setStart(true)
    },[])

    useEffect(()=>{
        const deleteNaver = async() =>{
            const result = await customAxios.post("/api/deleteNaverMember",{code : code})
            if(result.data.state===100){
            navigate("/logout",{replace:true})
            }
        }
        if(start){
            if(code!==null && loginState.isLogin === true){
                deleteNaver()
            }
        }
    },[start,code,loginState.isLogin])

}

export default DeleteNaver