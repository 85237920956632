import { useEffect, useState } from "react";
import { pageCollection } from "../Page/PageCollection";


const AgreePrivate = ({onStateChange}) => {

    const [checkedList, setCheckedList] = useState([]);

    useEffect(()=>{
        if(onStateChange){
            onStateChange(checkedList)
        }
    },[checkedList])

    const handleCheckAll = (e) => {
        (e.target.checked)
            ? setCheckedList(["join_age", "join_service", "join_privacy"])
            : setCheckedList([]);
    }
    const handleCheck = (e) => {
        e.target.checked
            ? setCheckedList([...checkedList, e.target.name])
            : setCheckedList(checkedList.filter((choice) => choice !== e.target.name));

        // console.log(e.target.name);

    }



    return (
            <div className=' rounded-lg py-5 px-5 '>
                <div className='space-y-5'>
                    <div className="flex items-start pb-5 mb-5 border-b">
                        <div className="flex h-5 items-center">
                            <input id="join_all" name="comments" required type="checkbox" className="h-4 w-4 rounded border-gray-300 text-Cgcolor focus:ring-Cgcolor"
                                onChange={handleCheckAll}
                                checked={checkedList.length === 3 ? true : false}
                            />
                        </div>
                        <div className="ml-3 ">
                            <label htmlFor="join_all" className="font-medium text-gray-700">모두 동의합니다.</label>
                        </div>
                    </div>
                    <div className="flex items-start">
                        <div className="flex h-5 items-center">
                            <input id="join_age" name="join_age" required type="checkbox" className="h-4 w-4 rounded border-gray-300 text-Cgcolor focus:ring-Cgcolor"
                                onChange={handleCheck}
                                checked={checkedList.includes("join_age") ? true : false}
                            />
                        </div>
                        <div className="ml-3 ">
                            <label htmlFor="join_age" className="font-medium text-gray-700">만 14세 이상입니다. <span className='text-Cgcolor'>(필수)</span></label>
                        </div>
                    </div>
                    <div className="flex items-start">
                        <div className="flex h-5 items-center">
                            <input id="join_service" name="join_service" required type="checkbox" className="h-4 w-4 rounded border-gray-300 text-Cgcolor focus:ring-Cgcolor"
                                onChange={handleCheck}
                                checked={checkedList.includes("join_service") ? true : false}

                            />
                        </div>
                        <div className="ml-3 ">
                            <label htmlFor="join_service" className="font-medium text-gray-700 block">개인정보 수집/이용에 동의합니다. <span className='text-Cgcolor'>(필수)</span></label>
                            <a href={pageCollection.etcPages[0].url} target="_blank" className='text-gray-400 block mt-3 hover:text-black'>[{pageCollection.etcPages[0].name}]</a>
                        </div>
                    </div>
                    <div className="flex items-start">
                        <div className="flex h-5 items-center">
                            <input id="join_privacy" name="join_privacy" required type="checkbox" className="h-4 w-4 rounded border-gray-300 text-Cgcolor focus:ring-Cgcolor"
                                onChange={handleCheck}
                                checked={checkedList.includes("join_privacy") ? true : false}
                            />
                        </div>
                        <div className="ml-3 ">
                            <label htmlFor="join_privacy" className="font-medium text-gray-700 block">서비스 이용약관에 동의합니다. <span className='text-Cgcolor'>(필수)</span></label>
                            <a href={pageCollection.etcPages[1].url} target="_blank" className='text-gray-400 block mt-3 hover:text-black'>[{pageCollection.etcPages[1].name}]</a>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default AgreePrivate