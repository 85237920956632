import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { customAxios } from "../Common/customAxios";


const AdminProductList = () => {

    const [start, setStart] = useState(false)
    const [productListRefresh, setproductListRefresh] = useState(true)
    const [productList, setProductList] = useState(null)


    const thClass = "border border-black border-blue-gray-100 bg-blue-gray-50 p-1";
    const tdClass = "border border-black border-blue-gray-100 p-1";

    useEffect(() => {
        setStart(true)
    }, [])

    useEffect(()=>{

        const getProductList = async()=>{
            const result = await customAxios.get("/api/getProductList")
            // console.log(result.data.response)
            setProductList(result.data.response)
          }
      
          if(start){
            if(productListRefresh === true){
                getProductList()
                setproductListRefresh(false)
            }
          }
    },[start,productListRefresh])

    const clickUpdateBtn = (e, index)=>{

        console.log("updateBtn")
    }

    if(productList){

    }

    return (
        <div className=" p-1 overflow-x-auto w-full">
            <table className="table-fixed whitespace-nowrap">
                <thead>
                    <tr>
                        <th className={`${thClass} w-1/12`}>상품 코드</th>
                        <th className={`${thClass} w-1/12`}>상품 이름</th>
                        <th className={`${thClass} w-1/12`}>가격</th>
                        <th className={`${thClass} w-1/12`}>배송비</th>
                        <th className={`${thClass} w-1/12`}>재고</th>
                        <th className={`${thClass} w-1/12`}>등록일</th>
                        <th className={`${thClass} w-1/12`}>수정</th>
                    </tr>
                </thead>
                <tbody>
                    {productList && productList.map((product, index) => (
                        <tr key={index}>
                            <td className={`${tdClass} w-1/12`}>{product.Product_Code}</td>
                            <td className={`${tdClass} w-1/12`}>{product.Product_Name}</td>
                            <td className={`${tdClass} w-1/12`}>{product.Product_Price}</td>
                            <td className={`${tdClass} w-1/12`}>{product.Product_DeliveryFee}</td>
                            <td className={`${tdClass} w-1/12`}>{product.Product_Quantity}</td>
                            <td className={`${tdClass} w-1/12`}>{new Date(product.Product_Date).toLocaleDateString()}</td>
                            <td className={`${tdClass} w-1/12`}>
                                <button
                                    className="px-1 py-1 rounded-md bg-Cgcolor text-white"
                                    onClick={(e) => clickUpdateBtn(e, index)}
                                >
                                    수정
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>


                <tfoot>
                    <tr>
                        <td colSpan={4}></td>
                        <td>
                            <div className="flex justify-center">
                              
                            </div>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    )

}

export default AdminProductList