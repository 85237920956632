import AdminAuthCheck from "../../Components/Admin/AdminAuthCheck";
import AdminMemberList from "../../Components/Admin/AdminMemberList";
import AdminHeader from "../../Components/Fixed/AdminHeader";


function AdminMember() {

    return (
        <div className="h-full flex flex-row">
            <AdminAuthCheck auth="M3"/>
            <AdminHeader mode={0}/>
            <div className="p-1">
                <AdminMemberList/>
            </div>
        </div>

    );

}

export default AdminMember;


