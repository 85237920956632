import { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { pageCollection } from './Components/Page/PageCollection';
import NotFound from './Pages/Etc/NotFound';
import Test from './Pages/Etc/Test';

import { customAxios,getAccessToken} from './Components/Common/customAxios';
import { setLogin, setLogout } from './Store/loginSlice';
import { setStoreCategoryData,getTest } from './Store/storeCategorySlice';
import { useDispatch } from 'react-redux';
import { useState } from 'react';

function App() {
  
  const dispatch = useDispatch()
  const [start,setStart] = useState(false)
  useEffect( ()=>{
    setStart(true)
  },[])

  useEffect(() => {
    
    const getPST = async () =>{

      const result = () =>{}
    }


    const AwaitFunc = async() =>{

      const res = await getAccessToken()
      if(res.data.state ===101){
        const getMember = await customAxios.get('/api/getMember')
        const state ={}
        if (getMember.data.state === 101) {
            state.isLogin = true
            state.Member_UID = getMember.data.Member.Member_UID
            state.Member_Email = getMember.data.Member.Member_Email
            state.Member_Nickname = getMember.data.Member.Member_Nickname
            state.Member_Auth = getMember.data.Member.Member_Auth
            state.Member_TokenType = getMember.data.Member.Member_TokenType
        }
        else{
            console.log("getMember 실패")
        }
        dispatch(setLogin(state))
      }
      else{
        dispatch(setLogout())
      }
      const getStoreCategoryList  = await customAxios.get("/api/getStoreCategoryList")
      // console.log(getStoreCategoryList.data)

      if (getStoreCategoryList.data.state === 101) {
        dispatch(setStoreCategoryData(getStoreCategoryList.data.response))
      }

    }
    if(start){
      AwaitFunc()
    }
   
  }, [start])
  
  const getGeneralPages = () => {
    let array =[]
    pageCollection.GeneralPages.forEach(element => {
      array.push(element.route)
    });
    return array
  }
  const getGeneralDetailPages = () => {
    let array =[]
    pageCollection.GeneralDetailPages.forEach(element => {
      array.push(element.route)
    });
    return array
  }

  const getManagementMemberPages = () => {
    let array =[]
    pageCollection.ManagementMemberPages.forEach(element => {
      array.push(element.route)
    });
    return array
  }

  const getAdminPages = () => {
    let array =[]
    pageCollection.adminPages.forEach(element => {
      array.push(element.route)
    });
      return array
  }
  const getEtcPages = () => {
    let array =[]
    pageCollection.etcPages.forEach(element => {
      array.push(element.route)
    });
      return array
  }

  return (
    <BrowserRouter>
        <Routes>
          {getGeneralPages()}
          {getGeneralDetailPages()}
          {getManagementMemberPages()}
          {getAdminPages()}
          {getEtcPages()}

          <Route path="/Dev_Test" element={<Test />}></Route>
          <Route path="*" element={<NotFound />}></Route>

        </Routes>
    </BrowserRouter>

  );
}

export default App;
