import { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Footer from "../../Components/Fixed/Footer"
import Header from "../../Components/Fixed/Header"
import PracticeTable from "../../Components/Table/PracticeTable"
import { customAxios } from "../../Components/Common/customAxios";


const Practice = () => {

  const [start, setStart] = useState(false)
  const loginState = useSelector((state) => state.login)
  const navigate = useNavigate()

  useEffect(() => {
    setStart(true)
  }, [])

  useEffect(() => {

    const getUserInfo = async () => {
      const result = await customAxios.get("/api/getUserInfo")
      if (result) {
        if (result.data) {
          if (result.data.userInfo === null) {
            alert("본인인증이 필요한 페이지입니다.")
            navigate("/MyPrivacy", { replace: false })
          }
        }
      }
    }

    if (start) {
      if (loginState.isLogin === false) {
        localStorage.setItem("nextPage", "/Practice")
        alert("로그인이 필요합니다.")
        navigate("/login", { replace: true })
      }
      else if (loginState.isLogin === true) {
        getUserInfo()
      }
    }
  }, [start, loginState.isLogin])

  if (loginState.isLogin === true) {
    return (
      <div className="max-w-lg mx-auto">
        <Header />
        <div className='flex px-2 pt-24 justify-center'>
          <PracticeTable />
        </div>
        <Footer />
      </div>
    )
  }

}

export default Practice