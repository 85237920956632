import { Typography, Input, Textarea } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { customAxios } from "../Common/customAxios";



function AdminPaymentLostForm() {

    const [start, setStart] = useState(false)
    const [imp_uid, setImp_uid] = useState("")
    const [lostList, setLostList] = useState([])

    useEffect(()=>{
        setStart(true)
    },[])

    useEffect(()=>{

        if(start){

        }

    },[start])

    const clickedCheckLost = async() =>{

        const result = await customAxios.post("/api/lostPaymentAdmin",
        {
            imp_uid : imp_uid,
        })

        if(result.data.state ===101){
            setLostList(result.data.response)

        }
        else if(result.data.state===-100){
            alert(result.data.reason)
        }

    }


    return(
        <div className="p-2 w-full flex">
            <div className="w-1/3 mb-1 flex flex-col gap-2 ">
                <Typography variant="h6" color="blue-gray" className="mb-1">imp_uid</Typography>
                <Textarea
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    size="lg"
                    rows={20}
                    resize={true}
                    value={imp_uid}
                    onChange={(e) => setImp_uid(e.target.value)}
                    labelProps={{
                        className: "before:content-none after:content-none",
                    }}
                />
            </div>
            <button onClick={() => clickedCheckLost()} 
            className='  bg-Cgcolor text-white text-sm font-bold rounded-lg py-3 px-4 inline-block w-24 h-24'>확인</button>
            <div className="w-1/3 mb-1 flex flex-col gap-2 ">
                <div>
                    {lostList.map((element) => {
                        return (
                            <div key={element}>
                                {element}
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )

}

export default AdminPaymentLostForm;
