import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom"
import { customAxios } from "../../Components/Common/customAxios";
import { useDispatch } from "react-redux";
import { setLogin } from "../../Store/loginSlice";

const LoginKakao = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [start, setStart] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const code = searchParams.get("code")

    useEffect(() => {
        setStart(true)
    }, [])


    useEffect(() => {
        const AwaitFunc = async () => {
            if (code !== null) {
                const res = await customAxios.post("https://kauth.kakao.com/oauth/token",{
                        grant_type: "authorization_code",
                        client_id: process.env.REACT_APP_REST_API_KAKAO,
                        redirect_uri: process.env.REACT_APP_REDIRECT_URI_KAKAO,
                        code: code,},{
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },})

                if (res.data) {
                    const res2 = await customAxios.post("/api/kakaoLogin", {
                        id_token: res.data.id_token,
                        access_token: res.data.access_token,
                        refresh_token: res.data.refresh_token,
                    })

                    if (res2.data.state === 101) {
                        customAxios.defaults.headers.common['Authorization'] = `Bearer ${res2.data.accessToken}`
                        
                        const getMember = await customAxios.get('/api/getMember')
                        const state ={}
                        if (getMember.data.state === 101) {
                            state.isLogin = true
                            state.Member_UID = getMember.data.Member.Member_UID
                            state.Member_Email = getMember.data.Member.Member_Email
                            state.Member_Nickname = getMember.data.Member.Member_Nickname
                            state.Member_Auth = getMember.data.Member.Member_Auth
                            state.Member_TokenType = getMember.data.Member.Member_TokenType
                        }
                        else{
                            console.log("getMember 실패")
                        }
                        dispatch(setLogin(state))
                        const nextPage = localStorage.getItem("nextPage")
                        localStorage.removeItem("nextPage")

                        if (nextPage) {
                            navigate(nextPage, { replace: true })
                        }
                        else {
                            navigate("/Home", { replace: true })
                        }
                    }
                    else {
                        if (res2.data.isDelete === true) {
                            if(window.confirm("탈퇴한 회원입니다.\n복구하시겠습니까?")){
                                const result = await customAxios.post("/api/restorationKakao",{kakaoData: res.data})
                                if(result.data.state ===100){
                                    alert("복구되었습니다.\n다시 로그인해주세요.")
                                    navigate("/login")
                                }
                            }
                            else{
                                navigate("/login")
                            }
                        }
                        else {
                            console.log("카카오 인증 에러")
                        }
                    }
                }
            }
        }
        if (start) {
            AwaitFunc()
        }
    }, [start, code])
}

export default LoginKakao