import AdminAuthCheck from "../../Components/Admin/AdminAuthCheck";
import AdminStoreForm from "../../Components/Admin/AdminStoreForm";
import AdminHeader from "../../Components/Fixed/AdminHeader";

function AdminStoreWritePage() {

    return (
        <div className="h-[calc(100vh-2rem)] flex flex-row ">
            <AdminAuthCheck auth="M3"/>
            <AdminHeader mode={1} />
            <AdminStoreForm/>
        </div>

    );

}

export default AdminStoreWritePage;


