import { useSelector } from "react-redux";
import { customAxios } from "../Common/customAxios";

import { useState,  useEffect } from "react";

function MyPasswordConfirm(props) {

    const loginState = useSelector((state)=> state.login)

    const [password, setPassword] = useState("");

    useEffect(()=>{
        if(props.passwordState === false){
            if(loginState.Member_TokenType ==="K"){
                props.setPasswordState(true)
            }
            else if(loginState.Member_TokenType ==="G"){
                props.setPasswordState(true)
            }
            else if(loginState.Member_TokenType ==="N"){
                props.setPasswordState(true)
            }
        }
    },[])


    const PW_Confirm = () => {

        customAxios.post("/api/checkPassword", {
            email: loginState.Member_Email,
            password: password
        }).then((res) => {
            if (res.data.state === 100) {//비밀번호 일치
                props.setPasswordState(true);
            }
            else {
                props.setPasswordState(false);
                alert("비밀번호가 일치하지 않습니다. 확인 바랍니다.")
            }
        })
    }

    const OnKeyPress = (e) => {
        if (e.key == 'Enter') {
            PW_Confirm();
        }

    }

    return (
        <div key="MyPasswordConfirm" className='basic text-black box-border py-5 px-5'>
            <div>
                <h1 className=' font-bold mb-5'>비밀번호 확인</h1>
                <ul>
                    <li className='flex mb-5'>
                        <span className='mr-3'>닉네임</span>
                        <span className='font-bold'>{loginState.Member_Nickname}</span>
                    </li>
                    <li>
                        <div>
                            <h2 className='mb-2'>비밀번호</h2>
                            <div className='flex'>
                                <input
                                    type="password"
                                    maxLength="20"
                                    name=""
                                    id=""
                                    className="text-black rounded-lg block w-full flex-1 border-gray-300 focus:border-Cgcolor focus:ring-Cgcolor focus:ring-Cgcolor mr-2"
                                    placeholder="" required onChange={(e) => { setPassword(e.target.value) }} onKeyDown={OnKeyPress}
                                />
                                <button
                                    type="submit"
                                    className="w-1/5 block rounded-lg  bg-Cgcolor py-3 px-3  font-bold text-white"
                                    onClick={PW_Confirm}
                                >
                                    확인
                                </button>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

    );
}

export default MyPasswordConfirm;

