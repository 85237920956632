import {  useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom"
import { setLogin } from "../../Store/loginSlice";
import { customAxios } from "../../Components/Common/customAxios";

const LoginGoogle = ()=>{
    const dispatch = useDispatch()
    const [start, setStart] = useState(null)
    const [searchParams, setSearchParams] = useSearchParams()
    const code = searchParams.get("code")
    const navigate = useNavigate()

    useEffect(()=>{
        setStart(true)
    },[])
    
    useEffect(()=>{
        const AwaitFunc = async() =>{
            if (code !== null) {
                const res = await customAxios.post("https://oauth2.googleapis.com/token", {
                    code: code,
                    client_id: process.env.REACT_APP_GOOGLE_ID,
                    client_secret: process.env.REACT_APP_GOOGLE_SECRET,
                    redirect_uri: process.env.REACT_APP_REDIRECT_URL_GOOGLE,
                    grant_type: 'authorization_code',}, {
                    headers: { "content-type": "application/x-www-form-urlencoded" },})
            
                if(res.data){
                    let res2 =  await customAxios.post("/api/GoogleLogin", {
                            access_token: res.data.access_token,
                            id_token: res.data.id_token,
                            refresh_token: res.data.refresh_token,
                        })
                        if(res2.data.state === 101){
                            customAxios.defaults.headers.common['Authorization'] = `Bearer ${res2.data.accessToken}`
                            const getMember = await customAxios.get('/api/getMember')
                            const state ={}
                            if (getMember.data.state === 101) {
                                state.isLogin = true
                                state.Member_UID = getMember.data.Member.Member_UID
                                state.Member_Email = getMember.data.Member.Member_Email
                                state.Member_Nickname = getMember.data.Member.Member_Nickname
                                state.Member_Auth = getMember.data.Member.Member_Auth
                                state.Member_TokenType = getMember.data.Member.Member_TokenType
                            }
                            else{
                                console.log("getMember 실패")
                            }
                            dispatch(setLogin(state))
                            const nextPage = localStorage.getItem("nextPage")
                            localStorage.removeItem("nextPage")

                            if (nextPage) {
                                navigate(nextPage, { replace: true })
                            }
                            else {
                                navigate("/Home", { replace: true })
                            }
                            // navigate("/",{replace:true})
                        }
                        else{
                            if (res2.data.isDelete === true) {
                                if(window.confirm("탈퇴한 회원입니다.\n복구하시겠습니까?")){
                                    const result = await customAxios.post("/api/restorationGoogle",{googleData: res.data})
                                    if(result.data.state ===100){
                                        alert("복구되었습니다.\n다시 로그인해주세요.")
                                        navigate("/login")
                                    }
                                }
                                else{
                                    navigate("/login")
                                }
                            }
                            else {
                                console.log("구글 인증 에러")
                            }

                        }
                }
            }
        }
        if(start){
            AwaitFunc()
        }
    },[start,code])
  
}

export default LoginGoogle