import Header from "../../Components/Fixed/Header";
import Footer from "../../Components/Fixed/Footer";

import PrivacyText from "../../Components/Text/PrivacyText";

function Privacy() {
  
    return (
      
      <div className='sub-bg'>
        <Header/>
            <div className='mx-auto max-w-7xl px-5 py-32 text-white'>
                <PrivacyText/>
            </div>
        <Footer/>
      </div> 
      
    );
  }
    
    export default Privacy;
    
  