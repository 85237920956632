import AdminHeader from "../../Components/Fixed/AdminHeader";
import { AdminPracticeForm } from "../../Components/Admin/AdminPracticeForm";
import AdminAuthCheck from "../../Components/Admin/AdminAuthCheck";

const AdminPractice = () => {

    return (
        <div className="h-[calc(100vh-2rem)] flex flex-row ">
            <AdminAuthCheck auth="M1"/>
            <AdminHeader mode={2}/>
            <div className="p-1 w-full">
                <AdminPracticeForm mode="CG"/>
            </div>
        </div>
    )
}

export default AdminPractice