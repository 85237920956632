import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom";
import { customAxios } from "../../Components/Common/customAxios"


 const EmailCertification = () => {

    const location = useLocation();
    const [token,setToken]= useState(null)
    const [str, setStr] = useState(null)

    useEffect(()=>{
   
        let param = new URLSearchParams(location.search)
        setToken(param.get('Token'))

    },[])

    useEffect(()=>{
        if(token !==null){
            customAxios.get("/api/emailCertification?Token="+token)
            .then((res)=>{

                if(res.data.state === 100){
                    alert("이메일 인증이 완료되었습니다. \n 진행중인 회원가입 페이지에서 회원가입을 완료해주세요.")
                   window.open('','_self').close();
                }
                else{
                    alert("이메일 인증이 실패하였습니다. 다시 시도해주세요.")
                    window.open('','_self').close();
                }
            })
        }
        else{
            setStr("이메일 인증이 진행 중 입니다.")
        }
    },[token])

    return(
        <div>
            {str}
            </div>
    )
}
export default EmailCertification