import Header from "../../Components/Fixed/Header";
import Footer from "../../Components/Fixed/Footer";

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { EventList } from "../../Components/Event/EventList";


function EventBoard() {

    const [start, setStart] = useState(false)
    const loginState = useSelector((state) => state.login)

    return (
        <div className='max-w-lg mx-auto'>
            <Header />
            <div className='flex flex-col px-2 pt-2 items-center'>
                <EventList/>
            </div>
            <Footer />
        </div>
    )
}

export default EventBoard;
