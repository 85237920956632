export  const findDataByID = (dataList, targetId, parent = null) => {
  let data = null;
  let parentData = parent;

  for (const item of dataList) {
    if (item.id === targetId) {
      data = item;
      break;
    } else if (item.dataList) {
      const result = findDataByID(item.dataList, targetId, item);
      if (result.data) {
        data = result.data;
        parentData = result.parentData;
        break;
      }
    }
  }

  return { data, parentData };
};

export const findDataAndParentsByID = (dataList, targetId, parent = null) => {
  let data = null;
  let parents = []; // 모든 부모를 저장할 배열

  for (const item of dataList) {
    if (item.id === targetId) {
      data = item;
      break;
    } else if (item.dataList) {
      const result = findDataAndParentsByID(item.dataList, targetId, item);
      if (result.data) {
        data = result.data;
        parents = [item, ...result.parents]; // 현재 항목과 이전에 발견된 모든 부모 항목을 함께 저장
        break;
      }
    }
  }

  return { data, parents };
};
